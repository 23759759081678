import { Injectable } from '@angular/core';
import { AppUserService } from './app.user.service';

@Injectable()
export class AppPermissionService {
    private paths = {
        baseRedirectPage: {
            admin: '/dashboard',
            manager: '/dashboard',
            viewer: '/user/assignments',
            mobile: '/user/assignments',
        },
        allowedPaths: {
            viewer: ['/user/profile', '/user/assignments', '/view', '/user/checklists'],
            mobile: ['/user/profile', '/user/assignments', '/view', '/user/checklists'],
        },
    };

    constructor(private appUserService: AppUserService) {}

    canAccess(url: string, isMobile = false): boolean {
        let canAccess = false;
        const userLevel = this.appUserService.getUserLevel();

        if (isMobile) {
            const allowedPathsLength = this.paths.allowedPaths.mobile.length;

            for (let i = 0; i < allowedPathsLength; i++) {
                if (url.startsWith(this.paths.allowedPaths.mobile[i])) {
                    canAccess = true;
                    break;
                }
            }
        } else {
            if (userLevel === 'admin' || userLevel === 'manager') {
                canAccess = true;
            } else if (userLevel === 'viewer') {
                const allowedPathsLength =
                    this.paths.allowedPaths.viewer.length;

                for (let i = 0; i < allowedPathsLength; i++) {
                    if (url.startsWith(this.paths.allowedPaths.viewer[i])) {
                        canAccess = true;
                        break;
                    }
                }
            }
        }

        return canAccess;
    }

    getBaseRedirectPage(isMobile = false): any {
        if (isMobile) {
            return this.paths.baseRedirectPage['mobile'];
        } else {
            const userLevel = this.appUserService.getUserLevel();
            return this.paths.baseRedirectPage[userLevel];
        }
    }
}
