import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import * as pluralize from 'pluralize';

import { DialogListMessagesComponent } from './dialogs/dialog-list-messages/dialog-list-messages.component';
import { AppHttpService } from '../../shared/services/app.http.service';
import { AppHelpersService } from '../../shared/services/app.helpers.service';
import { AppLoadingIndicatorService } from '../../shared/services/app.loading-indicator.service';
import { AppSelectOption } from '../../shared/components/app-select/classes/app.select.option';
import { AppService } from '../../app.service';
import { Utils } from '../../shared/app.utils';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as moment from "moment/moment";

@Component({
    selector: 'app-assignments',
    templateUrl: './assignments.component.html',
    styleUrls: ['./assignments.component.less'],
})
export class AssignmentsComponent implements OnInit, AfterViewInit {
    isMobile = null;
    assignments = [];
    numberOfPages = 0;
    numbersPerPage = 10;
    currentPageIndex = 0;
    numberOfResults = 0;
    entityName = 'assignment';
    pages: Array<AppSelectOption> = [];
    selectedPage: AppSelectOption;
    searchText: string = null;
    isDone = false;
    url = 'todo';

    private lastSearchText: string = null;
    private searchSubject: Subject<string> = new Subject();

    constructor(
        private dialog: MatDialog,
        private appService: AppService,
        private appHttpService: AppHttpService,
        private appHelpersService: AppHelpersService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private deviceService: DeviceDetectorService
    ) {
        this.isMobile = this.deviceService.isMobile();
    }

    ngOnInit() {
        this.appLoadingIndicatorService.show('view');
        setTimeout(() => (this.appService.selected.navOption = 'assignments'), 0);

        // Get page offset from page url
        const urlParams = this.appHelpersService.getUrlParams();
        if (urlParams) {
            if ('offset' in urlParams) {
                this.currentPageIndex = parseInt(urlParams.offset);
                if (this.currentPageIndex < 0) {
                    this.currentPageIndex = 0;
                    this.updateOffset(this.currentPageIndex);
                }
            }
            if ('page' in urlParams) {
                if (urlParams.page === 'todo') {
                    this.isDone = false;
                } else if (urlParams.page === 'done') {
                    this.isDone = true;
                } else {
                    this.isDone = false;
                }
                this.updateUrl(urlParams.page);
            }
        }

        this.searchSubject.pipe(debounceTime(250)).subscribe((searchText) => {
            this.processSearchTextChange(searchText);
        });
    }

    ngAfterViewInit() {
        // Init assignments
        this.initializeAssignments();
    }

    handlePagination(direction: string): void {
        let newPageIndex: number;

        if (direction === 'previous' && this.currentPageIndex > 0) {
            newPageIndex = this.currentPageIndex - 1;
        } else if (
            direction === 'next' &&
            this.currentPageIndex !== this.numberOfPages - 1
        ) {
            newPageIndex = this.currentPageIndex + 1;
        }

        // Set new selected page object.
        if (newPageIndex !== undefined) {
            this.selectedPage = this.pages[newPageIndex];
            this.currentPageIndex = newPageIndex;

            this.updateOffset(this.currentPageIndex);

            this.appLoadingIndicatorService.show(
                '.assignments-table .assignments-table-body'
            );
            this.initializeAssignments(false);
        }
    }

    handleSearchChange(event): void {
        if (event.key !== 'Enter') {
            const searchText = event.target.value;

            if (this.lastSearchText !== searchText) {
                this.searchSubject.next(searchText);
            }
        }
    }

    clearSearch(): void {
        this.searchText = null;
        const searchText = null;
        this.searchSubject.next(searchText);
    }

    processSearchTextChange(searchText: string) {
        this.lastSearchText = Utils.trim(searchText);
        this.currentPageIndex = 0;

        this.appLoadingIndicatorService.show('.assignments-table .assignments-table-body');
        this.initializeAssignments();
    }

    getTableFooterText(): string {
        const numberOfEntities = this.numberOfResults,
            numberOfPages = this.numberOfPages ? this.numberOfPages : false;
        let text = '';

        // If there are entities within the table to display, generate footer text.
        if (numberOfEntities > 0) {
            if (numberOfPages > 1) {
                const currentPage = this.currentPageIndex + 1,
                    startValue =
                        currentPage * this.numbersPerPage -
                        this.numbersPerPage +
                        1;
                let endValue = startValue + this.numbersPerPage - 1;

                if (endValue > numberOfEntities) {
                    endValue = numberOfEntities;
                }

                // If there are multiple pages, ...
                text =
                    'Displaying ' +
                    startValue +
                    ' to ' +
                    endValue +
                    ' of ' +
                    numberOfEntities +
                    ' ' +
                    pluralize(this.entityName, numberOfEntities);
            } else {
                // ... otherwise, display simpler format text.
                text =
                    'Displaying ' +
                    numberOfEntities +
                    ' ' +
                    pluralize(this.entityName, numberOfEntities);
            }
        } else if (numberOfEntities === 0) {
            text = 'Displaying 0 ' + pluralize(this.entityName);
        }

        return text;
    }

    getFooterPagingWidth(): string {
        let width = '125px';

        if (this.numberOfPages >= 10 && this.numberOfPages <= 99) {
            width = '135px';
        } else if (this.numberOfPages >= 100 && this.numberOfPages <= 999) {
            width = '150px';
        }

        return width;
    }

    getStatusIcon(isComplete: boolean, progress: number): string {
        let icon = 'star';
        if (!isComplete) {
            if (!progress) {
                icon = 'star_border';
            } else {
                icon = 'star_half';
            }
        }

        return icon;
    }

    onShowMessages(messages: Array<any>): void {
        if (messages && messages.length) {
            this.dialog.open(DialogListMessagesComponent, {
                width: '375px',
                minHeight: '120px',
                maxHeight: '80vh',
                height: 'auto',
                data: {
                    messages: messages,
                },
            });
        }
    }

    getRemaining(totalDuration: number, durationComplete: number): string {
        if (!durationComplete) {
            durationComplete = 0;
        }

        const remainderMinutes = Math.ceil((totalDuration - durationComplete) / 60);

        if (remainderMinutes > 60) {
            const roundedHours = Utils.roundToHalfHours(totalDuration - durationComplete),
                  hours = roundedHours > 1 ? "hours" : "hour";

            return `${roundedHours} ${hours}`;
        } else {
            let roundedMinutes = (Math.round(remainderMinutes / 5) * 5);

            if (roundedMinutes == 0) {
                roundedMinutes = 5;
            }

            return `${roundedMinutes} mins`;
        }
    }

    getProgressValue(progress: number): number {
        return Math.ceil(progress * 100);
    }

    getDisplayDate(date): string {
        return date ? moment(date).format('L') : '--';
    }

    getFriendlyType(type: string): string {
        if (type === 'packet') {
            return 'Course';
        } else if (type === 'requirement') {
            return 'Requirement';
        }
    }

    private buildAnalyticsPacketData(limit = true): object {
        const data = {};

        // Limit
        if (limit) {
            data['limit'] = this.numbersPerPage;
        }

        // Query search text.
        if (this.lastSearchText && this.lastSearchText.length > 0) {
            data['packetQuery'] = this.lastSearchText;
        }

        // Conditionally set the offset.
        if (this.currentPageIndex + 1 > 0 && limit) {
            data['offset'] = this.currentPageIndex * this.numbersPerPage;
        }

        return data;
    }

    private initializeAssignments(shouldInitPagingData = true): void {
        const data = this.buildAnalyticsPacketData();
        const urlParams = this.appHelpersService.getUrlParams();
        this.updateUrl(this.url);
        const getAssignmentsUrl = 'GET:api/assignments/' + this.url + '/';
        this.appLoadingIndicatorService.show('.assignments-table .assignments-table-body');
        this.appHttpService.request(getAssignmentsUrl, data, (response) => {
            if (shouldInitPagingData) {
                this.numberOfResults = response.count;
                this.numberOfPages = Math.ceil(
                    this.numberOfResults / this.numbersPerPage
                );
                // Build page list.
                for (let i = 0; i < this.numberOfPages; i++) {
                    const page = (i + 1).toString();
                    this.pages.push(new AppSelectOption(page, page));
                }
                // Set initial selected page.
                this.selectedPage = this.pages[0];
            }

            this.assignments = response.results;

            this.appLoadingIndicatorService.hide();
        });
    }

    tabAssignments(url) {
        this.currentPageIndex = 0;
        if (url === 'todo') {
            this.isDone = false;
            this.updateOffset(this.currentPageIndex);
            this.updateUrl(url);
        } else if (url === 'done') {
            this.isDone = true;
            this.updateOffset(this.currentPageIndex);
            this.updateUrl(url);
        }
        this.initializeAssignments(true);
    }

    private updateOffset(offset): void {
        // Add offset to page url
        const link = Utils.setUrlParameter(
            window.location.href,
            'offset',
            offset
        );
        window.history.replaceState(null, null, link);
    }
    private updateUrl(url): void {
        // Add page to page url
        this.url = url;
        const link = Utils.setUrlParameter(window.location.href, 'page', url);
        window.history.replaceState(null, null, link);
    }
}
