import {
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppHelpersService } from '../../services/app.helpers.service';
import { AppHttpService } from '../../services/app.http.service';
import { AppMessageService } from '../../services/app.message.service';
import { AppLoadingIndicatorService } from '../../services/app.loading-indicator.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
    FileSystemDirectoryEntry,
    FileSystemFileEntry,
    NgxFileDropEntry,
} from 'ngx-file-drop';
import { AppAlertService } from '../../services/app.alert.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs/Subscription';

@Component({
    templateUrl: './dialog-upload-certificate.component.html',
    styleUrls: ['./dialog-upload-certificate.component.less'],
})
export class DialogUploadCertificateComponent implements OnInit {
    myControl = new FormControl();
    @ViewChild('selectFileField') selectFileField: ElementRef;
    origin = undefined;
    selectedFile = undefined;
    certificateKey = "";
    endpoint = "POST:api/assignments/certificate/"
    postUrl = undefined;
    messages = {
        success: 'You have successfully set the certificate for this assignment.',
        error: 'There was an error while setting the certificate for this assignment.',
    };

    constructor(
        private appHelpersService: AppHelpersService,
        private appHttpService: AppHttpService,
        private appAlertService: AppAlertService,
        private appMessageService: AppMessageService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        public dialogRef: MatDialogRef<DialogUploadCertificateComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            origin: string;
            assignmentId: number;
        }
    ) {}

    ngOnInit() {
        this.postUrl = this.endpoint + this.data.assignmentId + '/'
        this.appLoadingIndicatorService.hide(350);
    }

    private setAssignmentCertificate() {
        const certificateData = {
            certificateKey: this.certificateKey,
        }

        this.appHttpService.request(
            this.postUrl,
            certificateData,
            (res) => {
                this.dialogRef.close();
                this.appMessageService.show(
                    'app',
                    'success',
                    this.messages.success,
                    4
                );
                this.appLoadingIndicatorService.hide();
            },
            (err) => {
                this.appLoadingIndicatorService.hide();
                this.appMessageService.show(
                    'modal',
                    'error',
                    this.messages.error,
                    4
                );
            }
        );
    }

    // Certificate file handling
    private processFileUpload(file: any) {
        this.appHttpService.apiAwsUploadFile(
            file,
            (key) => {
                this.certificateKey = key;
                this.setAssignmentCertificate();
            },
            () => {
                this.displayFileUploadErrorNotification()
                console.log("Error uploading file")
            }
        );
    }

    private isValidFile(file: File) {
        return file && this.isFileAllowed(file.name);
    }

    certificateFileDropped(files: NgxFileDropEntry[]) {
        if (files.length > 1) {
            this.displaySingleFileNotification()
        } else if (files.length === 0) {
            this.displayNoFilesSelectedNotification()
        }

        this.appLoadingIndicatorService.show('app');

        const droppedFile = files[0],
            fileEntry = droppedFile.fileEntry as FileSystemFileEntry,
            reader = new FileReader();

        if (!fileEntry.isFile) {
            this.displayNoFilesSelectedNotification();
        } else {
            fileEntry.file((file: File) => {
                if (this.isValidFile(file)) {
                    this.processFileUpload(file);
                } else {
                    this.displayInvalidFileNotification();
                    this.appLoadingIndicatorService.hide(300);
                }
            })
        }
    }

    isFileAllowed(fileName: string) {
        let isFileAllowed = false;
        const allowedFiles = ['.pdf', '.jpg', '.jpeg', '.png'];
        const regex = /(?:\.([^.]+))?$/;
        const extension = regex.exec(fileName);
        if (undefined !== extension && null !== extension) {
            const allowed = allowedFiles.filter(
                (ext) => ext === extension[0].toLowerCase()
            );

            if (allowed.length) {
                isFileAllowed = true;
            }
        }
        return isFileAllowed;
    }

    private displayInvalidFileNotification(): void {
        this.appMessageService.show(
            'app',
            'error',
            'Invalid file extension. You must choose either a PDF document or an image.',
            3
        );
    }

    private displaySingleFileNotification(): void {
        this.appMessageService.show(
            'app',
            'error',
            'You can only choose one file to be uploaded.',
            3
        );
    }

    private displayNoFilesSelectedNotification(): void {
        this.appMessageService.show(
            'app',
            'error',
            'No files selected, please try again.',
            3
        );
    }

    private displayFileUploadErrorNotification(): void {
        this.appMessageService.show(
            'app',
            'error',
            'There was an error uploading the file, please try again.',
            3
        );
    }

    private displayPreviewFileUploadNotification(): void {
        this.appMessageService.show(
            'app',
            'general',
            'Certificate files are not uploaded while previewing.',
            3
        );
    }

    openFileDialog() {
        $('input[type="file"]').trigger('click');
    }
}
