import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SortDirectionEnum } from '../classes/enums/sort.direction.enum';
import { SortTypeEnum } from '../classes/enums/sort.type.enum';
import { AppInfoService } from './app-info/app.info.service';
import * as moment from 'moment';
import * as $ from 'jquery';

@Injectable()
export class AppHelpersService {
    private fadeOptions = ['out', 'in'];
    private fadeInterval = 300;
    private whenElementPresentDoMinSelector = 2;
    private whenElementPresentDoMaxSelector = 50;
    private whenDoMinInterval = 250;
    private fileExtensions = {
        image: ['.jpg', '.jpeg', '.jpe', '.gif', '.png', '.bmp'],
        audio: [
            '.aiff',
            '.aac',
            '.flac',
            '.m4a',
            '.mp3',
            '.ogg',
            '.oga',
            '.ra',
            '.rm',
            '.tta',
            '.wav',
            '.wma',
            '.webm',
        ],
        video: [
            '.mpg',
            '.mp4',
            '.mov',
            '.avi',
            '.3gp',
            '.flv',
            '.wmv',
            '.vp6',
            '.webm',
            '.m4p',
            '.mkv',
        ],
        document: ['.pdf'],
    };
    private fileSizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    private fileExtensionTypes = Object.keys(this.fileExtensions);
    private patterns = {
        url: new RegExp(
            '^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?' + // port
                '(\\/[-a-z\\d%@_.~+&:=]*)*' + // path
                '(\\?[;&a-z\\d%@_.,~+&:=-]*)?' + // query string
                '(\\#[-a-z\\d=_]*)?$',
            'i'
        ),
        ext: /(\.|\/)(jpg|jpeg|jpe|gif|png|bmp|mpg|mp4|mov|avi|3gp|flv|wmv|vp6|m4p|mkv|aiff|aac|flac|m4a|mp3|ogg|oga|ra|rm|tta|wav|wma|webm)$/i,
        extInvalid: /(\.|\/)(exe|com|bat|asp|cmd|js|msi|vb|dmg)$/i,
    };

    constructor(
        private route: ActivatedRoute,
        private appInfoService: AppInfoService
    ) {}

    isEmptyObject(obj: object): boolean {
        let isEmpty = false;

        if (typeof obj === 'object' && !Array.isArray(obj)) {
            const objectIsNull = obj === null;
            let objectHasNoProperties = false;

            if (!objectIsNull) {
                objectHasNoProperties = Object.keys(obj).length === 0;
            }

            isEmpty = objectIsNull || objectHasNoProperties;
        }

        return isEmpty;
    }

    getFileExtensionsByType(type: string): string {
        let fileExtensions: string;

        if (this.fileExtensionTypes.indexOf(type) >= 0) {
            fileExtensions = this.fileExtensions[type].join(',');
        }

        return fileExtensions;
    }

    getUrlParams() {
        return this.route.queryParams['value'];
    }

    fade(selector: string, type: string, callback?: Function) {
        const element = $(selector);

        if (
            element.length >= 1 &&
            this.fadeOptions.indexOf(type.toString()) >= 0
        ) {
            type === 'out'
                ? element.fadeOut(this.fadeInterval)
                : element.fadeIn(this.fadeInterval);

            if (callback) {
                setTimeout(() => {
                    callback();
                }, this.fadeInterval + 50);
            }
        } else {
            console.log(
                'ERROR :: Cannot find element to fade out or arguments invalid.'
            );
        }
    }

    getClone(obj: any) {
        return JSON.parse(JSON.stringify(obj));
    }

    isEqual(obj1: any, obj2: any) {
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    }

    whenElementPresentDo(
        selector: string,
        callback: Function,
        interval = this.whenDoMinInterval
    ) {
        if (this.isValidWhenElementPresent(selector, callback, interval)) {
            const intervalFunction = setInterval(() => {
                if ($(selector).length > 0) {
                    callback();
                    setTimeout(() => {
                        clearInterval(intervalFunction);
                    }, 100);
                }
            }, interval);
        } else {
            console.log(
                'ERROR :: Invalid properties supplied to method: whenElementPresentDo().'
            );
        }
    }

    whenPresentDo(
        obj: object,
        propertyName: string,
        callback: Function,
        interval = this.whenDoMinInterval
    ) {
        const intervalFunction = setInterval(() => {
            if (this.isObjectPropertySet(obj, propertyName)) {
                callback();
                setTimeout(() => {
                    clearInterval(intervalFunction);
                }, 100);
            }
        }, interval);
    }

    isObjectPropertySet(obj: object, propertyName: string): boolean {
        return (
            obj &&
            propertyName in obj &&
            Boolean(obj[propertyName]) &&
            Object.keys(obj[propertyName]).length > 0
        );
    }

    getBaseUrl() {
        let baseUrl = '';

        if (
            location.hostname === 'localhost' ||
            location.hostname === '127.0.0.1'
        ) {
            baseUrl = 'https://demo.vingapp.com/';
        } else {
            baseUrl = location.origin + '/';
        }

        return baseUrl;
    }

    isValidUrl(url: string) {
        try {
            new URL(url);
            return true;
        } catch (err) {
            return false;
        }
    }

    isValidString(str) {
        return typeof str === 'string' || str instanceof String;
    }

    isValidComponentFile(file: File): boolean {
        const appInfo = this.appInfoService.getAppInfo();
        const invalidExts =
            appInfo.validationInfo.packet.invalidUploadExtensions;
        const fileName = this.getFileNameByFile(file);
        const fileExtension = this.getFileExtensionByFile(file);
        const invalidExtRegExp =
            invalidExts && invalidExts.length
                ? new RegExp('(.|/)(' + invalidExts.join('|') + ')$', 'i')
                : this.patterns.extInvalid;
        return (
            fileName !== '' &&
            fileExtension !== '.' &&
            (this.patterns.ext.test(fileExtension) ||
                !invalidExtRegExp.test(fileExtension))
        );
    }

    isValidContactFile(file: File): boolean {
        const fileExtension = this.getFileExtensionByFile(file);
        const csvExt = /(\.|\/)(csv|tsv|xlsx)$/i;
        return csvExt.test(fileExtension);
    }

    isValidCertificateFile(file: File): boolean {
        const fileExtension = this.getFileExtensionByFile(file);
        const certExt = /(\.|\/)(pdf|jpg|png|gif|tiff|jpeg)$/i;
        return certExt.test(fileExtension);
    }

    getComponentTypeByFile(file: File): string {
        const fileExtension = this.getFileExtensionByFile(file);
        let componentType = '';

        Object.keys(this.fileExtensions).forEach((type) => {
            if (
                componentType === '' &&
                this.fileExtensions[type].indexOf(fileExtension) >= 0
            ) {
                componentType = type;
            }
        });

        return componentType === '' ? 'other' : componentType;
    }

    bytesToSize(bytes: number): string {
        const i = parseInt(
            Math.floor(Math.log(bytes) / Math.log(1024)).toString(),
            10
        );
        return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + this.fileSizes[i];
    }

    generateUniqueId() {
        return Math.random().toString(36).substr(2, 16);
    }

    getFileExtensionByFileName(fileName: string): string {
        const fileExt = fileName.split('?')[0].split(".").slice(-1)[0]
        return '.' + fileExt.toLowerCase();
    }

    getViewerExtensionByType(type: string): string {
        let viewerFileExtension = '';

        switch (type) {
            case 'video':
                viewerFileExtension = '.mp4';
                break;
            case 'audio':
                viewerFileExtension = '.mp3';
                break;
        }

        return viewerFileExtension;
    }

    findIndexByProperty(list, propertyName, propertyValue) {
        const listLength = list.length;
        let index = -1;

        for (let i = 0; i < listLength; i++) {
            if (list[i][propertyName] === propertyValue) {
                index = i;
                break;
            }
        }

        return index;
    }

    getArrayDuplicates(array: Array<any>): Array<any> {
        const duplicates = [],
            sortedArray = array.slice().sort(),
            sortedArrayLength = sortedArray.length;

        for (let i = 0; i < sortedArrayLength - 1; i++) {
            if (sortedArray[i + 1] === sortedArray[i]) {
                duplicates.push(sortedArray[i]);
            }
        }

        return duplicates;
    }

    findByProperty(array, propertyName, propertyValue) {
        const arrayLength = array.length;
        let item;

        for (let i = 0; i < arrayLength; i++) {
            if (array[i][propertyName] === propertyValue) {
                item = array[i];
                break;
            }
        }

        return item;
    }

    getFileDataFromUrl(url, callback) {
        const xhr = new XMLHttpRequest();

        xhr.onload = function () {
            const reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
        };

        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    sortListByPropertyName(
        list: Array<any>,
        propertyIndex: number,
        sortDirection: SortDirectionEnum,
        sortType: SortTypeEnum
    ): void {
        switch (sortType) {
            case SortTypeEnum.NUMBER:
                if (sortDirection === SortDirectionEnum.ASC) {
                    list.sort(
                        (a, b) =>
                            a['cells'][propertyIndex].sortValue -
                            b['cells'][propertyIndex].sortValue
                    );
                } else if (sortDirection === SortDirectionEnum.DESC) {
                    list.sort(
                        (a, b) =>
                            b['cells'][propertyIndex].sortValue -
                            a['cells'][propertyIndex].sortValue
                    );
                }
                break;
            case SortTypeEnum.DATE:
                list.sort((a, b) => {
                    const dateA = new Date(a['cells'][propertyIndex].sortValue),
                        dateB = new Date(b['cells'][propertyIndex].sortValue);

                    if (sortDirection === SortDirectionEnum.ASC) {
                        return dateA.getTime() - dateB.getTime();
                    } else if (sortDirection === SortDirectionEnum.DESC) {
                        return dateB.getTime() - dateA.getTime();
                    }
                });
                break;
            case SortTypeEnum.STRING:
                list.sort((a, b) => {
                    const stringA = a['cells'][propertyIndex].sortValue,
                        stringB = b['cells'][propertyIndex].sortValue;

                    if (sortDirection === SortDirectionEnum.ASC) {
                        if (stringA < stringB) {
                            return -1;
                        } else if (stringA > stringB) {
                            return 1;
                        }

                        return 0;
                    } else if (sortDirection === SortDirectionEnum.DESC) {
                        if (stringA > stringB) {
                            return -1;
                        } else if (stringA < stringB) {
                            return 1;
                        }

                        return 0;
                    }
                });
                break;
        }
    }

    isValueInRange(rangeMin: number, rangeMax: number, value: number): boolean {
        return (value - rangeMin) * (value - rangeMax) <= 0;
    }

    camelCaseToProperCase(str: string): string {
        const result = str.replace(/([A-Z])/g, ' $1');
        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    capitalizeFirstLetter(str: string): string {
        if (str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        } else {
            return str;
        }
    }

    getDisplayDate(date): string {
        return date ? moment(date).format('MMM Do, YYYY') : '--';
    }

    getDisplayTime(time): string {
        return time ? moment(time).format('LT') : '';
    }

    getScoresColor(score) {
        const appInfo = this.appInfoService.getAppInfo();
        const colors = appInfo.colorsInfo.scores;
        const filtered = colors.filter((clr) => clr[0] <= score);

        return filtered[filtered.length - 1][1];
    }

    private getFileExtensionByFile(file: File) {
        const ext = file.name.split('.').pop();
        return '.' + (ext === file.name ? '' : ext);
    }

    private getFileNameByFile(file: File) {
        return file.name.split('.').slice(0, -1).join('.');
    }

    private isValidWhenElementPresent(
        selector: string,
        callback: Function,
        interval: number
    ): boolean {
        const min = this.whenElementPresentDoMinSelector,
            max = this.whenElementPresentDoMaxSelector,
            isValidSelector = selector.length >= min && selector.length <= max,
            isValidCallback = Boolean(callback),
            isValidInterval = interval >= this.whenDoMinInterval;

        return isValidSelector && isValidCallback && isValidInterval;
    }
}
