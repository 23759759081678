<div [class]="getWrapperClass()">
    <div class="filters">
        <!--Search-->
        <div class="search">
            <div class="app-input-text">
                <input
                    type="text"
                    name="searchText"
                    [(ngModel)]="searchText"
                    (keyup)="handleSearchTextChange($event)"
                    placeholder="Search..."
                />
                <div class="app-clear-search-field-button">
                    <i class="material-icons icon" *ngIf="!searchText"
                        >search</i
                    >
                    <i
                        class="material-icons icon clear"
                        *ngIf="searchText"
                        (click)="handleClearSearchField()"
                        >clear</i
                    >
                </div>
            </div>
        </div>

        <!--Checklists Count-->
        <div class="cards-count">
            {{ checklistsCount }} {{ 'checklist' | pluralize: checklistsCount }}
        </div>

        <!--Sort & Status-->
        <div class="sort-and-status">
            <!--Status-->
            <div class="dropdown">
                <app-select
                    rel="status"
                    [width]="140"
                    [height]="28"
                    [scrollHeight]="80"
                    [shouldIncludeDefaultValue]="false"
                    [options]="statuses"
                    [selected]="selectedStatus"
                    (update)="handleStatusValueChange($event)"
                >
                </app-select>
            </div>

            <!--Sort-->
            <div class="dropdown">
                <app-select
                    rel="sort"
                    [width]="120"
                    [height]="28"
                    [scrollHeight]="104"
                    [shouldIncludeDefaultValue]="false"
                    [options]="sorts"
                    [selected]="selectedSort"
                    (update)="handleSortValueChange($event)"
                >
                </app-select>
            </div>

            <!--Owner-->
            <div class="dropdown">
                <app-select
                    rel="owner"
                    [width]="100"
                    [height]="28"
                    [scrollHeight]="104"
                    [shouldIncludeDefaultValue]="false"
                    [defaultLabel]="'Anyone'"
                    [options]="owners"
                    [selected]="selectedOwner"
                    (update)="handleOwnerValueChange($event)"
                >
                </app-select>
            </div>
        </div>
    </div>

    <div class="cards-wrapper" (scroll)="lazyLoadChecklists($event)">
        <div id="list-cards" class="cards-list">
            <app-ving-card *ngFor="let checklist of checklists"
                [origin]="'checklists'"
                [packet]="checklist"
                [selectedPacket]="selectedChecklist"
                [selectedPacketIds]="selectedChecklistIds"
                [excludedPacketIds]="excludedChecklistIds"
                [selectedAllPackets]="selectedAllChecklists"
                (handleToggleObjectId)="toggleSelectedChecklistId(checklist.id)"
                (handleClickObject)="handleClick($event)"
                (handleEditObject)="handleEdit($event)"
                (handleDeleteObject)="handleDelete($event)">
            </app-ving-card>
        </div>
    </div>
</div>
