import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { ContactsRoutingModule } from './contacts.routing.module';
import { AppControlsModule } from '../../shared/modules/app.controls.module';
import { AppPipesModule } from '../../shared/modules/app.pipes.module';
import { FilterNavigationModule } from '../../shared/components/app-filter-navigation/app.filter.navigation.module';
import { ContactsComponent } from './contacts.component';
import { ContactAssignmentsComponent } from './components/assignments/assignments.component';
import { ContactSubmissionsComponent } from './components/submissions/submissions.component';
import { DialogCreateContactComponent } from './dialogs/dialog-create-contact/dialog-create-contact.component';
import { DialogUploadContactsComponent } from './dialogs/dialog-upload-contacts/dialog-upload-contacts.component';
import { DialogMergeContactsComponent } from './dialogs/dialog-merge-contacts/dialog-merge-contacts.component';
import { DialogEditContactComponent } from './dialogs/dialog-edit-contact/dialog-edit-contact.component';
import { DialogCustomFilterComponent } from './dialogs/dialog-custom-filter/dialog-custom-filter.component';
import { ContactService } from './services/contact.service';
import { FormatPhonePipe } from '../../shared/pipes/app.phone.pipe';
import { FormatContactPipe } from '../../shared/pipes/app.format-contact.pipe';
import { MatChipsModule } from '@angular/material/chips';
import { AngularSplitModule } from 'angular-split';
import { MatExpansionModule } from '@angular/material/expansion';
import { AppMultiDragDropComponent } from '../../shared/components/app-multi-drag-and-drop/app.multi-drag-and-drop.component';
import { AppDialogEditCategoryComponent } from '../../shared/dialogs/dialog-edit-category/app.dialog-edit-category.component';
import { AppDialogCreateCategoryComponent } from '../../shared/dialogs/dialog-create-category/app.dialog-create-category.component';
import { DialogManageLabelsComponent } from '../../shared/dialogs/dialog-manage-labels/dialog.manage-labels.component';
import { MatListModule } from '@angular/material/list';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { ListComponent } from './components/list/list.component';
import { ContactCardComponent } from './components/contact-card/contact.card.component';

export function getWindow() {
    return window;
}

export function getLocalStorage() {
    return localStorage;
}

export function getLocation() {
    return location;
}

@NgModule({
    imports: [
        AppControlsModule,
        AppPipesModule,
        ContactsRoutingModule,
        FilterNavigationModule,
        MatChipsModule,
        AngularSplitModule,
        MatExpansionModule,
        MatListModule,
        ReactiveFormsModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        ContactsComponent,
        ContactAssignmentsComponent,
        ContactSubmissionsComponent,
        HeaderComponent,
        ListComponent,
        ContactCardComponent,
        /*Pipes*/
        FormatPhonePipe,
        FormatContactPipe,
        /*Dialogs*/
        DialogCreateContactComponent,
        DialogUploadContactsComponent,
        DialogMergeContactsComponent,
        DialogEditContactComponent,
        DialogManageLabelsComponent,
        DialogCustomFilterComponent,
        AppDialogEditCategoryComponent,
        AppDialogCreateCategoryComponent,
    ],
    providers: [
        ContactService,
        { provide: 'window', useFactory: getWindow },
        { provide: 'storage', useFactory: getLocalStorage },
        { provide: 'location', useFactory: getLocation },
    ],
    exports: [FormatPhonePipe, ContactCardComponent],
    entryComponents: [
        /*Dialogs*/
        DialogCreateContactComponent,
        DialogUploadContactsComponent,
        DialogMergeContactsComponent,
        DialogEditContactComponent,
        DialogManageLabelsComponent,
        DialogCustomFilterComponent,
    ],
})
export class ContactsModule {}
