import {
    Component,
    Inject,
    Input,
    Output,
    EventEmitter,
    OnInit,
    OnDestroy,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Chart } from 'chart.js';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import * as fileSaver from 'file-saver';

import { AppSelectOption } from '../../../../shared/components/app-select/classes/app.select.option';
import {
    XTable,
    XtCell,
    XtHeader,
    XtRow,
    XtAnswer,
} from '../../../../shared/components/app-power-table/classes/XTable';
import { XtFilter } from '../../../../shared/components/app-power-table/classes/XTableFilter';
import { columnWidths } from '../../../../shared/components/app-power-table/defaults';
import { PacketComponent } from '../../classes/packet-component';
import { Packet } from '../../classes/packet';
import { Share } from '../../../../shared/classes/share';
import { Question } from '../../../../shared/classes/questions/question';
import { Analytics } from './classes/analytics';
import { AnalyticsLabel } from '../../../../shared/classes/analytics/analytics.label';
import { AnalyticsSource } from '../../../../shared/classes/analytics/analytics.source';
import { ColumnAlignEnum } from '../../../../shared/classes/enums/column.align.enum';
import { AppHelpersService } from '../../../../shared/services/app.helpers.service';
import { AppHttpService } from '../../../../shared/services/app.http.service';
import { AppLoadingIndicatorService } from '../../../../shared/services/app.loading-indicator.service';
import { AppInfoService } from '../../../../shared/services/app-info/app.info.service';
import { AppMessageService } from '../../../../shared/services/app.message.service';
import { AppUserService } from '../../../../shared/services/app.user.service';
import { AppInfo } from '../../../../shared/services/app-info/app.info';
import { AppDataTransform } from '../../../../shared/app.data-transform';
import { AnalyticsFilters } from '../../../../shared/classes/analytics/analytics.filters';
import { DialogGetLinkComponent } from '../../dialogs/dialog-get-link/dialog.get.link.component';
import { DialogQrCodeComponent } from '../../dialogs/dialog-qr-code/dialog.qr.code.component';
import { AppDialogImageComponent } from '../../../../shared/dialogs/dialog-image/app.dialog-image.component';
import { AppDialogAcknowledgeComponent } from '../../../../shared/dialogs/dialog-acknowledge/app.dialog-acknowledge.component';
import { Utils } from '../../../../shared/app.utils';
import { DialogSendInvitationComponent } from '../../../../shared/dialogs/dialog-send-invitation/dialog.send.invitation.component';

@Component({
    selector: 'app-vings-selected-ving',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.less'],
})
export class DetailComponent implements OnInit, OnDestroy {
    orgHasAccess: boolean;
    userCanUpdate: boolean;
    userCanCopy: boolean;
    userCanRevise: boolean;
    messages = {
        success: {
            settingUpdated: 'Share settings successfully updated.',
        },
        error: {
            settingUpdated: 'There was an error saving the share settings.',
        },
    };
    displayChart: boolean;
    microburst: boolean;
    selectors = {
        tableBody: '.selected-view-table .power-table-body',
    };
    isOverviewSelected = false;
    selectedComponent = null;
    selectedComponentId: number;
    selectedComponentTitle: string;
    selectedQuestion: Question;
    selectedQuestionId: number;
    xTableData: XTable;
    xTableFilters: Array<XtFilter> = [];
    analyticsFilterData: {};
    analytics: Analytics;
    currentPageIndex: number;
    numberOfPages: number;
    numbersPerPage: number;
    numberOfResults: number;
    results: any;
    selectedContacts: Array<number> = [];
    excludedContacts: Array<number> = [];
    selectedAllContacts = false;
    packet: Packet;
    baseAssignmentDataUrl: string;
    share: Share;
    shareType: string;
    labels: Array<any> = [];
    sources: Array<any> = [];
    icons = {
        image: 'photo',
        file: 'insert_drive_file',
        document: 'content_copy',
        other: 'insert_drive_file',
        text: 'title',
        link: 'link',
        audio: 'volume_up',
        video: 'videocam',
        questions: 'assignment',
        certificate: 'workspace_premium'
    };
    questionType = {
        true_false: 'TRUE/FALSE',
        yes_no: 'YES/NO',
        likert: 'LIKERT',
        multiple_choice: 'MULTIPLE CHOICE',
        multiple_response: 'MULTIPLE RESPONSE',
        short_answer: 'SHORT ANSWER',
    };
    chartKeyItems = [
        { name: 'completed', label: 'Completed', color: this.appInfoService.getAppInfo().colorsInfo.analyticsChart.completed },
        { name: 'viewed', label: 'Viewed', color: this.appInfoService.getAppInfo().colorsInfo.analyticsChart.viewed },
        { name: 'unviewed', label: 'Not Viewed', color: this.appInfoService.getAppInfo().colorsInfo.analyticsChart.unviewed },
    ];
    entityName = 'assignment';
    noDataText: string;
    defaultDueDays: number;
    defaultExpirationDays: number;
    infoLink: string;
    updatePaging = new Subject();
    packetAnalyticsPageSize: number;
    componentAnalyticsPageSize: number;
    questionAnalyticsPageSize: number;

    packetSearchDelayMs: number;
    componentSearchDelayMs: number;
    questionSearchDelayMs: number;
    packetSubscription: any;

    sendReminders: boolean;

    private searchText: string;
    private selected = new AnalyticsFilters(null, null, null, null);
    private analyticsInterval = null;

    @Input() selectNewPacket: Observable<any>;
    @Output() handleSelectPacket = new EventEmitter<any>();
    @Output() handlePackets = new EventEmitter<any>();

    constructor(
        @Inject('window') private window,
        @Inject('location') private location,
        private appHelpersService: AppHelpersService,
        private appHttpService: AppHttpService,
        private appInfoService: AppInfoService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private appMessageService: AppMessageService,
        private appUserService: AppUserService,
        private dialog: MatDialog,
        private router: Router
    ) {}

    ngOnInit() {
        this.currentPageIndex = 0;
        this.isOverviewSelected = true;

        const appInfo = this.appInfoService.getAppInfo();
        this.packetAnalyticsPageSize =
            appInfo.analyticsInfo.packetAnalyticsInfo.pageSize;
        this.componentAnalyticsPageSize =
            appInfo.analyticsInfo.componentAnalyticsInfo.pageSize;
        this.questionAnalyticsPageSize =
            appInfo.analyticsInfo.questionAnalyticsInfo.pageSize;
        this.packetSearchDelayMs =
            appInfo.analyticsInfo.packetAnalyticsInfo.searchDelayMs;
        this.componentSearchDelayMs =
            appInfo.analyticsInfo.componentAnalyticsInfo.searchDelayMs;
        this.questionSearchDelayMs =
            appInfo.analyticsInfo.questionAnalyticsInfo.searchDelayMs;
        this.infoLink = appInfo.analyticsInfo.packetAnalyticsInfo.infoLink;
        this.noDataText = appInfo.analyticsInfo.packetAnalyticsInfo.noDataText;
        this.defaultDueDays = appInfo.analyticsInfo.packetAnalyticsInfo.defaultDueDays;
        this.defaultExpirationDays = appInfo.analyticsInfo.packetAnalyticsInfo.defaultExpirationDays;

        this.packetSubscription = this.selectNewPacket.subscribe(
            (newPacket: Packet) => {
                this.selectedComponent = undefined;
                this.selectedComponentId = undefined;
                this.packet = undefined;
                this.share = undefined;
                this.labels = [];
                this.sources = [];
                this.xTableFilters = [];
                this.searchText = '';
                this.selectedContacts = [];
                this.excludedContacts = [];
                this.selectedAllContacts = false;

                if (newPacket) {
                    this.initializeSelectedPacket(newPacket);
                }
            }
        );
        const pageRefreshMs = appInfo.packetsInfo.pageRefreshMs;

        this.analyticsInterval = setInterval(() => {
            if (this.packet && document.hasFocus()) {
                this.appLoadingIndicatorService.show(
                    this.selectors.tableBody,
                    '',
                    () => {
                        if (this.packet && this.isOverviewSelected) {
                            this.initializeSelectedPacket(null, false);
                        } else if (this.selectedComponent) {
                            if (this.selectedQuestion) {
                                this.initializeQuestion(
                                    this.selectedQuestion,
                                    false
                                );
                            } else {
                                this.initializeComponent(
                                    this.selectedComponent,
                                    false
                                );
                            }
                        } else {
                            this.appLoadingIndicatorService.hide(350);
                        }
                    }
                );
            }
        }, pageRefreshMs);
    }

    ngOnDestroy() {
        this.packetSubscription.unsubscribe();

        if (this.analyticsInterval) {
            clearInterval(this.analyticsInterval);
        }
    }

    displayPacketPreview(): void {
        let params = `?preview=${this.packet.id}`;
        if (this.selectedComponentId) {
            params += `&componentId=${this.selectedComponentId}`;
        }
        this.window.open(
            `${this.location.origin}/view/${this.packet.share.stub}${params}`
        );
    }

    displayComponentOverview(): void {
        // Clear out the selected component title.
        this.selectedComponentTitle = '';

        // Clear selected component id and overview boolean.
        this.selectedComponent = undefined;
        this.selectedComponentId = undefined;
        this.selectedQuestionId = undefined;
        this.isOverviewSelected = false;

        this.appLoadingIndicatorService.show('.selected-ving .body', '', () => {
            this.initializeSelectedPacket();
        });
    }

    handleClickComponent(component: PacketComponent): void {
        // Clear out the selected component title.
        this.selectedComponentTitle = '';

        // Clear selected component/question id and overview boolean.
        this.selectedComponentId = undefined;
        this.selectedComponent = undefined;
        this.selectedQuestionId = undefined;
        this.isOverviewSelected = false;

        // Init viewers table page.
        this.currentPageIndex = 0;

        this.appLoadingIndicatorService.show('.selected-ving .body', '', () => {
            this.initializeComponent(component);
        });
    }

    handleClickQuestion(question: Question): void {
        // Clear out the selected component title.
        this.selectedQuestion = null;

        // Clear selected question id.
        this.selectedQuestionId = question.id;

        // Init questions table page.
        this.currentPageIndex = 0;

        this.appLoadingIndicatorService.show('.selected-ving .body', '', () => {
            this.initializeQuestion(question);
        });
    }

    getSelectedComponentClass(id: number): string {
        return this.isComponentSelected(id)
            ? 'component selected'
            : 'component';
    }

    getSelectedQuestionClass(id: number): string {
        return this.isQuestionSelected(id) ? 'question selected' : 'question';
    }

    getScoreColor(item: any): string {
        return item.correct
            ? this.appInfoService.getAppInfo().colorsInfo.questions.correct
            : this.appInfoService.getAppInfo().colorsInfo.questions.incorrect;
    }

    getPercentage(value: any): number {
        let val = 0;
        if (value) {
            val = value * 100;
        }
        return Math.round(val);
    }

    handleSearchTextChange(newSearchText): void {
        this.appLoadingIndicatorService.show(this.selectors.tableBody);
        this.searchText = newSearchText;
        // Init analytics table page.
        this.currentPageIndex = 0;
        if (this.selectedQuestionId) {
            this.updateAnswerTableRows();
        } else {
            this.updateViewerTableRows();
        }
    }

    handleFiltersChange(update): void {
        const currentFilter = this.selected[update.filterName],
            newFilter = update.newFilterValue,
            type = Array.isArray(currentFilter) ? 'multi' : 'single';

        if (
            (type === 'single' &&
                this.selectedFilterHasChanged(currentFilter, newFilter)) ||
            (type === 'multi' &&
                this.selectedFiltersHaveChanged(currentFilter, newFilter))
        ) {
            this.appLoadingIndicatorService.show(this.selectors.tableBody);

            if (type === 'single') {
                this.selected[update.filterName] = update.newFilterValue;
            } else if (type === 'multi') {
                this.selected[update.filterName] =
                    this.appHelpersService.getClone(update.newFilterValue);
            }

            // Init analytics table page.
            this.currentPageIndex = 0;

            if (this.selectedQuestionId) {
                this.updateAnswerTableRows();
            } else {
                this.updateViewerTableRows();
            }
        }
    }

    handlePageChange(currentPageIndex): void {
        this.appLoadingIndicatorService.show(this.selectors.tableBody);
        this.currentPageIndex = currentPageIndex;
        if (this.selectedQuestionId) {
            this.updateAnswerTableRows(false);
        } else {
            this.updateViewerTableRows(false);
        }
    }

    handleSelectedRows(selectedRows): void {
        this.selectedContacts = selectedRows;
    }

    handleExcludedRows(excludedRows): void {
        this.excludedContacts = excludedRows;
    }

    handleSelectedAllRows(allSelected): void {
        this.selectedAllContacts = allSelected;
    }

    export(): void {
        this.appLoadingIndicatorService.show(this.selectors.tableBody);
        this.exportData();
    }

    remindersEnabled(share) {
        if (share.enforceOrder && share.consumeMinutes && share.consumeInterval) {
            return true;
        } else {
            return false;
        }
    }

    enableSaveShares(): boolean {
        if (this.remindersEnabled(this.packet.share) && !this.sendReminders) {
            return true;
        } else if (this.compareShares(this.packet.share, this.share)) {
            return false;
        } else {
            if (this.share.required && !this.share.dueDays) {
                return false;
            } else if (this.sendReminders && (!this.share.consumeMinutes || !this.share.consumeInterval)) {
                return false;
            }
        }

        return true;
    }

    saveShareSettings(): void {
        const packetDraft = this.appHelpersService.getClone(this.packet);

        if (!this.sendReminders) {
            this.share.consumeInterval = null;
            this.share.consumeMinutes = null;
        }

        packetDraft.share = this.share;

        this.appLoadingIndicatorService.show('view', 'Saving...');
        this.appHttpService.request(
            `PUT:api/packets/${this.packet.id}/`,
            packetDraft,
            (packet) => {
                this.packet = this.appHelpersService.getClone(packet);
                this.share = this.appHelpersService.getClone(packet.share);
                this.setSendReminders()
                this.appLoadingIndicatorService.hide(350);
                this.appMessageService.show(
                    'app',
                    'success',
                    this.messages.success.settingUpdated
                );
                this.handlePackets.emit(this.packet);
            },
            () => {
                this.appLoadingIndicatorService.hide(350);
                this.appMessageService.show(
                    'app',
                    'error',
                    this.messages.error.settingUpdated
                );
            }
        );
    }

    displayInviteModal(): void {
        const data = this.buildAnalyticsPacketData(),
            params = AppDataTransform.getConvertedData('snake', data),
            query = [];
        Object.keys(params || {}).forEach((key) => {
            const val = params[key];
            if (Array.isArray(val)) {
                query.push([key, encodeURIComponent(val.join(','))].join('='));
            } else {
                query.push([key, encodeURIComponent(val)].join('='));
            }
        });
        const path = this.isOverviewSelected
            ? `GET:${this.baseAssignmentDataUrl}`
            : `GET:${this.baseAssignmentDataUrl}component/${this.selectedComponentId}/`;
        const prePopulate = [];
        const allContacts= this.analytics.data.results.map((x) => x.contact);

        if (!this.selectedAllContacts) {
            this.selectedContacts.map((contactId) => {
                allContacts.find((result) => {
                    if (contactId === result.contactId) {
                        prePopulate.push(result);
                    }
                });
            });
        } else {
            if (
                this.excludedContacts.length ===
                this.analytics.data.results.length
            ) {
                this.selectedAllContacts = false;
            }
        }

        this.dialog.open(DialogSendInvitationComponent, {
            width: '700px',
            minHeight: '370px',
            height: 'auto',
            disableClose: true,
            data: {
                allContacts: allContacts,
                selectedContacts: this.appHelpersService.getClone(
                    this.selectedContacts
                ),
                excludedContacts: this.appHelpersService.getClone(
                    this.excludedContacts
                ),
                selectedAllContacts: {
                    status: this.appHelpersService.getClone(
                        this.selectedAllContacts
                    ),
                    analyticsFilterUrl: `${path}?${query.join('&')}`,
                    analyticsFilterCount: this.numberOfResults,
                },
                header: "Assign and Send Invitations",
                shareId: this.packet.share.id,
                shareType: "packet",
                invitationId: undefined,
                xTableData: this.xTableData,
                prePopulate: prePopulate,
                callback: () => {
                    console.log('invitations sent');
                },
            },
        });
    }

    displayGetLinkModal(): void {
        this.dialog.open(DialogGetLinkComponent, {
            width: '300px',
            minHeight: '150px',
            height: 'auto',
            data: {
                link: this.packet.share.link,
                callback: (dialog) => {
                    dialog.close();
                },
            },
        });
    }

    canShareAndPreview(packet): boolean {
        if (
            packet.share.active
            && this.orgHasAccess
            && (packet.duration || packet.share.type == 'requirement')
        ) {
            return true;
        } else {
            return false;
        }
    }

    displayQRCodeModal(): void {
        if (this.packet.share.qrCode && this.packet.share.qrCode.imageUrl) {
            this.dialog.open(DialogQrCodeComponent, {
                width: '390px',
                minHeight: '450px',
                height: 'auto',
                disableClose: true,
                data: {
                    img: this.packet.share.qrCode.imageUrl,
                    callback: (dialog) => {
                        this.appLoadingIndicatorService.show('modal');

                        const filename = `qr-code-${this.packet.id}.png`,
                            // eslint-disable-next-line @typescript-eslint/no-this-alias
                            that = this;
                        const xhr = new XMLHttpRequest();
                        xhr.open(
                            'GET',
                            this.packet.share.qrCode.downloadUrl,
                            true
                        );
                        xhr.responseType = 'blob';
                        xhr.addEventListener('load', function () {
                            dialog.close();
                            that.appLoadingIndicatorService.hide();
                            if (this.status === 200) {
                                const blob = this.response;
                                fileSaver.saveAs(blob, filename);
                            } else {
                                that.dialog.open(
                                    AppDialogAcknowledgeComponent,
                                    {
                                        width: '425px',
                                        disableClose: true,
                                        data: {
                                            text: 'Failed to download the QR code. Please try again later.',
                                        },
                                    }
                                );
                            }
                        });
                        xhr.send();
                    },
                },
            });
        }
    }

    recreatePacket(type): void {
        const params = {
            packet_id: this.packet.id,
        };
        if (type === 'COPY') {
            params['copy'] = true;
            this.createPacket(params);
        } else if (type === 'REVISE') {
            params['revise'] = true;
            this.createPacket(params);
        }
    }

    handleRevisionPacket(packetId): void {
        console.log("Select packet " + packetId);
        this.handleSelectPacket.emit(packetId);
    }

    isRevisionsExists() {
        return (
            this.packet &&
            'share' in this.packet &&
            this.packet.share.revisions.length
        );
    }

    displayDate(date) {
        const day = this.appHelpersService.getDisplayDate(date);
        const time = this.appHelpersService.getDisplayTime(date);
        return `${day} ${time}`;
    }

    displayDuration() {
        return Utils.roundTo5Minutes(this.packet.duration);
    }

    viewQuestionImage(url: string) {
        this.dialog.open(AppDialogImageComponent, {
            width: '700px',
            minHeight: '300px',
            height: 'auto',
            disableClose: true,
            data: {
                url: url,
            },
        });
    }

    get2LetterName(name: string) {
        return Utils.get2LetterName(name);
    }

    toggleRequired() {
        this.setSendReminders();
        this.setDefaultDueDays();
    }

    toggleEnforceOrder() {
        this.setSendReminders();
        this.setDefaultDueDays();
    }

    toggleReminders() {
        if (this.sendReminders) {
            this.share.required = true;
            this.share.enforceOrder = true;
            if (this.share.consumeMinutes && this.share.consumeInterval) {
                this.share.dueDays = this.getDefaultDueDays();
            } else {
                this.share.dueDays = null;
            }
        } else {
            this.share.dueDays = this.getDefaultDueDays();
        }
    }

    setSendReminders() {
        if (this.share.required && this.share.enforceOrder && this.share.consumeMinutes && this.share.consumeInterval) {
            this.sendReminders = true;
        } else {
            this.sendReminders = false;
        }
    }

    setDefaultDueDays() {
        this.share.dueDays = this.getDefaultDueDays();
    }

    getDefaultDueDays() {
        if (!this.share.required) {
            return null;
        } else if (this.share.enforceOrder && this.sendReminders && this.share.consumeMinutes && this.share.consumeInterval) {
            const sessions = Math.ceil(this.packet.duration / (this.share.consumeMinutes * 60));
            return sessions * this.share.consumeInterval;
        } else if (this.share.enforceOrder && this.sendReminders && (!this.share.consumeMinutes || !this.share.consumeInterval)) {
            return null;
        } else {
            return this.defaultDueDays;
        }
    }

    getDefaultExpirationDays() {
        if (!this.share.required) {
            return null;
        } else {
            return this.defaultExpirationDays;
        }
    }

    private updateViewerTableRows(shouldInitPagingData = true): void {
        const data = this.buildAnalyticsPacketData(),
            url = this.isOverviewSelected
                ? `GET:${this.baseAssignmentDataUrl}`
                : `GET:${this.baseAssignmentDataUrl}component/${this.selectedComponentId}/`;

        this.appHttpService.request(url, data, (response) => {
            if (shouldInitPagingData) {
                this.currentPageIndex = 0;
                this.results = response;
                this.numberOfResults = response.data.count;
                this.numberOfPages = Math.ceil(
                    this.numberOfResults / this.getPageSizeForContext()
                );
                setTimeout(() => {
                    this.updatePaging.next();
                }, 10);
            }

            this.analytics = response;

            // Update table.
            this.xTableData = new XTable(
                this.xTableData.headers,
                this.initAssignmentsTableRows(response.data.results)
            );

            // Redraw chart.
            this.setChartData();

            this.appLoadingIndicatorService.hide();
        });
    }

    private updateAnswerTableRows(shouldInitPagingData = true): void {
        const data = this.buildAnalyticsPacketData(),
            url = `GET:${this.baseAssignmentDataUrl}component/${this.selectedComponentId}/question/${this.selectedQuestionId}/`;

        this.appHttpService.request(url, data, (response) => {
            if (shouldInitPagingData) {
                this.currentPageIndex = 0;
                this.results = response;
                this.numberOfResults = response.data.count;
                this.numberOfPages = Math.ceil(
                    this.numberOfResults / this.getPageSizeForContext()
                );
                setTimeout(() => {
                    this.updatePaging.next();
                }, 10);
            }

            this.analytics = response;
            // the order of these matter, choices should be first.
            this.analytics.choices = response.stats.choices;
            this.analytics.stats = response.stats.overview;

            // Update table.
            this.xTableData = new XTable(
                this.xTableData.headers,
                this.initAnswersTableRows(response.data.results)
            );

            // Redraw chart.
            this.setChartData();

            this.appLoadingIndicatorService.hide();
        });
    }

    private initializeSelectedPacket(packet?: Packet, refresh = true) {
        if (packet) {
            this.packet = packet;
            this.baseAssignmentDataUrl = `api/data/assignments/packet/${this.packet.id}/`;
            this.share = this.appHelpersService.getClone(packet.share);
            if (this.share.type === 'packet') {
                this.shareType = 'Course';
                this.orgHasAccess = this.appUserService.hasPacketsAccess();
            } else if (this.share.type === 'requirement') {
                this.shareType = 'Requirement';
                this.orgHasAccess = this.appUserService.hasRequirementsAccess();
            }
            this.userCanUpdate = this.appUserService.canUpdatePacket(this.packet);
            this.userCanCopy = this.appUserService.canCopyPacket(this.packet);
            this.userCanRevise = this.appUserService.canRevisePacket(this.packet);

            this.microburst = !!(
                this.share.consumeInterval && this.share.consumeMinutes
            );

            this.setSendReminders()

            // Reset filters, labels, sorts
            this.selected = new AnalyticsFilters(
                null,
                null,
                null,
                null
            );
        }

        // Init viewers table page.
        if (refresh) {
            this.currentPageIndex = 0;
        }

        const data = this.buildAnalyticsPacketData();

        // Query analytics data for this packet.
        this.appHttpService.request(
            `GET:${this.baseAssignmentDataUrl}`,
            data,
            (response) => {
                this.isOverviewSelected = true;
                this.selectedComponent = null;
                this.selectedComponentId = undefined;
                this.selectedComponentTitle = undefined;
                this.analytics = response;

                // Initialize labels.
                this.labels = this.buildLabels(response.labels);

                // Initialize sources.
                this.sources = this.buildSources(response.sources);

                // Initialize filters for table.
                this.initXFilters();

                // Initialize table.
                this.initViewersTable(refresh);

                // Initialize chart.
                this.setChartData();

                this.appLoadingIndicatorService.hide();
            }
        );
    }

    private initializeComponent(component: PacketComponent, refresh = true) {
        const data = this.buildAnalyticsPacketData();

        const url = `GET:${this.baseAssignmentDataUrl}component/${component.id}/`;

        this.appHttpService.request(url, data, (response) => {
            this.selectedComponent = component;
            this.selectedComponentId = component.id;
            this.selectedComponentTitle = this.packet.components.find(
                (c) => c.id === component.id
            ).title;
            this.selectedQuestion = undefined;
            this.analytics = response;

            // Initialize labels.
            this.labels = this.buildLabels(response.labels);

            // Initialize sources.
            this.sources = this.buildSources(response.sources);

            // Initialize filters for table.
            this.initXFilters();

            // Initialize table.
            this.initViewersTable(refresh);

            // Initialize chart.
            this.setChartData();

            this.appLoadingIndicatorService.hide();
        });
    }

    private initializeQuestion(question: Question, refresh = true) {
        const data = this.buildAnalyticsPacketData();

        const url = `GET:${this.baseAssignmentDataUrl}component/${this.selectedComponentId}/question/${question.id}/`;

        this.appHttpService.request(url, data, (response) => {
            this.selectedQuestion = question;
            this.analytics = response;
            // the order of these matter, choices should be first.
            this.analytics.choices = response.stats.choices;
            this.analytics.stats = response.stats.overview;

            // Initialize labels.
            this.labels = this.buildLabels(response.labels);

            // Initialize sources.
            this.sources = this.buildSources(response.sources);

            // Initialize filters for table.
            this.initXFilters();

            // Initialize table.
            this.initAnswersTable(refresh);

            // Initialize chart.
            this.setChartData();

            this.appLoadingIndicatorService.hide();
        });
    }

    private compareShares(packetShare, share): boolean {
        return JSON.stringify(packetShare) === JSON.stringify(share);
    }

    private exportData(): void {
        const data = this.buildAnalyticsPacketData(false);
        data['export'] = true;
        data['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

        let url = '';
        if (this.isOverviewSelected) {
            url = `GET:${this.baseAssignmentDataUrl}`;
        } else {
            if (this.selectedQuestionId) {
                url = `GET:${this.baseAssignmentDataUrl}component/${this.selectedComponentId}/question/${this.selectedQuestionId}/`;
            } else {
                url = `GET:${this.baseAssignmentDataUrl}component/${this.selectedComponentId}/`;
            }
        }

        this.appHttpService.requestBlob(url, data, (response) => {
            this.appLoadingIndicatorService.hide();
            this.dialog.open(AppDialogAcknowledgeComponent, {
                width: '425px',
                disableClose: true,
                data: {
                    title: 'Data Export',
                    text: 'Your requested export is being generated and you will receive it via email soon!',
                },
            });
        });
    }

    private buildLabels(
        labels: Array<AnalyticsLabel>
    ): Array<AppSelectOption> {
        const packetLabels: Array<AppSelectOption> = [];

        if (labels !== undefined) {
            labels.forEach((label) => {
                packetLabels.push(
                    new AppSelectOption(label.text, label.id.toString())
                );
            });
        }

        return packetLabels;
    }

    private buildSources(
        sources: Array<AnalyticsSource>
    ): Array<AppSelectOption> {
        const packetSources: Array<AppSelectOption> = [];

        if (sources !== undefined) {
            sources.forEach((source) => {
                packetSources.push(
                    new AppSelectOption(source.text, source.id.toString())
                );
            });
        }

        return packetSources;
    }

    private selectedFilterHasChanged(
        currentFilter: AppSelectOption,
        newFilter: AppSelectOption
    ): boolean {
        const currentValue = currentFilter ? currentFilter.value : undefined,
            newValue = newFilter ? newFilter.value : undefined;

        return currentValue !== newValue;
    }

    private selectedFiltersHaveChanged(
        currentFilter: Array<AppSelectOption>,
        newFilter: Array<AppSelectOption>
    ): boolean {
        let hasChanged = false;

        // If the lengths of the new and old filters have changed, this is a sign
        // that the selected values have changed.
        if (currentFilter.length !== newFilter.length) {
            hasChanged = true;
        } else {
            // Build the list of current and new values joined into a concatenated
            // strings. This allows us to compare the current and new values in
            // subsequent steps.
            const currentValues = currentFilter
                    .map((filter) => filter.value)
                    .sort()
                    .join(''),
                newValues = newFilter
                    .map((filter) => filter.value)
                    .sort()
                    .join('');

            // If the lengths of the string values of the new and old filters have
            // changed or if the string values representation are different then this
            // tells us that the values have changed.
            if (
                currentValues.length !== newValues.length ||
                currentValues !== newValues
            ) {
                hasChanged = true;
            }
        }

        return hasChanged;
    }

    private initXFilters(filters = true): void {
        // Init above-table drop-downs.
        const appInfo = this.appInfoService.getAppInfo(),
            filtersOptions = this.buildOptions(
                this.getFiltersForContext(appInfo)
            ),
            sortsData = this.getSortsForContext(appInfo),
            sortsOptions = this.buildOptions(sortsData);

        // Clear previous table filters list.
        this.xTableFilters = [];

        // Filters
        if (filters) {
            this.xTableFilters.push(
                new XtFilter(
                    'multi',
                    'Filters',
                    'filters',
                    115,
                    79,
                    filtersOptions,
                    this.buildOptions(this.selected.filters, true),
                    true
                )
            );
        }

        // Labels
        this.xTableFilters.push(
            new XtFilter(
                'multi',
                'Labels',
                'labels',
                115,
                105,
                this.labels,
                this.buildOptions(this.selected.labels, true),
                true
            )
        );

        // Sorts
        this.xTableFilters.push(
            new XtFilter(
                'single',
                'Sort',
                'sort',
                115,
                105,
                sortsOptions,
                this.getSelectedSorts(sortsData),
                false
            )
        );
    }

    private buildOptions(list, label = false): Array<AppSelectOption> {
        const options: Array<AppSelectOption> = [];

        list.forEach((item) => {
            options.push(
                new AppSelectOption(label ? item.label : item.name, item.value)
            );
        });

        return options;
    }

    private getSelectedSorts(list): Array<AppSelectOption> {
        if (this.selected.sort) {
            const appInfo = this.appInfoService.getAppInfo(),
                sortsData = this.getSortsForContext(appInfo),
                sortsOptions = this.buildOptions(sortsData);
            const option = sortsOptions.filter((opt) => {
                return opt.value === this.selected.sort.value;
            });
            if (option && option.length) {
                return this.buildOptions([this.selected.sort], true);
            } else if (sortsOptions && sortsOptions.length) {
                return this.buildOptions([sortsOptions[0]], true);
            }
        } else {
            const options: Array<AppSelectOption> = [];

            const selectedItem = list.find(
                (filter) => filter.selected === true
            );
            options.push(
                new AppSelectOption(selectedItem.name, selectedItem.value)
            );

            return options;
        }
    }

    private setChartData() {
        this.appHelpersService.whenElementPresentDo(
            '#selected-view-chart',
            () => {
                const chartInvited = document.getElementById(
                    'standard-doughnut-chart'
                );
                if (this.analytics) {
                    const stats = this.appHelpersService.getClone(
                            this.analytics.stats
                        ),
                        statsTotal =
                            stats.viewed + stats.completed + stats.unviewed;

                    Chart.defaults.global.defaultFontColor = '#757575';
                    Chart.defaults.global.defaultFontFamily =
                        'Lato, sans-serif';
                    Chart.defaults.global.elements.line.fill = false;

                    chartInvited.innerHTML =
                        '<canvas id="canvas-standard-chart"></canvas>';
                    const context = document
                        .getElementById('canvas-standard-chart')
                        ['getContext']('2d');

                    const chartData = {
                        labels: ['Viewed', 'Completed', 'Not Viewed'],
                        datasets: [
                            {
                                data: [
                                    this.analytics.stats.viewed,
                                    this.analytics.stats.completed,
                                    this.analytics.stats.unviewed,
                                ],
                                backgroundColor: [
                                    this.appInfoService.getAppInfo().colorsInfo
                                        .analyticsChart.viewed,
                                    this.appInfoService.getAppInfo().colorsInfo
                                        .analyticsChart.completed,
                                    this.appInfoService.getAppInfo().colorsInfo
                                        .analyticsChart.unviewed,
                                ],
                            },
                        ],
                    };

                    this.drawChart('', context, chartData);
                    setTimeout(() => {
                        this.displayChart = statsTotal > 0;
                        this.appLoadingIndicatorService.hide(350);
                    }, 150);
                } else {
                    this.displayChart = false;
                    this.appLoadingIndicatorService.hide(350);
                }
            }
        );
    }

    private drawChart(title, context, data) {
        const chart = new Chart(context, {
            type: 'doughnut',
            data: data,
            options: this.getChartOptionsDoughnut(title),
        });
    }

    private getChartOptionsDoughnut(title) {
        return {
            legend: {
                display: false,
            },
            maintainAspectRatio: false,
            title: {
                display: false,
                text: title,
            },
            tooltips: {
                mode: 'label',
                backgroundColor: '@app-dark',
            },
            responsive: true,
        };
    }

    private buildAnalyticsPacketData(limit = true): object {
        const data = {};

        // Limit
        if (limit) {
            data['limit'] = this.getPageSizeForContext();
        }

        // Query search text.
        if (this.searchText && this.searchText.length > 0) {
            data['contactQuery'] = this.searchText;
        }

        // Conditionally set the offset.
        if (this.currentPageIndex + 1 > 0 && limit) {
            data['offset'] =
                this.currentPageIndex * this.getPageSizeForContext();
        }

        // Conditionally apply 'filters', 'labels', 'sources', 'sorts'
        ['filters', 'labels', 'source', 'sort'].forEach((filter) => {
            if (filter === 'filters') {
                if (this.selected[filter].length > 0) {
                    data[filter] = this.selected[filter].map(
                        (item) => item.value
                    );
                }
            } else if (filter === 'labels') {
                if (this.selected[filter].length > 0) {
                    data['contactLabels'] = this.selected[filter].map(
                        (item) => item.value
                    );
                }
            } else if (filter === 'sort') {
                const appInfo = this.appInfoService.getAppInfo(),
                    sortsData = this.getSortsForContext(appInfo),
                    sortsOptions = this.buildOptions(sortsData);
                if (this.selected[filter] && this.selected[filter].value) {
                    const option = sortsOptions.filter((opt) => {
                        return opt.value === this.selected[filter].value;
                    });
                    if (option && option.length) {
                        data[filter] = this.selected[filter].value;
                    } else if (sortsOptions && sortsOptions.length) {
                        data[filter] = sortsOptions[0].value;
                    }
                } else if (sortsOptions && sortsOptions.length) {
                    data[filter] = sortsOptions[0].value;
                }
            }
        });

        this.analyticsFilterData = data;

        return data;
    }

    private initViewersTable(shouldHideLoadingIndicator = false) {
        // Update total results.
        this.results = this.analytics.data;
        this.numberOfResults = this.analytics.data.count;
        this.numberOfPages = Math.ceil(
            this.numberOfResults / this.getPageSizeForContext()
        );
        this.numbersPerPage = this.getPageSizeForContext();

        const viewers = this.analytics.data.results,
            rows: Array<XtRow> = this.initAssignmentsTableRows(viewers);
        let headers: Array<XtHeader> = null;

        if (this.isOverviewSelected) {
            headers = [
                new XtHeader('', columnWidths['smallIcon'], ColumnAlignEnum.CENTER),
                new XtHeader('', columnWidths['smallIcon'], ColumnAlignEnum.CENTER),
                new XtHeader('Name', columnWidths['name']),
                new XtHeader('', columnWidths['bigIcon'], ColumnAlignEnum.CENTER),
                new XtHeader('', columnWidths['bigIcon'], ColumnAlignEnum.CENTER),
                new XtHeader('Assigned', columnWidths['date'], ColumnAlignEnum.CENTER),
                new XtHeader('Last Invited', columnWidths['date'], ColumnAlignEnum.CENTER, 'hide-narrow-window'),
                new XtHeader('Visits', columnWidths['visits'], ColumnAlignEnum.CENTER, 'hide-narrow-window'),
                new XtHeader('Due', columnWidths['date'], ColumnAlignEnum.CENTER),
                new XtHeader('Viewed', columnWidths['viewed'], ColumnAlignEnum.CENTER),
                new XtHeader('Progress', columnWidths['progress'], ColumnAlignEnum.CENTER),
                new XtHeader('Score', columnWidths['score'], ColumnAlignEnum.CENTER),
                new XtHeader('Completed', columnWidths['date'], ColumnAlignEnum.CENTER),
                new XtHeader('Expires', columnWidths['date'], ColumnAlignEnum.CENTER),
                new XtHeader('Cert', columnWidths['visits'], ColumnAlignEnum.CENTER),
            ];
        } else if (
            this.selectedComponent &&
            this.selectedComponent.type === 'questions'
        ) {
            headers = [
                new XtHeader('', columnWidths['smallIcon'], ColumnAlignEnum.CENTER),
                new XtHeader('', columnWidths['smallIcon'], ColumnAlignEnum.CENTER),
                new XtHeader('Name', columnWidths['name']),
                new XtHeader('', columnWidths['smallIcon'], ColumnAlignEnum.CENTER),
                new XtHeader('', columnWidths['bigIcon'], ColumnAlignEnum.CENTER),
                new XtHeader('Visits', columnWidths['visits'], ColumnAlignEnum.CENTER),
                new XtHeader('Viewed', columnWidths['viewed'], ColumnAlignEnum.CENTER),
                new XtHeader('Progress', columnWidths['progress'], ColumnAlignEnum.CENTER),
                new XtHeader('Score', columnWidths['score'], ColumnAlignEnum.CENTER),
                new XtHeader('Completed', columnWidths['date'], ColumnAlignEnum.CENTER),
            ];
        } else {
            headers = [
                new XtHeader('', columnWidths['smallIcon'], ColumnAlignEnum.CENTER),
                new XtHeader('', columnWidths['smallIcon'], ColumnAlignEnum.CENTER),
                new XtHeader('Name', columnWidths['name']),
                new XtHeader('', columnWidths['smallIcon'], ColumnAlignEnum.CENTER),
                new XtHeader('', columnWidths['bigIcon'], ColumnAlignEnum.CENTER),
                new XtHeader('Visits', columnWidths['visits'], ColumnAlignEnum.CENTER),
                new XtHeader('Viewed', columnWidths['viewed'], ColumnAlignEnum.CENTER),
                new XtHeader('Progress', columnWidths['progress'], ColumnAlignEnum.CENTER),
                new XtHeader('Completed', columnWidths['date'], ColumnAlignEnum.CENTER),
            ];
        }

        this.xTableData = new XTable(headers, rows);

        if (shouldHideLoadingIndicator) {
            this.appLoadingIndicatorService.hide(250);
        }
    }

    private initAssignmentsTableRows(assignments): Array<XtRow> {
        const rows: Array<XtRow> = [],
            emptyEntry = '';

        assignments.forEach((assignment) => {
            let isViewSet=false,
                thisProgress,
                thisDuration;

            if (this.isOverviewSelected) {
                thisDuration = this.packet.duration;
                if (!this.appHelpersService.isEmptyObject(assignment.packetProgress)) {
                    isViewSet = true;
                    thisProgress = assignment.packetProgress;
                }
            } else {
                thisDuration = this.selectedComponent.duration;
                if (
                    !this.appHelpersService.isEmptyObject(assignment.packetProgress)
                    && !this.appHelpersService.isEmptyObject(assignment.componentProgress)
                ) {
                    isViewSet = true;
                    thisProgress = assignment.componentProgress;
                }
            }

            const isComplete = assignment.dateCompleted != undefined,
                durationComplete = isViewSet ? thisProgress.durationComplete : 0;

            const selectable = Boolean(assignment.contact.defaultEmailOrPhone || assignment.dateAssigned),
                columnTwoIcon =
                    isViewSet && assignment.packetProgress.verified ? 'verified_user' : '',
                dateAssignedDate = this.getDisplayDate(
                    assignment.dateAssigned
                ),
                dateAssignedTime = this.getDisplayTime(
                    assignment.dateAssigned
                ),
                dateDueDate = this.getDisplayDate(
                    assignment.dateDue
                ),
                dateDueTime = this.getDisplayTime(
                    assignment.dateDue
                ),
                dateExpiresDate = this.getDisplayDate(
                    assignment.dateExpires
                ),
                dateExpiresTime = this.getDisplayTime(
                    assignment.dateExpires
                ),
                lastInvitedDate = this.getDisplayDate(
                    assignment.lastInvitationSent
                ),
                lastInvitedTime = this.getDisplayTime(
                    assignment.lastInvitationSent
                ),
                dateCompleted = isComplete
                    ? this.getDisplayDate(assignment.dateCompleted)
                    : '--',
                dateCompletedTime = isComplete
                    ? this.getDisplayTime(assignment.dateCompleted)
                    : '',
                viewedDuration = isViewSet || isComplete
                    ? Utils.getDurationCompleteValue(isComplete, durationComplete, thisDuration)
                    : '--',
                visitsCount = isViewSet ? thisProgress.visitsCount : '',
                scoreColor =
                    isViewSet &&
                    thisProgress.score !== null &&
                    thisProgress.score >= 0
                        ? this.appHelpersService.getScoresColor(thisProgress.score)
                        : null,
                score =
                    isViewSet &&
                    thisProgress.score !== null &&
                    thisProgress.score >= 0
                        ? `${(thisProgress.score * 100).toFixed(0)}%`
                        : '--',
                lastInvitationMethod = assignment.lastInvitationMethod;

            const progress = isViewSet ? thisProgress.progress : 0;
            let columnThreeIcon = '',
                columnThreeError = null,
                progressCell: XtCell,
                certificateCell: XtCell;

            switch (lastInvitationMethod) {
                case 'email':
                    columnThreeIcon = 'email';
                    if (assignment.invitationFailed) {
                        columnThreeError = 'Last invitation undeliverable'
                    }
                    break;
                case 'phone':
                    columnThreeIcon = 'sms';
                    if (assignment.invitationFailed) {
                        columnThreeError = 'Last invitation undeliverable'
                    }
                    break;
                case null:
                    if (assignment.invitationFailed) {
                        columnThreeIcon = 'email';
                        columnThreeError = 'No active contact methods'
                    } else {
                        columnThreeIcon = 'open_in_new';
                    }
                    break;
            }

            // Set progress cell.
            if (isComplete) {
                progressCell = new XtCell(
                    'progress',
                    null,
                    null,
                    null,
                    null,
                    null,
                    true
                );
            } else {
                progressCell = new XtCell(
                    'progress',
                    null,
                    null,
                    null,
                    null,
                    this.getProgressValue(progress)
                );
            }

            // Set certificate cell
            if (assignment.certificateUrl) {
                certificateCell = new XtCell(
                    'certificateDownload',
                    null,
                    null,
                    null,
                    null,
                )
            } else {
                certificateCell = new XtCell(
                    'certificateUpload',
                    null,
                    null,
                    null,
                    null,
                )
            }

            let cells = null;

            if (this.isOverviewSelected) {
                cells = [
                    new XtCell(null, null, null, null, columnTwoIcon),
                    new XtCell(null, null, null, null, columnThreeIcon, null, null, columnThreeError),
                    new XtCell(
                        'title',
                        !assignment.contact.name || /^ *$/.test(assignment.contact.name)
                            ? 'Name not set'
                            : assignment.contact.name,
                        null,
                        this.getAssignmentContactMethod(assignment)
                    ),
                    new XtCell('sentiment', null, null, null, assignment.sentiment.icon, null, null, null, null, assignment.sentiment.color),
                    new XtCell('pdf'),
                    new XtCell('dateAssigned', dateAssignedDate, null, dateAssignedTime),
                    new XtCell('lastInvited', lastInvitedDate, 'hide-narrow-window', lastInvitedTime),
                    new XtCell('visits', visitsCount, 'hide-narrow-window'),
                    new XtCell('dateDue', dateDueDate, null, dateDueTime),
                    new XtCell('viewedDuration', viewedDuration),
                    progressCell,
                    new XtCell(
                        'score',
                        score,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        scoreColor
                    ),
                    new XtCell('dateCompleted', dateCompleted, null, dateCompletedTime),
                    new XtCell('dateExpires', dateExpiresDate, null, dateExpiresTime),
                    certificateCell,
                ];
            } else if (
                this.selectedComponent &&
                this.selectedComponent.type === 'questions'
            ) {
                cells = [
                    new XtCell(null, null, null, null, columnTwoIcon),
                    new XtCell(null, null, null, null, columnThreeIcon),
                    new XtCell(
                        'title',
                        !assignment.contact.name || /^ *$/.test(assignment.contact.name)
                            ? 'Name not set'
                            : assignment.contact.name,
                        null,
                        this.getAssignmentContactMethod(assignment)
                    ),
                    new XtCell('sentiment', null, null, null, assignment.sentiment.icon, null, null, null, null, assignment.sentiment.color),
                    new XtCell('pdf'),
                    new XtCell(null, visitsCount),
                    new XtCell('viewedDuration', viewedDuration),
                    progressCell,
                    new XtCell(
                        'score',
                        score,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        scoreColor
                    ),
                    new XtCell('dateCompleted', dateCompleted, null, dateCompletedTime),
                ];
            } else {
                cells = [
                    new XtCell(null, null, null, null, columnTwoIcon),
                    new XtCell(null, null, null, null, columnThreeIcon),
                    new XtCell(
                        'title',
                        !assignment.contact.name || /^ *$/.test(assignment.contact.name)
                            ? 'Name not set'
                            : assignment.contact.name,
                        null,
                        this.getAssignmentContactMethod(assignment)
                    ),
                    new XtCell('sentiment', null, null, null, assignment.sentiment.icon, null, null, null, null, assignment.sentiment.color),
                    new XtCell('pdf'),
                    new XtCell(null, visitsCount),
                    new XtCell('viewedDuration', viewedDuration),
                    progressCell,
                    new XtCell('dateCompleted', dateCompleted, null, dateCompletedTime),
                ];
            }

            rows.push(
                new XtRow(
                    assignment.contact.contactId,
                    cells,
                    selectable,
                    assignment.assignmentId,
                    assignment.contact.contactId,
                    undefined,
                    assignment.assignmentId,
                    assignment.certificateUrl
                )
            );
        });
        return rows;
    }

    private initAnswersTable(shouldHideLoadingIndicator = false) {
        // Update total results.
        this.results = this.analytics.data;
        this.numberOfResults = this.analytics.data.count;
        this.numberOfPages = Math.ceil(
            this.numberOfResults / this.getPageSizeForContext()
        );
        this.numbersPerPage = this.getPageSizeForContext();

        const viewers = this.analytics.data.results,
            rows: Array<XtRow> = this.initAnswersTableRows(viewers),
            headers: Array<XtHeader> = [
                new XtHeader('Name', columnWidths['name']),
                new XtHeader('Answer', columnWidths['answer'], ColumnAlignEnum.LEFT),
                new XtHeader('Correct', columnWidths['score'], ColumnAlignEnum.CENTER),
            ];

        this.xTableData = new XTable(headers, rows);

        if (shouldHideLoadingIndicator) {
            this.appLoadingIndicatorService.hide(250);
        }
    }

    private initAnswersTableRows(assignments): Array<XtRow> {
        const rows: Array<XtRow> = [],
            emptyEntry = '--';

        assignments.forEach((assignment) => {
            const isViewSet = !this.appHelpersService.isEmptyObject(assignment.componentProgress),
                selectable = Boolean(assignment.contact.defaultEmailOrPhone),
                tag = assignment.source ? assignment.source : emptyEntry,
                correct = this.selectedQuestion.scored
                    ? assignment.answer
                        ? assignment.answer.isCorrect
                            ? '<img src="/assets/images/icons/success-outline.png" height="18" />'
                            : '<img src="/assets/images/icons/error-outline.png" height="18" />'
                        : emptyEntry
                    : emptyEntry;
            const answers: Array<XtAnswer> = [];
            let answer: XtAnswer, tagCell: XtCell;

            if (assignment.answer) {
                if (this.selectedQuestion.type === 'short_answer') {
                    answer = new XtAnswer(assignment.answer.text);
                    answers.push(answer);
                } else {
                    assignment.answer.choices.map((choice) => {
                        answer = new XtAnswer(choice.text, choice.imageUrl);
                        answers.push(answer);
                    });
                }
            }

            const cells = [
                new XtCell(
                    'title',
                    !assignment.contact.name || /^ *$/.test(assignment.contact.name)
                        ? 'Name not set'
                        : assignment.contact.name,
                    null,
                    assignment.contact.defaultEmailOrPhone
                ),
                new XtCell(
                    'answer',
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    answers
                ),
                new XtCell('correct', correct),
            ];

            rows.push(new XtRow(assignment.contact.contactId, cells, selectable, assignment.assignmentId));
        });
        return rows;
    }

    private isComponentSelected(id: number): boolean {
        return this.selectedComponentId && this.selectedComponentId === id;
    }

    private isQuestionSelected(id: number): boolean {
        return this.selectedQuestionId && this.selectedQuestionId === id;
    }

    private getAssignmentContactMethod(assignment): string {
        let method = '';

        if (assignment.lastInvitationEmailOrPhone) {
            method = assignment.lastInvitationEmailOrPhone;
        } else if (assignment.contact.defaultEmailOrPhone) {
            method = assignment.contact.defaultEmailOrPhone;
        }

        return method;
    }

    private getProgressValue(progress: number): number {
        return Math.ceil(progress * 100);
    }

    private getPageSizeForContext(): number {
        return this.selectedQuestionId
            ? this.questionAnalyticsPageSize
            : this.isOverviewSelected
            ? this.packetAnalyticsPageSize
            : this.componentAnalyticsPageSize;
    }

    private getFiltersForContext(appInfo: AppInfo) {
        let filters;

        if (this.isOverviewSelected) {
            filters = appInfo.analyticsInfo.packetAnalyticsInfo.filters;
        } else {
            filters = appInfo.analyticsInfo.componentAnalyticsInfo.filters;
        }

        return filters;
    }

    private getSortsForContext(appInfo: AppInfo) {
        let sorts;

        if (this.isOverviewSelected) {
            sorts = appInfo.analyticsInfo.packetAnalyticsInfo.sorts;
        } else {
            if (this.selectedQuestionId) {
                sorts = appInfo.analyticsInfo.questionAnalyticsInfo.sorts;
            } else {
                sorts = appInfo.analyticsInfo.componentAnalyticsInfo.sorts;
            }
        }

        return sorts;
    }

    private getSearchDelayMs(): number {
        return this.selectedQuestionId
            ? this.questionSearchDelayMs
            : this.isOverviewSelected
            ? this.packetSearchDelayMs
            : this.componentSearchDelayMs;
    }

    private createPacket(params: any): void {
        this.appLoadingIndicatorService.show('app', '');

        this.appHttpService.request(
            'POST:api/packets/',
            params,
            (response) => {
                this.router.navigateByUrl(`/vings/${response.id}/edit`);
            },
            () => {
                this.router.navigate(['/dashboard'], {
                    queryParams: { error: 'creatingPacket' },
                });
            }
        );
    }

    private getDisplayDate(date): string {
        return date ? moment(date).format('L') : '--';
    }

    private getDisplayTime(time): string {
        return time ? moment(time).format('LT') : '';
    }
}
