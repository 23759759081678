import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AppService } from '../../../../app.service';
import { AppLoadingIndicatorService } from '../../../../shared/services/app.loading-indicator.service';
import { AppJobService } from '../../../../shared/services/job/app.job.service';
import { AppMessageService } from '../../../../shared/services/app.message.service';
import { AppHelpersService } from '../../../../shared/services/app.helpers.service';
import { AppHttpService } from '../../../../shared/services/app.http.service';
import { AppAlertService } from '../../../../shared/services/app.alert.service';
import { AppInfoService } from '../../../../shared/services/app-info/app.info.service';
import { AppMessage } from '../../../../shared/app.messages';
import { GroupOption } from '../../../../shared/classes/group-option';
import { ComponentFile } from './classes/component-file';
import { AppDialogAcknowledgeComponent } from '../../../../shared/dialogs/dialog-acknowledge/app.dialog-acknowledge.component';
import { AppDialogConfirmComponent } from '../../../../shared/dialogs/dialog-confirm/app.dialog-confirm.component';
import { QuestionErrors } from '../../../../shared/classes/questions/question-errors';
import { QuestionsValidationService } from './services/questions.validation.service';
import { DialogEditDetailsComponent } from './dialogs/edit-details/dialog.edit-details.component';
import * as $ from 'jquery';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Question } from '../../../../shared/classes/questions/question';
import { QuestionType, questionTypeIcons } from '../../../../shared/classes/questions/question-type';

@Component({
    selector: 'app-edit-checklist',
    templateUrl: './edit.component.html',
    styleUrls: ['./edit.component.less'],
})
export class EditComponent implements OnInit, OnDestroy {
    @ViewChild('autoscroll') autoscroll: ElementRef;
    @ViewChild('selectFileField') selectFileField: ElementRef;
    addQuestionButtons = [
        {
            type: 'questions',
            questionType: 'true_false',
            text: 'True / False',
            componentTypeImage: '/assets/images/true-false.svg',
            isSupported: true,
        },
        {
            type: 'questions',
            questionType: 'yes_no',
            text: 'Yes / No',
            componentTypeImage: '/assets/images/yes-no.svg',
            isSupported: true,
        },
        {
            type: 'questions',
            questionType: 'likert',
            text: 'Likert Scale',
            componentTypeImage: '/assets/images/likert.svg',
            isSupported: true,
        },
        {
            type: 'questions',
            questionType: 'multiple_choice',
            text: 'Multiple Choice',
            componentTypeImage: '/assets/images/multiple-choice.svg',
            isSupported: true,
        },
        {
            type: 'questions',
            questionType: 'multiple_response',
            text: 'Multiple Response',
            componentTypeImage:
                '/assets/images/multiple-response.svg',
            isSupported: true,
        },
        {
            type: 'questions',
            questionType: 'short_answer',
            text: 'Short Answer',
            componentTypeImage: '/assets/images/short-answer.svg',
            isSupported: true,
        },
    ];
    componentFiles: Array<ComponentFile> = [];
    isAddingNewComponents = true;
    messages = {
        success: {
            componentDeleted: 'Question successfully deleted.',
            componentUpdated: 'Question successfully updated.',
            checklistSaved: 'Successfully saved.',
        },
        error: {
            questionsComponent:
                'Errors have been detected within your question set. Please review.',
            saveComponent: 'The following fields have errors: ',
            checklist: {
                save: 'Errors have been detected within the checklist. Please review.',
                componentsNone: 'Must have at least 1 component.',
                componentsHasInvalid: 'One or more questions have errors.',
            },
        },
    };
    questionErrors: QuestionErrors;
    selectedQuestion: Question;
    show = {
        draftSave: 'button', // 'button', 'saving', 'saved'
        draftSaveLoadingIndicator: false,
        draftSuccessfullySaved: false,
        draftSaveButton: true,
        selectAnotherFile: false,
    };
    tempAwsRequests = {};
    tempAwsRequest: any;
    tempFile: File;
    tempFileName: string;
    tempFileSrc: any;
    validations: any;
    viewAccessOptions: Array<GroupOption> = [
        {
            value: 'public',
            text: 'Anyone',
        },
        {
            value: 'verified',
            text: 'Verified',
        },
    ];
    checklistHasNoComponentsError = false;
    allComponentsValidError = false;
    isJustAdded = false;

    // Checklist
    id: number;
    status: string;
    title: string;
    summary: string;
    questions: Array<Question> = [];
    labels: Array<string> = [];
    share = {
        id: 0,
        type: 'checklist',
        notify: true,
        stub: '',
        privacy: this.viewAccessOptions[0].value, // View Access
    };
    clearSelectedQuestion = new Subject();
    clearSelectedDocument = new Subject();
    questionTypeIcons = questionTypeIcons;

    private lastChecklistJson: string;
    private urlProperties = [
        'audioUrl',
        'imageUrl',
        'videoUrl',
        'downloadUrl',
        'thumbnailUrl',
    ];
    private selectedComponentLastSavedJson: string;
    private urlPrefix = {
        http: 'http://',
        https: 'https://',
    };
    private processDialog: any;

    // Jobs
    private urlSyncRunning = false;
    private autoSaveEnabled = true;
    private isDetailsEditted: any;
    private currentPersistRequest: any;
    private saveTime = null;
    private filesProcessed = 0;
    private questionType: string;

    constructor(
        @Inject('location') private location: Location,
        public dialog: MatDialog,
        private appService: AppService,
        private appHelpersService: AppHelpersService,
        private appHttpService: AppHttpService,
        private appAlertService: AppAlertService,
        private appJobService: AppJobService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private appMessageService: AppMessageService,
        private appInfoService: AppInfoService,
        private questionsValidationService: QuestionsValidationService,
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private router: Router
    ) {}
    ngOnInit() {
        setTimeout(
            () => (this.appService.selected.navOption = 'create-checklist'),
            0
        );
        const id = this.route.snapshot.paramMap.get('id');
        this.filesProcessed = 0;

        this.appLoadingIndicatorService.show('app', '');
        this.appHttpService.request(
            'GET:api/checklists/' + id + '/',
            {},
            (response) => {
                // Check Published Checklist
                if (response.status === 'published') {
                    this.dialog.open(AppDialogAcknowledgeComponent, {
                        width: '425px',
                        disableClose: true,
                        data: {
                            text: 'You cannot edit a checklist after it has been published.',
                            label: 'Return',
                            callback: () => {
                                this.router.navigateByUrl('/checklists/' + id);
                            },
                        },
                    });
                } else {
                    const appInfo = this.appInfoService.getAppInfo();
                    this.validations = appInfo.validationInfo;

                    // Initialize local Checklist data.
                    this.initializeChecklist(response);

                    // Start component file url scan.
                    this.appJobService.create(
                        'edit-checklist-url-sync',
                        () => {
                            this.checkComponentUrls();
                        },
                        3000
                    );
                }

                this.appLoadingIndicatorService.hide(500);
            },
            () => {
                this.router.navigate(['/dashboard'], {
                    queryParams: { error: 'gettingChecklist' },
                });
            }
        );
    }

    ngOnDestroy() {
        this.appJobService.kill('edit-checklist-draft-save-job');
        this.appJobService.kill('edit-checklist-url-sync');
        this.appJobService.kill('edit-checklist-is-valid-job');

        this.clearAndCancelHttpForAllComponentFiles();
    }

    onBlurTextarea() {
        this.isJustAdded = false;
    }

    displayEditDetailsDialog(): void {
        const editDetailsDialog = this.dialog.open(DialogEditDetailsComponent, {
            width: '475px',
            height: '450px',
            disableClose: true,
            autoFocus: false,
            data: {
                title: this.title,
                summary: this.summary,
                type: this.share.type,
                privacy: this.share.privacy,
                validations: this.validations,
                viewAccessOptions: this.viewAccessOptions,
                callback: (data, dialog) => {
                    // Update details
                    ['title', 'summary'].forEach((key) => {
                        this[key] = data[key];
                    });
                    ['privacy'].forEach((key) => {
                        this.share[key] = data[key];
                    });

                    dialog.close();
                },
            },
        });

        editDetailsDialog.afterClosed().subscribe(() => {
            this.isDetailsEditted = true;
        });
    }

    selectComponent(id: number, shouldValidate = true, premium = false) {
        if (!premium) {
            const newQuestion = this.findQuestionById(id);
            const setNewComponent = () => {
                if (
                    !this.selectedQuestion ||
                    (this.selectedQuestion && id !== this.selectedQuestion.id)
                ) {
                    this.isJustAdded = false;
                }

                if (shouldValidate === true) {
                    this.validateAllComponents();
                }
                this.isAddingNewComponents = false;
                this.questionErrors = new QuestionErrors();
                this.clearSelectedQuestion.next();
                this.selectedQuestion = newQuestion;
                this.selectedQuestion.text = newQuestion.text;
                this.selectedQuestion.type = newQuestion.type;
                this.questionType = newQuestion.type;
                this.setComponentLastSavedJson();
                this.initComponentEditorClass(this.selectedQuestion.type);
            };
            setNewComponent();
        }
    }
    selectNewComponent(id: number, shouldValidate = true, premium = false) {
        if (!premium) {
            const newQuestion = this.findQuestionById(id);
            const setNewComponent = () => {
                this.isJustAdded = true;
                if (shouldValidate === true) {
                    this.validateAllComponents();
                }
                this.isAddingNewComponents = false;
                this.questionErrors = new QuestionErrors();
                this.clearSelectedQuestion.next();
                this.selectedQuestion = newQuestion;
                this.selectedQuestion.text = newQuestion.text;
                this.selectedQuestion.type = newQuestion.type;
                this.questionType = newQuestion.type;
                this.setComponentLastSavedJson();
                this.initComponentEditorClass(this.selectedQuestion.type);
            };
            setNewComponent();
        }
    }

    displayConfirmDeleteModal(id: number, locked: boolean) {
        // Prevent pass through event click of the parent component card.
        event.stopPropagation();

        if (!locked) {
            this.dialog.open(AppDialogConfirmComponent, {
                height: '165px',
                width: '425px',
                disableClose: true,
                data: {
                    text: 'Are you sure you want to delete this question?',
                    callback: () => {
                        this.deleteComponent(id);
                        this.appMessageService.show(
                            'app',
                            'success',
                            this.messages.success.componentDeleted,
                            3
                        );
                    },
                },
            });
        }
    }

    displayAddComponentView() {
        this.appLoadingIndicatorService.show('#panel-two');

        this.checklistHasNoComponentsError = false;
        this.allComponentsValidError = false;
        this.selectedQuestion = undefined;
        this.clearComponentLastSavedJson();
        this.isAddingNewComponents = true;

        this.appLoadingIndicatorService.hide();
    }

    addQuestion(questionType: QuestionType) {
        const addNewQuestion = () => {
            // Hide components error.
            this.checklistHasNoComponentsError = false;
            this.allComponentsValidError = false;

            // Temporarily disable auto-save and url syncing.
            this.autoSaveEnabled = false;
            this.urlSyncRunning = true;
            //
            const question = new Question(null, questionType, '');

            this.questionType = questionType;

            this.questions.push(question);
            this.selectNewComponent(question.id);
            this.isAddingNewComponents = false;

            this.saveChecklistDraft('add-component');
        };
        addNewQuestion();
    }

    saveChecklistDraft(type, newQuestionsCount?: number) {
        if (
            this.currentPersistRequest &&
            this.currentPersistRequest.closed === false
        ) {
            this.currentPersistRequest.unsubscribe();
        }

        // Reset saveTime
        this.saveTime = null;

        // Temporarily disable url syncing.
        this.urlSyncRunning = true;

        if (type === 'manual') {
            this.appLoadingIndicatorService.show('view', 'Saving...');
        } else if (type === 'auto') {
            this.show.draftSave = 'saving';
        }

        const checklistDraft = {
            id: this.id,
            status: this.status,
            title: this.title,
            summary: this.summary,
            questions: this.appHelpersService.getClone(this.questions),
            labels: this.labels,
            share: this.share,
        };

        // Prepare checklist draft by:
        //  1: Removing all temp ids from components.
        this.prepChecklistDraft(checklistDraft);

        // Special loading indicator.
        setTimeout(() => {
            this.currentPersistRequest = this.appHttpService.request(
                'PUT:api/checklists/' + this.id + '/',
                checklistDraft,
                (checklist) => {
                    this.updateQuestionsLive(checklist.questions);
                    this.updateLastChecklistJson();

                    if (type === 'manual') {
                        this.appLoadingIndicatorService.hide(750);
                        this.appMessageService.show(
                            'app',
                            'success',
                            this.messages.success.checklistSaved
                        );
                    } else if (type === 'auto') {
                        this.show.draftSave = 'saved';
                        setTimeout(() => {
                            this.show.draftSave = 'button';
                        }, 1750);
                    }

                    // Add new questions to the local questions data list.
                    if (newQuestionsCount) {
                        const lastIndex = this.questions.length - 1,
                            startNewIndex = lastIndex - newQuestionsCount + 1;

                        for (let i = startNewIndex; i <= lastIndex; i++) {
                            this.questions[i] = checklist.questions[i];
                        }

                        // Select the first of the newly added components.
                        const NewComponent = checklist.questions[startNewIndex];
                        this.selectComponent(
                            NewComponent.id,
                            false,
                            NewComponent.premium
                        );
                    }

                    // Init record audio view.
                    if (type === 'add-component') {
                        // Select the newly added component and clear the newly added component type.
                        const lastComponent =
                            checklist.questions[checklist.questions.length - 1];
                        this.selectComponent(
                            lastComponent.id,
                            false,
                            lastComponent.premium
                        );
                        this.isJustAdded = true;
                    }

                    // If there was an auto-save and there is dialog open, close it.
                    if (type === 'auto' && Boolean(this.processDialog)) {
                        this.closeAndClearProcessDialog();
                    }

                    // Re-enable auto-save and url syncing.
                    setTimeout(() => {
                        this.autoSaveEnabled = true;
                        this.urlSyncRunning = false;
                    }, 500);
                }
            );
        }, 10);
    }

    saveQuestionsChanges(event): void {
        this.autoSaveEnabled = false;
        this.urlSyncRunning = true;
        this.processDialog = event;
        this.updateComponent(() => {
            this.saveChecklistDraft('auto');
        });
        this.validateAllComponents();
    }

    getComponentClass(question: Question) {
        let componentClass = 'checklist-component';

        // Check to see if premium is true
        // if (component.premium) {
        //     componentClass += ' premium';
        // }

        // Check to see if description is set.
        if (question.text && question.text.length) {
            componentClass += ' has-text';
        }

        // Check to see if the component is currently selected.
        if (this.selectedQuestion && this.selectedQuestion.id === question.id) {
            componentClass += ' selected';
        }

        // Check component validation by index. Should only highlight if there is a present
        // error with the component and the component isn't just selected and just added.
        if (question.isValid === false) {
            if (
                this.selectedQuestion &&
                question.id === this.selectedQuestion.id &&
                this.isJustAdded
            ) {
                return componentClass;
            } else {
                return componentClass + ' invalid';
            }
        }

        return componentClass;
    }

    getEditDetailsClass(): string {
        if (
            (this.isDetailsEditted &&
                this.isChecklistTitleValid() &&
                this.isChecklistSummaryValid()) ||
            !this.isDetailsEditted
        ) {
            return 'light';
        } else {
            return 'red';
        }
    }

    drop(event: CdkDragDrop<Array<Question>, any>) {
        moveItemInArray(
            event.container.data,
            event.previousIndex,
            event.currentIndex
        );
        this.saveChecklistDraft('auto');
    }

    publish(): void {
        // Disable auto save and url updates before publishing
        this.autoSaveEnabled = false;
        this.urlSyncRunning = true;

        this.isJustAdded = false;
        this.checklistHasNoComponentsError = false;
        this.allComponentsValidError = false;

        if (this.isChecklistValid()) {
            this.appLoadingIndicatorService.show('app', 'Publishing...', () => {
                const checklist = {
                    id: this.id,
                    status: this.status,
                    title: this.title,
                    summary: this.summary,
                    questions: this.appHelpersService.getClone(this.questions),
                    labels: this.labels,
                    share: this.share,
                };

                // Build checklist object.
                this.prepChecklistDraft(checklist);
                checklist.status = 'published';

                this.appHttpService.request(
                    'PUT:api/checklists/' + this.id + '/',
                    checklist,
                    (response) => {
                        // TODO - keep for toggling while in dev to test consumption.
                        // this.router.navigateByUrl('/view/' + response.share.stub);

                        // Route user to Checklists area with checklist id in the URL so that the user can view their newly published checklist.
                        this.router.navigateByUrl('/checklists/' + response.id);
                    }
                );
            });
        } else {
            this.appMessageService.show(
                'app',
                'error',
                'Cannot publish. Please review error messages to continue.'
            );
        }
    }

    delete(): void {
        this.dialog.open(AppDialogConfirmComponent, {
            height: '165px',
            width: '425px',
            disableClose: true,
            data: {
                text: 'Are you sure you want to delete this?',
                callback: () => {
                    this.isJustAdded = false;
                    this.checklistHasNoComponentsError = false;
                    this.allComponentsValidError = false;

                    this.appLoadingIndicatorService.show(
                        'app',
                        'Deleting...',
                        () => {
                            this.appHttpService.request(
                                'DELETE:api/checklists/' + this.id + '/',
                                {},
                                (response) => {
                                    this.router.navigateByUrl('/checklists/');
                                }
                            );
                        }
                    );
                },
            },
        });
    }

    private selectedComponentHasChanges(): boolean {
        let hasChanges = false;

        if (this.selectedQuestion) {
            hasChanges =
                this.selectedComponentLastSavedJson !==
                this.buildQuestionsComponentLastSavedJson();
        }

        return hasChanges;
    }

    private updateComponent(callback?: Function) {
        if (this.selectedComponentHasChanges()) {
            this.questionErrors = new QuestionErrors();

            const index = this.questions
                    .map((component) => component.id)
                    .indexOf(this.selectedQuestion.id),
                processUpdateComponent = (fileKey?: string) => {
                    this.questions[index] = this.selectedQuestion;

                    if (fileKey && fileKey.length) {
                        // Clear URL properties for local components and selected component data.
                        this.urlProperties.forEach((url) => {
                            this.selectedQuestion[url] = '';
                        });
                    }
                };

            // Check to see if new file is present, if so go through the AWS file upload process.
            if (this.tempFile) {
                this.appLoadingIndicatorService.show('app', 'Uploading...');
                this.tempAwsRequest = this.appHttpService.apiAwsUploadFile(
                    this.tempFile,
                    (key) => {
                        this.cancelFile(); // Clear temp selected file data.

                        if (this.tempAwsRequest) {
                            delete this.tempAwsRequest;

                            processUpdateComponent(key);
                        }

                        this.appLoadingIndicatorService.hide();
                        this.setComponentLastSavedJson();
                        if (callback) {
                            callback();
                        }
                    },
                    () => {
                        this.appMessageService.show(
                            'modal',
                            'error',
                            AppMessage.get('awsUploadError'),
                            5
                        );
                    }
                );
            } else {
                processUpdateComponent();
                this.setComponentLastSavedJson();
                if (callback) {
                    callback();
                }
            }
        }
    }

    private cancelFile() {
        this.show.selectAnotherFile = false;
        this.tempFile = undefined;
        this.tempFileSrc = undefined;
        this.tempFileName = undefined;
    }

    private validateAllComponents() {
        this.getQuestionErrors();
        this.setIsValid();
    }

    private initComponentEditorClass(type: string): void {
        setTimeout(() => {
            $('.component-editor').attr('component-type', type);
        }, 250);
    }

    private clearAndCancelHttpForAllComponentFiles() {
        // Clear out component files list.
        this.componentFiles.length = 0;

        // Kill any requests for uploading.
        Object.keys(this.tempAwsRequests).forEach((id) => {
            this.tempAwsRequests[id].unsubscribe();
            delete this.tempAwsRequests[id];
        });

        // Default the aws request list.
        this.tempAwsRequests = {};
    }

    private isValidMultiSelectFile(file: File) {
        return file && this.appHelpersService.isValidComponentFile(file);
    }

    private checkComponentUrls() {
        // If the URL sync is already running, exit function.
        if (this.urlSyncRunning) {
            return;
        }

        // Check to see if any of the URLs are empty. If so, identify them
        // and do a get call to see if they've been updated. When updated
        // update the local data objects for them (both the components and
        // selected component data).
        const componentIds = [];

        // Scan local component data.
        this.questions.forEach((question) => {
            if (typeof question.id === 'number') {
                if (this.isQuestionProcessing(question)) {
                    if (componentIds.indexOf(question.id) < 0) {
                        componentIds.push(question.id);
                    }
                }
            }
        });

        // If component ids list contains any items, there are components that
        // do not have their final processed URLs yet. In turn, we'll kick off
        // an HTTP call to retrieve the latest components and update the URLs.
        if (componentIds.length) {
            // Disable auto-saving during this time.
            this.autoSaveEnabled = false;
            this.urlSyncRunning = true;

            this.appHttpService.request(
                'GET:api/checklists/' + this.id + '/',
                {},
                (checklist) => {
                    componentIds.forEach((id) => {
                        const updatedComponent = checklist.components.find(
                                (c) => c.id === id
                            ),
                            existingComponentIndex =
                                this.appHelpersService.findIndexByProperty(
                                    this.questions,
                                    'id',
                                    id
                                );

                        if (
                            updatedComponent.status !== 'transcoding' ||
                            updatedComponent.status !== 'converting'
                        ) {
                            // If the status has changed, update the local data
                            // in list and the selected component.
                            this.updateQuestionLive(
                                this.questions[existingComponentIndex],
                                updatedComponent
                            );
                            if (
                                updatedComponent.id === this.selectedQuestion.id
                            ) {
                                this.updateQuestionLive(
                                    this.selectedQuestion,
                                    updatedComponent
                                );
                            }
                        }
                    });

                    // Re-enable auto-saving now that URL sync work is done.
                    this.autoSaveEnabled = true;
                    this.urlSyncRunning = false;
                },
                () => {
                    // Re-enable auto-saving now that URL sync work is done.
                    console.error(
                        'ERROR :: problem performing GET:api/checklists/ for URL sync.'
                    );
                    this.autoSaveEnabled = true;
                    this.urlSyncRunning = false;
                }
            );
        }
    }

    private isQuestionProcessing(component: Question): boolean {
        const isProcessing = false;

        return isProcessing;
    }

    private updateChoiceLive(existingChoice, updatedChoice): void {
        // Update all properties of the selected question object.
        try {
            Object.keys(updatedChoice).forEach((key) => {
                if (['text', 'correct'].indexOf(key) < 0) {
                    existingChoice[key] = updatedChoice[key];
                }
            });
        } catch (error) {
            console.log('- Error syncing component -----');
            console.log(error);
        }
    }

    private updateQuestionLive(existingQuestion, updatedQuestion): void {
        // Update all properties of the selected question object.
        try {
            Object.keys(updatedQuestion).forEach((key) => {
                if (['text'].indexOf(key) < 0) {
                    if (key === 'choices') {
                        // Keep the order
                        for (
                            let i = 0;
                            i < updatedQuestion['choices'].length;
                            i++
                        ) {
                            this.updateChoiceLive(
                                existingQuestion['choices'][i],
                                updatedQuestion['choices'][i]
                            );

                        }
                    } else {
                        existingQuestion[key] = updatedQuestion[key];
                    }
                }
            });
        } catch (error) {
            console.log('- Error syncing component -----');
            console.log(error);
        }
    }

    private updateQuestionsLive(questions): void {
        // Update ids.
        const questionsLength = questions.length;
        for (let i = 0; i < questionsLength; i++) {
            if (this.questions[i].id === this.questions[i].clientId) {
                delete this.questions[i]['id'];
                this.questions[i]['id'] = questions[i].id;
            }
        }

        // Update all questions
        questions.forEach((updatedQuestion) => {
            const existingQuestion = this.questions.find(
                (q) => q.id === updatedQuestion.id
            );
            if (existingQuestion && updatedQuestion) {
                this.updateQuestionLive(existingQuestion, updatedQuestion);
            }
        });
    }

    private prepChecklistDraft(checklistDraft): void {
        const componentsLength = checklistDraft.questions.length;

        for (let i = 0; i < componentsLength; i++) {
            // 1.) Delete temp IDs.
            if (checklistDraft.questions[i].id === checklistDraft.questions[i].clientId) {
                delete checklistDraft.questions[i].id;
            }
        }
    }

    private isChecklistValid(): boolean {
        let isValid = true;

        // Reset all previous errors.
        this.checklistHasNoComponentsError = false;
        this.allComponentsValidError = false;

        // Validate title.
        if (!this.isChecklistTitleValid()) {
            isValid = false;
        }

        // Validate components.
        if (this.questions.length === 0) {
            isValid = false;
            this.checklistHasNoComponentsError = true;
        }
        // Check that all components are valid.
        const allComponentsValid = this.areAllComponentsValid();
        if (!allComponentsValid) {
            isValid = false;
            this.allComponentsValidError = true;
        }

        // If all components valid, hide components error message.
        if (allComponentsValid) {
            this.allComponentsValidError = false;
        }
        return isValid;
    }

    private areAllComponentsValid(): boolean {
        const componentsLength = this.questions.length;
        let areValid = true;

        for (let i = 0; i < componentsLength; i++) {
            if (this.questions[i].isValid === false) {
                areValid = false;
                break;
            }
        }

        return areValid;
    }

    private isChecklistTitleValid(): boolean {
        return (
            this.title &&
            this.title.length >= this.validations.checklist.title.min &&
            this.title.length <= this.validations.checklist.title.max
        );
    }

    private isChecklistSummaryValid(): boolean {
        return (
            this.summary &&
            this.summary.length >= this.validations.checklist.summary.min &&
            this.summary.length <= this.validations.checklist.summary.max
        );
    }

    private updateLastChecklistJson() {
        this.lastChecklistJson = this.getChecklistJson();
    }

    private checkAndSaveDraft() {
        if (this.lastChecklistJson !== this.getChecklistJson()) {
            // Save changes in 2 seconds from now
            this.saveTime = Date.now() + 2000;
            this.lastChecklistJson = this.getChecklistJson();
        }

        if (this.shouldSave() && this.autoSaveEnabled) {
            this.saveChecklistDraft('auto');
        }
    }

    private shouldSave() {
        if (this.saveTime && this.saveTime <= Date.now()) {
            return true;
        } else {
            return false;
        }
    }

    private getChecklistJson() {
        return JSON.stringify({
            id: this.id,
            status: this.status,
            title: this.title,
            summary: this.summary,
            questions: this.questions,
            labels: this.labels,
            share: this.share,
        });
    }

    private initializeChecklist(checklist) {
        // Init base properties.
        ['id', 'status', 'title', 'summary', 'questions', 'labels'].forEach(
            (key) => {
                this[key] = checklist[key];
            }
        );

        // Check and init the 'View Access' (share.privacy).
        this.share = checklist.share;

        // Initialize valid state of each component.
        this.getQuestionErrors();

        this.updateLastChecklistJson();

        // Open details dialog if checklist is new
        if (!this.title) {
            this.displayEditDetailsDialog();
        }
    }

    private clearComponentLastSavedJson(): void {
        this.selectedComponentLastSavedJson = '';
    }

    private setComponentLastSavedJson(): void {
        if (this.selectedQuestion) {
            this.selectedComponentLastSavedJson =
                this.buildQuestionsComponentLastSavedJson();
        }
    }

    private buildQuestionsComponentLastSavedJson(): string {
        const questionObject = {
            type: this.selectedQuestion.type,
            text: this.selectedQuestion.text,
            imageUrl: this.selectedQuestion.imageUrl,
            image: this.selectedQuestion.image,
            choices: this.selectedQuestion.choices,
            flag: this.selectedQuestion.flag,
            required: this.selectedQuestion.required,
        };

        return JSON.stringify(questionObject);
    }

    private getQuestionErrors(): QuestionErrors {
        const questionErrors =
            this.questionsValidationService.validateQuestions(this.questions);

        questionErrors.questionIndexes.map((errorIndex) => {
            this.questions[errorIndex].isValid = false;
        });

        if (questionErrors.hasErrors()) {
            this.questionErrors = questionErrors;
        }

        return questionErrors;
    }

    private findQuestionById(id: number) {
        return this.questions.find((question) => question.id === id);
    }

    private deleteComponent(id: number) {
        // Delete the component from the components list.
        const index = this.questions
            .map((component) => component.id)
            .indexOf(id);
        this.questions.splice(index, 1);

        // If selected, clear the selected component object.
        if (this.selectedQuestion && id === this.selectedQuestion.id) {
            this.displayAddComponentView();
        }
    }

    private closeAndClearProcessDialog(): void {
        setTimeout(() => {
            this.processDialog.close();

            setTimeout(() => {
                this.processDialog = undefined;
            }, 500);
        }, 1000);
    }

    getPrettyType(type: QuestionType) {
        if (type === 'true_false') {
            return 'True/False';
        } else if (type === 'yes_no') {
            return 'Yes/No';
        } else if (type === 'likert') {
            return 'Likert Scale';
        } else if (type === 'multiple_choice') {
            return 'Multiple Choice';
        } else if (type === 'multiple_response') {
            return 'Multiple Response';
        } else if (type === 'short_answer') {
            return 'Short Answer';
        }
    }

    private setIsValid() {
        const errors = this.getQuestionErrors();
        const questionsLength = this.questions.length;

        if (questionsLength) {
            for (let i = 0; i < questionsLength; i++) {
                if (
                    i in errors.answerIndexes &&
                    errors.answerIndexes[i].length
                ) {
                    // There is an error in this question's choices
                    this.questions[i].isValid = false;
                    const choicesLength = this.questions[i].choices.length;
                    for (let j = 0; j < choicesLength; j++) {
                        if (errors.answerIndexes[i].includes(j)) {
                            this.questions[i].choices[j].isValid = false;
                        }
                    }
                } else if (errors.questionIndexes.includes(i)) {
                    // There is an error with the question itself
                    this.questions[i].isValid = false;
                } else {
                    // There are no errors, reset the question and its choices to correct
                    this.questions[i].isValid = true;

                    if (
                        this.questions[i].choices &&
                        this.questions[i].choices.length
                    ) {
                        const choicesLength = this.questions[i].choices.length;
                        for (let j = 0; j < choicesLength; j++) {
                            this.questions[i].choices[j].isValid = true;
                        }
                    }
                }
            }
        }
    }
}
