<!--Buttons-->
<div class="contacts-buttons">
    <div class="create-contact-buttons" *ngIf="!selectedContact">
        <button
            title="Create"
            class="app-button small create create-contact-button"
            (click)="clickCreate('CREATE_CONTACT')"
        >
            Create
        </button>
        <button
            title="Upload"
            class="app-button small upload upload-contact-button"
            (click)="clickCreate('UPLOAD_CONTACTS')"
        >
            <img src="/assets/images/icons/upload.png" height="12" />
        </button>
    </div>
    <div class="contact-action-buttons">
        <ng-container *ngFor="let button of buttons">
            <button
                [title]="button.tooltip"
                [class]="getClass(button.name)"
                (click)="handleClick(button.name)"
                *ngIf="
                    !button.hidden &&
                    (!selectedContact || (selectedContact && button.selectedContact))
                "
            >
                <i class="material-icons icon">
                    {{ button.icon }}
                </i>
            </button>
        </ng-container>
    </div>
    <!--Selected info-->
    <div class="app-header-selected-info" *ngIf="!selectedContact">
        <!--Selected vings count-->
        <div class="selected-count">{{ getSelectedContactsCount() }} selected</div>

        <!--Select all vings-->
        <div class="select-all-items" *ngIf="contacts.length > 0">
            <a (click)="toggleSelectAllContacts()">
                <span *ngIf="!selectedAllContacts"> Select all </span>
                <span *ngIf="selectedAllContacts"> Deselect all </span>
            </a>
        </div>
    </div>
    <div class="fl-right toggle-req-button-container" *ngIf="selectedContact">
        <button
            [ngClass]="{
                'toggle-req-active': this.selectedPage === 'assignments'
            }"
            class="toggle-req-button"
            (click)="goToAssignments()"
        >
            Assignments
            <i
                *ngIf="sentimentAssignments"
                style="color: #fffefe"
                class="icon material-icons toggle-req-button-icon"
                >circle</i
            >
            <i
                *ngIf="sentimentAssignments"
                [ngStyle]="{ color: sentimentAssignments }"
                class="icon material-icons toggle-req-button-icon"
                >error</i
            >
        </button>
        <button
            [ngClass]="{
                'toggle-req-active': this.selectedPage === 'submissions'
            }"
            class="toggle-req-button"
            (click)="goToSubmissions()"
        >
            Submissions
            <i
                *ngIf="sentimentSubmissions"
                style="color: #fffefe"
                class="icon material-icons toggle-req-button-icon"
                >circle</i
            >
            <i
                *ngIf="sentimentSubmissions"
                [ngStyle]="{ color: sentimentSubmissions }"
                class="icon material-icons toggle-req-button-icon"
                >flag_circle</i
            >
        </button>
    </div>
</div>
