import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { AppSelectOption } from '../../../../shared/components/app-select/classes/app.select.option';
import { AppHeaderService } from '../../../../shared/services/app.header.service';

@Component({
    selector: 'app-contacts-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less'],
})
export class HeaderComponent implements OnInit, OnChanges {
    buttons = [
        {
            name: 'export-data',
            icon: 'file_open',
            tooltip: 'Export analytics',
            enabled: false,
            hidden: false,
            selectedContact: true,
        },
        {
            name: 'manage-contacts-labels',
            icon: 'local_offer',
            tooltip: 'Manage contact labels',
            enabled: false,
            hidden: false,
            selectedContact: true,
        },
        {
            name: 'change-owner',
            icon: 'account_box',
            tooltip: 'Change owner of selected contacts',
            enabled: false,
            hidden: false,
            selectedContact: true,
        },
        {
            name: 'archive',
            icon: 'archive',
            tooltip: 'Archive selected contacts',
            enabled: false,
            hidden: false,
            selectedContact: true,
        },
        {
            name: 'set-user',
            icon: 'verified_user',
            tooltip: 'Send login link',
            enabled: false,
            hidden: false,
            selectedContact: true,
        },
        {
            name: 'unarchive',
            icon: 'unarchive',
            tooltip: 'Unarchive selected contacts',
            enabled: false,
            hidden: true,
            selectedContact: true,
        },
        {
            name: 'save-custom-filters',
            icon: 'filter_list',
            tooltip: 'Save custom filters',
            enabled: false,
            hidden: false,
            selectedContact: false,
        },
        {
            name: 'merge-contacts',
            icon: 'call_merge',
            tooltip: 'Merge contacts',
            enabled: false,
            hidden: false,
            selectedContact: true,
        },
    ];

    @Input() contacts: Array<any>;
    @Input() selectedContactIds: Array<number>;
    @Input() excludedContactIds: Array<number>;
    @Input() selectedAllContacts: boolean;
    @Input() contactsCount: number;
    @Input() selectedLabels: Array<string>;
    @Input() selectedFilters: Array<string>;
    @Input() selectedContact: any;
    @Input() selectedStatus: AppSelectOption;
    @Input() isDetail: boolean;
    @Input() selectedPage: string;
    @Input() sentimentAssignments: string;
    @Input() sentimentSubmissions: string;

    @Output() onToggleAllContactIds = new EventEmitter();
    @Output() onClickHeaderButton = new EventEmitter<any>();
    @Output() displaySelectedContact = new EventEmitter<any>();
    @Output() selectPage = new EventEmitter<any>();

    private jobs = {
        headerButtonState: 'contacts-header-button-state',
    };

    page = '';

    constructor(private appHeaderService: AppHeaderService) {}

    ngOnInit() {
        this.appHeaderService.page.subscribe((page) => {
            this.page = page;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.updateButtonStates();
    }

    handleClick(name: string): void {
        if (this.getButton(name).enabled === true) {
            this.onClickHeaderButton.emit(name);
        }
    }

    getClass(name: string): string {
        const button = this.getButton(name),
            css = 'app-button dark x-small no-text ';

        this.updateButtonStates();

        return button.enabled ? css : css + ' disabled';
    }

    toggleSelectAllContacts(): void {
        this.onToggleAllContactIds.emit();
    }

    getSelectedContactsCount() {
        let count = 0;
        if (this.selectedAllContacts) {
            count = this.contactsCount - this.excludedContactIds.length;
        } else {
            count = this.selectedContactIds.length;
        }

        return count > 0 ? count : 0;
    }

    updateButtonStates(): void {
        let contactsAreSelected = false;
        let archivedContactsAreSelected = false;
        const labelsAreSelected = this.selectedLabels.length > 0;

        if (
            this.selectedAllContacts &&
            this.contactsCount > this.excludedContactIds.length
        ) {
            contactsAreSelected = true;
        } else {
            contactsAreSelected = this.selectedContactIds.length > 0;
        }

        if (this.selectedContact) {
            contactsAreSelected = true;
            if (this.selectedContact.archived === true) {
                archivedContactsAreSelected = true;
            }
        }

        if (this.selectedStatus && this.selectedStatus.value === 'archived') {
            archivedContactsAreSelected = true;
        }

        // Update header buttons based on selection state.
        this.getButton('export-data').enabled = contactsAreSelected;
        this.getButton('manage-contacts-labels').enabled = contactsAreSelected;
        this.getButton('change-owner').enabled = contactsAreSelected;
        this.getButton('archive').enabled = contactsAreSelected;
        this.getButton('archive').hidden = archivedContactsAreSelected;
        this.getButton('set-user').enabled = contactsAreSelected;
        this.getButton('unarchive').enabled =
            contactsAreSelected && archivedContactsAreSelected;
        this.getButton('unarchive').hidden = !archivedContactsAreSelected;
        this.getButton('merge-contacts').hidden = this.isDetail;
        this.getButton('export-data').hidden = this.isDetail;
        this.getButton('save-custom-filters').enabled =
            this.selectedLabels.length > 1;
        this.getButton('merge-contacts').enabled =
            contactsAreSelected && this.selectedContactIds.length >= 2;
    }

    private getButton(name: string) {
        return this.buttons.find((btn) => btn.name === name);
    }

    clickCreate(type) {
        this.appHeaderService.events.next(type);
    }

    goToAssignments() {
        this.selectedPage = 'assignments';
        this.selectPage.emit(this.selectedPage);
        this.displaySelectedContact.emit(this.selectedContact.id);
    }

    goToSubmissions() {
        this.selectedPage = 'submissions';
        this.selectPage.emit(this.selectedPage);
        this.displaySelectedContact.emit(this.selectedContact.id);
    }
}
