<div class="app-dialog dialog-edit-details">
    <form
        (ngSubmit)="handleDetails(detailForm)"
        #detailForm="ngForm"
        novalidate
    >
        <div class="header">Details</div>

        <div class="body">
            <!--Course Type-->
            <div class="form-group toggles">
                <div class="share-type">
                    <div class="app-field-label">Type</div>
                    <app-group-toggle
                        gap="true"
                        size="large"
                        [options]="shareTypeOptions"
                        [(value)]="type"
                    ></app-group-toggle>
                    <div class="help-text">
                        <div *ngIf="type=='packet'">Completion is tracked automatically when a viewer has completed all components.</div>
                        <div *ngIf="type=='requirement'">Completion is tracked when a certificate is uploaded, or an admin updates the status.</div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <!--Title-->
                <app-form-field
                    type="text"
                    name="title"
                    label="Title"
                    placeholder="Use between {{
                        validations.packet.title.min
                    }} and {{
                        validations.packet.title.max
                    }} characters to title your content."
                    [required]="true"
                    [validate]="true"
                    [minlength]="validations.packet.title.min"
                    [maxlength]="validations.packet.title.max"
                    [marginSize]="'margin-sm'"
                    [(ngModel)]="title"
                >
                </app-form-field>
            </div>
            <div class="form-group">
                <!--Summary-->
                <app-form-field
                    type="textarea"
                    name="summary"
                    label="Summary"
                    placeholder="Use between {{
                        validations.packet.summary.min
                    }} and {{
                        validations.packet.summary.max
                    }} characters to help your viewers know what to expect and why the content is important."
                    [required]="true"
                    [validate]="true"
                    [minlength]="validations.packet.summary.min"
                    [maxlength]="validations.packet.summary.max"
                    [marginSize]="'margin-sm'"
                    [(ngModel)]="summary"
                >
                </app-form-field>
            </div>
            <div class="form-group toggles">
                <!--View Access-->
                <div class="view-access">
                    <div class="app-field-label">View Access</div>
                    <app-group-toggle
                        gap="true"
                        size="large"
                        [options]="viewAccessOptions"
                        [(value)]="privacy"
                    ></app-group-toggle>
                </div>

                <!--Enforce Order-->
                <div class="enforce-order" *ngIf="type=='packet'">
                    <div class="app-field-label">Enforce Order</div>
                    <app-group-toggle
                        gap="true"
                        size="large"
                        [options]="enforceOrderOptions"
                        [(value)]="enforceOrder"
                    ></app-group-toggle>
                </div>
            </div>
        </div>

        <div class="footer">
            <button mat-dialog-close class="app-button light w-90 mr-1">
                Cancel
            </button>
            <button
                type="submit"
                class="app-button green w-90"
                [disabled]="!detailForm.form.valid"
            >
                Save
            </button>
        </div>
    </form>
</div>
