<div
    class="power-table"
    *ngIf="xTableData"
    [ngClass]="isReq ? 'req-power-table' : ''"
>
    <div [ngClass]="isReq ? 'req-power-table-top' : 'power-table-top'">
        <!--Search-->
        <div class="search-field">
            <!--Input-->
            <div class="app-input-text" *ngIf="page !== 'CONTACT-REQUIREMENTS'">
                <input
                    type="text"
                    maxlength="25"
                    placeholder="Search table..."
                    [(ngModel)]="filterText"
                    (keyup)="handleSearchChange($event)"
                    name="searchField"
                />
                <div class="app-clear-search-field-button">
                    <i class="material-icons icon" *ngIf="!filterText"
                        >search</i
                    >
                    <i
                        class="material-icons icon clear"
                        *ngIf="filterText"
                        (click)="handleClearSearchField()"
                        >clear</i
                    >
                </div>
            </div>
        </div>

        <!--Filter Buttons-->
        <div class="filter-buttons">
            <div
                *ngFor="let filter of xTableFilters"
                [class]="getFilterClass(filter)"
            >
                <div class="disabled"></div>

                <div *ngIf="filter.type === 'single'">
                    <div class="app-field-label" *ngIf="filter.name === 'sort'">
                        {{ filter.label }}
                    </div>
                    <app-select
                        [width]="filter.width"
                        [scrollHeight]="filter.scrollHeight"
                        [shouldIncludeDefaultValue]="
                            filter.shouldIncludeDefaultValue
                        "
                        [defaultLabel]="filter.label"
                        [options]="filter.options"
                        [selected]="getFilterSelectedValue(filter)"
                        [label]="filter.label"
                        [style]="filter.name === 'sort' ? 'light' : 'dark'"
                        [deselectable]="false"
                        (update)="handleFilterChange($event, filter.name)"
                    >
                    </app-select>
                </div>

                <div *ngIf="filter.type === 'multi'">
                    <app-select-multi
                        [width]="filter.width"
                        [scrollHeight]="filter.scrollHeight"
                        [label]="filter.label"
                        [options]="filter.options"
                        [selected]="getFilterSelectedValue(filter)"
                        (update)="handleFilterChange($event, filter.name)"
                    >
                    </app-select-multi>
                </div>
            </div>
        </div>
        <div class="unassign-button-wrapper" *ngIf="page !== 'CHECKLIST-SUBMISSIONS' && page !== 'CONTACT-SUBMISSIONS'">
            <button
                (click)="unassignFromPacket()"
                [style.display]="
                    isToggleAllSelected || selectedRows.length
                        ? 'block'
                        : 'none'
                "
                class="unassign-button app-button green small"
            >
                Unassign
            </button>
        </div>
        <div class="unassign-button-wrapper" *ngIf="page !== 'CHECKLIST-SUBMISSIONS' && page !== 'CONTACT-SUBMISSIONS'">
            <button
                (click)="setAsComplete()"
                [style.display]="
                    isToggleAllSelected || selectedRows.length
                        ? 'block'
                        : 'none'
                "
                class="unassign-button app-button green small"
            >
                Set As Complete
            </button>
        </div>
        <div class="unassign-button-wrapper" *ngIf="page === 'CHECKLIST-SUBMISSIONS' || page === 'CONTACT-SUBMISSIONS'">
            <button
                (click)="resolveSelectedFlag()"
                [style.display]="
                     (selectedFlagged.length || isToggleAllSelected)
                        ? 'block'
                        : 'none'
                "
                class="unassign-button app-button green small"
            >
                Resolve
            </button>
        </div>

        <!--Export Buttons-->
        <div
            class="export-button"
            *ngIf="xTableData.rows.length && page !== 'CONTACT-REQUIREMENTS'"
        >
            <a
                href="javascript:"
                title="Export the current table"
                (click)="handleDataExport()"
            >
                [Export]
            </a>
        </div>
    </div>

    <div class="power-table-header">
        <table>
            <thead>
                <tr>
                    <!--Checkbox-->
                    <th class="checkbox" *ngIf="canSelectRows">
                        <span
                            (click)="toggleAllRows()"
                            [class]="
                                isToggleAllSelected
                                    ? 'circle selected'
                                    : 'circle'
                            "
                        >
                            <i class="material-icons icon">check</i>
                        </span>
                    </th>
                    <!--Columns-->
                    <th
                        *ngFor="let header of xTableData.headers; let i = index"
                        [style.text-align]="header.align"
                        [style.width]="header.width"
                        [style.min-width]="header.width"
                        [style.max-width]="header.width"
                        [class]="header.css ? header.css : null"
                    >
                        <!--Text-->
                        <div class="text">
                            {{ header.text }}
                        </div>
                    </th>
                </tr>
            </thead>
        </table>
    </div>

    <div class="power-table-body">
        <div *ngIf="!xTableData.rows.length && noDataText" class="no-data">{{ noDataText }}</div>
        <div *ngIf="xTableData.rows.length" #scrollBarAreaRef class="scroll-bar-area" style="height: 100%">
            <table>
                <tbody>
                    <tr
                        *ngFor="let row of xTableData.rows"
                        [class]="isRowSelected(row.id) ? 'selected' : ''"
                    >
                        <!--Checkbox-->
                        <td
                            class="checkbox"
                            *ngIf="canSelectRows"
                            (click)="toggleSelectedRow($event, row, true)"
                        >
                            <span
                                [class]="
                                    isRowSelected(row.id)
                                        ? 'circle selected'
                                        : 'circle'
                                "
                            >
                                <i class="material-icons icon">check</i>
                            </span>
                        </td>
                        <!--Columns-->
                        <td
                            *ngFor="let column of row.cells; let i = index"
                            [style.width]="xTableData.headers[i].width"
                            [style.min-width]="xTableData.headers[i].width"
                            [style.max-width]="xTableData.headers[i].width"
                            [style.text-align]="xTableData.headers[i].align"
                            [class]="column.css ? column.css : null"
                        >
                            <!--                            TODO - refactor colors so we are sending as css instead of checks in template-->
                            <i
                                class="material-icons icon"
                                style="color: #F25F5C; font-size: 24px"
                                *ngIf="
                                    column.icon &&
                                    !column.errText &&
                                    column.name == 'flag'&&
                                    column.text == 'flagged'
                                "
                            >
                                {{ column.icon }}
                            </i>
                             <i
                                class="material-icons icon"
                                style="color: #6AB33F; font-size: 24px"
                                *ngIf="
                                    column.icon &&
                                    !column.errText &&
                                    column.name == 'flag'&&
                                    column.text == 'resolved'
                                "
                            >
                                {{ column.icon }}
                            </i>
                            <i
                                class="material-icons icon"
                                style="color: #9b9b9b; font-size: 24px"
                                *ngIf="
                                    column.icon &&
                                    !column.errText &&
                                    column.name == 'flag'&&
                                    column.text == 'unflagged'
                                "
                            >
                            </i>
                            <i
                                class="material-icons icon"
                                *ngIf="
                                    column.icon &&
                                    !column.errText &&
                                    column.name !== 'sentiment' &&
                                    column.name !== 'flag'
                                "
                            >
                                {{ column.icon }}
                            </i>
                            <i
                                class="material-icons icon error"
                                *ngIf="
                                    column.icon &&
                                    column.errText &&
                                    column.name !== 'sentiment' &&
                                    column.name !== 'flag'
                                "
                                title="{{ column.errText }}"
                            >
                                {{ column.icon }}
                            </i>
                            <i
                                class="material-icons icon"
                                style="color: {{ column.color }}; font-size: 24px"
                                *ngIf="
                                    column.name == 'sentiment' &&
                                    !column.errText
                                "
                            >
                                {{ column.icon }}
                            </i>
                            <i
                                class="material-icons icon error"
                                style="color: {{ column.color }}; font-size: 24px"
                                title="{{ column.errText }}"
                                *ngIf="
                                    column.name == 'sentiment' &&
                                    column.errText
                                "
                            >
                                {{ column.icon }}
                            </i>
                            <div
                                [class]="'text ' + column.name + ' title ellipsis-text'"
                                [style.width]="xTableData.headers[i].width"
                                [style.min-width]="xTableData.headers[i].width"
                                [style.max-width]="xTableData.headers[i].width"
                                *ngIf="column.name === 'requirement'"
                                [style.color]="
                                    column.color ? column.color : null
                                "
                                [style.cursor]="'pointer'"
                                (click)="
                                    handleItemClick($event, column.name, row)
                                "
                                [innerHTML]="column.text"
                            ></div>

                            <div
                                class="text"
                                *ngIf="
                                    column.name === 'completed' ||
                                    column.name === 'expires'
                                "
                                [innerHTML]="column.text"
                            ></div>
                            <!-- Invited -->
                            <div
                                [class]="'text ' + column.name"
                                *ngIf="
                                    column.name == 'lastInvited'
                                    || column.name == 'dateAssigned'
                                    || column.name == 'dateDue'
                                    || column.name == 'dateCompleted'
                                "
                                [innerHTML]="column.text"
                            ></div>

                            <!--Text-->

                            <div
                                [class]="'text ' + column.name + ' ellipsis-text'"
                                *ngIf="
                                    column.text &&
                                    column.name !== 'lastInvited' &&
                                    column.name !== 'dateAssigned' &&
                                    column.name !== 'dateDue' &&
                                    column.name !== 'dateCompleted' &&
                                    column.name !== 'requirement' &&
                                    column.name !== 'completed' &&
                                    column.name !== 'expires' &&
                                    column.name !== 'flag'
                                "
                                [style.color]="
                                    column.color ? column.color : null
                                "
                                (click)="
                                    handleItemClick($event, column.name, row)
                                "
                                [innerHTML]="column.text"
                            ></div>

                            <!--SubText-->
                            <div
                                class="sub-text"
                                *ngIf="column.subText"
                                [innerHTML]="column.subText"
                            ></div>

                            <!--PDF-->
                            <div class="pdf" *ngIf="column.name === 'pdf'">
                                <button
                                    class="app-button light x-small no-text"
                                    (click)="openDetails($event, row.reportId)"
                                >
                                    <i
                                        class="material-icons icon"
                                        *ngIf="
                                            page === 'PACKET-ASSIGNMENTS' ||
                                            page === 'CONTACT-ASSIGNMENTS' ||
                                            page === 'CHECKLIST-SUBMISSIONS' ||
                                            page === 'CONTACT-SUBMISSIONS'
                                        "
                                        >assessment</i
                                    >
                                </button>
                            </div>

                            <!--Progress Completed-->
                            <div
                                class="progress-complete"
                                *ngIf="column.progressComplete"
                            >
                                <i class="material-icons icon icon-check"
                                    >done</i
                                >
                            </div>

                            <!--ProgressBar-->
                            <div
                                class="progress"
                                *ngIf="isProgressSet(column.progress)"
                                [title]="column.progress + '%'"
                            >
                                <div class="text">
                                    {{ column.progress + '%' }}
                                </div>
                                <div class="bar">
                                    <div
                                        class="fill"
                                        [style.width]="column.progress + '%'"
                                    ></div>
                                </div>
                            </div>

                            <!--Answers-->
                            <div class="answers" *ngIf="column.answers">
                                <div
                                    class="answer"
                                    *ngFor="let answer of column.answers"
                                >
                                    <span *ngIf="answer.text">{{
                                        answer.text
                                    }}</span>
                                    <i
                                        class="material-icons icon"
                                        *ngIf="answer.imageUrl"
                                        (click)="
                                            viewAnswerImage(answer.imageUrl)
                                        "
                                        >image</i
                                    >
                                </div>
                            </div>
                            <div
                                *ngIf="column.name === 'certificateDownload'"
                                [style.cursor]="'pointer'"
                            >
                                <i
                                    class="material-icons icon"
                                    style="font-size: 20px;"
                                    (click)="openFileInNewTab(row.certificateUrl)"
                                    >workspace_premium</i
                                >
                            </div>
                            <div
                                *ngIf="column.name === 'certificateUpload'"
                                [style.cursor]="'pointer'"
                            >
                                <i
                                    class="material-icons icon"
                                    style="font-size: 20px;"
                                    (click)="uploadCertificate(row.assignmentId)"
                                    >upload</i
                                >
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="power-table-footer">
        <div
            class="footer-paging"
            *ngIf="numberOfPages > 1"
            [style.width]="getFooterPagingWidth()"
        >
            <!--Button Left-->
            <div class="footer-paging-button left">
                <button
                    (click)="handlePagesButtonClick('previous')"
                    [disabled]="currentPageIndex < 1"
                    class="app-button small no-text blue"
                >
                    <i class="material-icons icon">keyboard_arrow_left</i>
                </button>
            </div>

            <!--Pages Drop-Down-->
            <div class="footer-paging-details">
                <div class="number-of-pages">
                    {{ currentPageIndex + 1 }}&nbsp;&nbsp;/&nbsp;&nbsp;{{
                        numberOfPages
                    }}
                </div>
            </div>

            <!--Button Right-->
            <div class="footer-paging-button right">
                <button
                    (click)="handlePagesButtonClick('next')"
                    [disabled]="currentPageIndex >= numberOfPages - 1"
                    class="app-button small no-text blue"
                >
                    <i class="material-icons icon">keyboard_arrow_right</i>
                </button>
            </div>
        </div>
        <!--Number of users-->
        <div class="footer-key-value">
            {{ getTableFooterText() }}
            <a *ngIf=infoLink target="_blank" href="{{ infoLink }}"><i class="material-icons icon">info_outline</i></a>
        </div>

        <!--Paging-->
    </div>
</div>
