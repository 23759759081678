<div class="app-dialog-complete">
    <div class="header">Set As Complete</div>
    <div class="body">
        <div class="content-list" *ngIf="selectedItems.length">
            <div>{{ confirmationText }}</div>
        </div>
        <div class="content-list no-items" *ngIf="!selectedItems.length">
            <div class="no-items-text">No items selected</div>
        </div>
    </div>
    <div class="footer">
        <button mat-dialog-close class="app-button light w-90 mr-1">
            Cancel
        </button>
        <button (click)="complete()" class="app-button green w-90 mr-1">
            Complete
        </button>
    </div>
</div>
