<div class="app-dialog dialog-upload-certificate">
    <div class="header">Upload Certificate</div>
    <div class="body">
        <div class="container">
            <div class="cert-file-drag-drop">
                <ngx-file-drop
                    class="file-drag-drop {{
                        certificateFileDropped ? 'file-over' : ''
                    }}"
                    dropZoneLabel="Drop files here"
                    dropZoneClassName="file-dropper-area"
                    (onFileDrop)="certificateFileDropped($event)"
                >
                    <ng-template ngx-file-drop-content-tmp>
                        <div class="d-flex jc-sb">
                            <div class="flex-column tac mt-1 mr-1">
                                <div class="text">
                                    <b>Drop File Here</b>
                                </div>
                                <div class="text mt-1">or</div>
                                <div class="flex-column browse mt-1">
                                    <button
                                        type="button"
                                        class="app-button green"
                                        (click)="openFileDialog()"
                                    >
                                        Browse
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ngx-file-drop>
            </div>
        </div>
    </div>
    <div class="footer">
        <button mat-dialog-close class="app-button light w-90 mr-1">
            Cancel
        </button>
    </div>
</div>
