<div id="ving-creation">
    <!--Errors-->
    <!--Components-->
    <div class="components-error" *ngIf="vingHasNoComponentsError">
        {{ messages.error.ving.componentsNone }}
        &nbsp;
        <i
            class="material-icons icon"
            (click)="vingHasNoComponentsError = false"
            >highlight_off</i
        >
    </div>
    <!--Components-->
    <div class="components-error" *ngIf="allComponentsValidError">
        {{ messages.error.ving.componentsHasInvalid }}
        &nbsp;
        <i class="material-icons icon" (click)="allComponentsValidError = false"
            >highlight_off</i
        >
    </div>

    <!--Header (green bar)-->
    <div id="header">
        <div class="left">
            <div class="page-title">
                <div class="title-text">Create</div>
            </div>
        </div>

        <div class="right">
            <div class="inner">
                <!--Title-->
                <div class="title">
                    <span title="{{ title }}">{{ title }}</span>
                    <button
                        class="app-button {{ getEditDetailsClass() }} w-90"
                        (click)="displayEditDetailsDialog()"
                    >
                        <i class="material-icons icon">edit</i> Edit Details
                    </button>
                </div>
                <!--Publish Ving-->
                <div class="save-and-status">
                    <!--Status Updates-->
                    <div
                        class="status-updates"
                        *ngIf="
                            show.draftSave === 'saving' ||
                            show.draftSave === 'saved'
                        "
                    >
                        <span
                            class="ving-draft-saving"
                            [style.display]="
                                show.draftSave === 'saving' ? 'block' : 'none'
                            "
                        >
                            <img
                                src="/assets/images/loading-indicator-white.gif"
                            />
                            Draft saving
                        </span>
                        <span
                            class="ving-draft-saved"
                            [style.display]="
                                show.draftSave === 'saved' ? 'block' : 'none'
                            "
                        >
                            Draft saved
                        </span>
                    </div>
                    <!--Actions-->
                    <div class="save-draft-button">
                        <button
                            class="app-button red no-text"
                            (click)="delete()"
                        >
                            <i class="material-icons icon">delete</i>
                        </button>
                        <button class="app-button blue" (click)="publish()">
                            Publish
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Panels-->
    <div id="panels">
        <!--Panel One-->
        <div id="panel-one">
            <!--No components text-->
            <div class="app-message-text" *ngIf="!components.length">
                Start adding components
            </div>

            <!--Components-->
            <div
                class="components"
                cdkDropList
                [cdkDropListData]="components"
                (cdkDropListDropped)="drop($event)"
                #autoscroll
            >
                <ng-container *ngFor="let component of components">
                    <div
                        *ngIf="component"
                        cdkDrag
                        [cdkDragData]="component"
                        [cdkDragDisabled]="component.lockPosition"
                        [class]="getComponentClass(component)"
                        (click)="
                            selectComponent(
                                component.id,
                                true,
                                component.premium
                            )
                        "
                        title="{{
                            component.premium
                                ? 'Premium Components can`t be edited'
                                : ''
                        }}"
                    >
                        <!--Selected indicator-->
                        <div class="selected-indicator"></div>

                        <!--Transcoding indicator-->
                        <div
                            *ngIf="
                                component.status === 'transcoding' ||
                                component.status === 'converting'
                            "
                            class="horizontal-loading-indicator"
                        ></div>

                        <!--Type-Icon :: Top-Left-->
                        <div class="type-icon">
                            <i class="material-icons icon">
                                {{ icons[component.type] }}
                            </i>
                        </div>

                        <!--Re-order :: grabber-->
                        <div
                            class="grabber grabable"
                            (click)="$event.stopPropagation()"
                            *ngIf="!component.lockPosition"
                        >
                            <i class="material-icons icon grabable"
                                >unfold_more</i
                            >
                        </div>

                        <!--Controls :: Top-Right-->
                        <div
                            class="controls"
                            [ngClass]="{
                                'controls-locked': !component.lockPosition
                            }"
                        >
                            <span
                                class="control-button delete-button"
                                title="Delete"
                                (click)="
                                    displayConfirmDeleteModal(
                                        component.id,
                                        component.lockPosition
                                    )
                                "
                                *ngIf="!component.lockPosition && component.deletable"
                            >
                                <i class="material-icons icon">delete</i>
                            </span>
                            <span
                                class="control-button"
                                *ngIf="component.premium"
                            >
                                <i class="material-icons icon">stars</i>
                            </span>
                        </div>

                        <!--Title-->
                        <div
                            class="title {{
                                component.title ? '' : 'no-title-set'
                            }}"
                            [title]="
                                component.premium
                                    ? 'Premium Components can`t be edited or moved'
                                    : component.title
                                    ? component.title
                                    : ''
                            "
                        >
                            {{
                                component.title
                                    ? component.title
                                    : 'No title set'
                            }}
                        </div>

                        <!--Text-->
                        <div
                            *ngIf="component.text.length"
                            class="text"
                            ellipsis
                        >
                            {{ component.text }}
                        </div>
                    </div>
                </ng-container>
            </div>

            <!--Add Component Buttons-->
            <div class="add-buttons">
                <!--Add Component Button-->
                <button
                    class="app-button green standard"
                    (click)="displayAddComponentView()"
                    title="Add components"
                >
                    <i class="material-icons icon">add</i> Add Component
                </button>
            </div>
        </div>

        <!--Panel Three-->
        <div id="panel-two">
            <!--Add Component View-->
            <div class="add-component-view" *ngIf="isAddingNewComponents">
                <!--Title-->
                <div class="title">
                    Select one of the following options or drag a file into the
                    space below.
                </div>
                <div class="add-component-view-content">
                    <!--Drag-drop file space-->
                    <ngx-file-drop
                        class="file-drag-drop {{
                            componentFilesDropped ? 'file-over' : ''
                        }}"
                        dropZoneLabel="Drop files here"
                        dropZoneClassName="file-drag-drop-area h-100"
                        (onFileDrop)="componentFilesDropped($event)"
                        (onFileOver)="componentsIsFileOver = true"
                        (onFileLeave)="componentsIsFileOver = false"
                    >
                        <ng-template
                            ngx-file-drop-content-tmp
                            let-openFileSelector="openFileSelector"
                        >
                            <div class="file-drag-drop-text">
                                <div class="text">Drag-and-drop files here</div>
                                <div class="sub">
                                    Drag-and-drop your files of the following type
                                    into this region:
                                </div>
                                <div class="types">
                                    <div
                                        class="type"
                                        *ngFor="
                                            let type of addComponentDragDropTypes
                                        "
                                    >
                                        <i class="material-icons icon">{{
                                            icons[type.type]
                                        }}</i
                                        ><br /><span class="text">{{
                                            type.text
                                        }}</span>
                                    </div>
                                </div>
                                <div class="or">
                                    <fieldset>
                                        <legend>Or</legend>
                                    </fieldset>
                                </div>
                                <div class="sub">
                                    Browse and select one or more files:
                                </div>
                                <div class="browse-and-select-multiple-files">
                                    <input
                                        type="file"
                                        #selectFileField
                                        (change)="selectMultipleFiles($event)"
                                        multiple
                                    />
                                </div>
                            </div>
                        </ng-template>
                    </ngx-file-drop>
                    <div class="component-buttons">
                     <app-component-button *ngFor="let button of addComponentButtons"
                    [componentTitle]="button.text"
                    [componentType]="button.type"
                    [componentTypeImage]="button.componentTypeImage"
                    (click)="addComponent(button.type, button.isSupported)"
                    title="{{
                                !button.isSupported
                                    ? 'This feature is not supported in your current browser'
                                    : ''
                            }}">
                    </app-component-button>
                    </div>
                </div>
            </div>

            <!--Component Editor-->
            <div class="component-editor" *ngIf="selectedComponent">
                <!--Component :: Properties-->
                <div class="header">
                    <!--Component Title & Summary-->
                    <div class="title">
                        <div class="input">
                            <label>Component Title</label>
                            <app-form-field
                                type="text"
                                name="title"
                                placeholder="Enter component title..."
                                [minlength]="validations.component.title.min"
                                [maxlength]="validations.component.title.max"
                                [hasError]="!isComponentTitleValid()"
                                [(ngModel)]="selectedComponent.title"
                            >
                            </app-form-field>
                        </div>
                        <div class="actions">
                            <button
                                class="app-button dark no-text"
                                title="Edit Component Summary"
                                *ngIf="selectedComponent.type !== 'text'"
                                (click)="displayEditTextDialog('component')"
                            >
                                <i class="material-icons icon">subject</i>
                            </button>
                        </div>
                    </div>
                </div>

                <!--Component :: Content-->
                <div class="content">
                    <!--Image-->
                    <div
                        class="content-image"
                        *ngIf="selectedComponent.type === 'image'"
                    >
                        <div
                            class="content-image-display"
                            *ngIf="!tempFileSrc && selectedComponent.imageUrl"
                        >
                            <img [src]="selectedComponent.imageUrl" />
                        </div>
                        <div class="content-image-display" *ngIf="tempFileSrc">
                            <img [src]="tempFileSrc" />
                        </div>

                        <app-content-pending
                            [type]="selectedComponent.type"
                            *ngIf="shouldDisplayAppContentPending()"
                        >
                        </app-content-pending>
                    </div>

                    <!--Video-->
                    <div
                        class="content-video"
                        *ngIf="selectedComponent.type === 'video'"
                    >
                        <div class="content-video-display">
                            <video
                                controls
                                *ngIf="
                                    !tempFileSrc &&
                                    shouldDisplayPreview('video') &&
                                    !shouldDisplayAppContentPending()
                                "
                                [src]="selectedComponent.videoUrl"
                                [poster]="selectedComponent.imageUrl"
                            >
                                This web browser does not support HTML5 video.
                            </video>
                            <video
                                controls
                                *ngIf="
                                    tempFileSrc &&
                                    shouldDisplayPreview('video') &&
                                    !shouldDisplayAppContentPending()
                                "
                                [src]="tempFileSrc"
                                poster="../../../../../assets/images/video-placeholder.png"
                            ></video>

                            <!--New video file selected-->
                            <div
                                class="media-cannot-be-played"
                                *ngIf="
                                    tempFileSrc &&
                                    !shouldDisplayPreview('video') &&
                                    !shouldDisplayAppContentPending()
                                "
                            >
                                <div class="title">
                                    Video preview unavailable.
                                </div>
                                <div class="sub">
                                    Selected video file must be processed before
                                    it can be played.
                                </div>
                            </div>

                            <app-content-pending
                                [type]="selectedComponent.type"
                                *ngIf="shouldDisplayAppContentPending()"
                            >
                            </app-content-pending>
                        </div>
                    </div>

                    <!--Audio-->
                    <div
                        class="content-audio"
                        *ngIf="selectedComponent.type === 'audio'"
                    >
                        <div class="inner">
                            <!--Audio File Error-->
                            <div
                                class="error-message"
                                *ngIf="
                                    !isJustAdded && !selectedComponent.audioUrl
                                "
                            >
                                Audio file not selected.
                            </div>

                            <!--Audio Mode :: File-->
                            <div
                                class="audio-tab-container file"
                                *ngIf="getAudioMode() === 'file'"
                            >
                                <div class="content-audio-display">
                                    <ng-container *ngIf="!tempFileData">
                                        <audio
                                            controls
                                            *ngIf="
                                                !tempFileSrc &&
                                                shouldDisplayPreview('audio') &&
                                                !shouldDisplayAudioPreview()
                                            "
                                            [src]="selectedComponent.audioUrl"
                                        ></audio>

                                        <!--New audio file selected-->
                                        <div
                                            class="media-cannot-be-played"
                                            *ngIf="
                                                tempFileSrc &&
                                                !shouldDisplayAudioPreview()
                                            "
                                        >
                                            <div class="title">
                                                Audio preview unavailable.
                                            </div>
                                            <div class="sub">
                                                Selected audio file must be
                                                processed before it can be
                                                played.
                                            </div>
                                        </div>

                                        <audio
                                            controls
                                            *ngIf="
                                                shouldDisplayAudioPreview() &&
                                                !shouldDisplayAppContentPending()
                                            "
                                            [src]="tempFileSrc"
                                        ></audio>

                                        <app-content-pending
                                            [type]="selectedComponent.type"
                                            *ngIf="shouldDisplayAudioPending()"
                                        >
                                        </app-content-pending>
                                    </ng-container>

                                    <ng-container *ngIf="tempFileData">
                                        <!--Audio Control-->
                                        <audio
                                            controls
                                            autoplay
                                            [src]="tempFileData"
                                        ></audio>
                                        <!--Loader-->
                                        <div class="loader"></div>
                                    </ng-container>
                                </div>

                                <button
                                    class="app-button light small no-text"
                                    title="Remove"
                                    (click)="deleteAudio()"
                                    *ngIf="
                                        getAudioMode() === 'file' &&
                                        tempFileData
                                    "
                                >
                                    <i class="material-icons icon">delete</i>
                                </button>
                            </div>

                            <!--Audio Mode :: Recording-->
                            <div
                                class="audio-tab-container recording"
                                *ngIf="
                                    getAudioMode() === 'recording' &&
                                    !shouldDisplayAppContentPending()
                                "
                            >
                                <app-record-audio
                                    (saveRecording)="saveRecording($event)"
                                ></app-record-audio>
                            </div>
                        </div>
                    </div>

                    <!--Document-->
                    <div
                        class="content-document"
                        *ngIf="selectedComponent.type === 'document'"
                    >
                        <app-document
                            mode="edit"
                            [document]="selectedComponent.document"
                            [clearSelectedDocument]="
                                clearSelectedDocument.asObservable()
                            "
                            (newDocumentFileKey)="onNewFileKey($event)"
                        >
                        </app-document>
                    </div>

                    <!--Certificate-->
                    <div
                        class="content-text"
                        *ngIf="selectedComponent.type === 'certificate'"
                    >
                        <div class="help-text">
                            This component type allows a certificate to be uploaded by the viewer, and completion of the external requirement to be tracked automatically. The duration entered below should correspond with the expected time to complete the portion of this requirement outside of the application.
                        </div>
                        <div class="content-duration">
                            Expected time to complete (in minutes):
                            <input
                                class="duration-minutes"
                                [ngClass]="{'error':!selectedComponent.duration}"
                                type="number"
                                name="duration"
                                value="{{ selectedComponent.duration }}"
                                min="1"
                                oninput="validity.valid || (value = ''); if (this.value.length > 4) this.value = this.value.slice(0, 4);"
                                [(ngModel)]="selectedComponent.duration"
                            />
                        </div>
                    </div>

                    <!--Text-->
                    <div
                        class="content-text"
                        *ngIf="selectedComponent.type === 'text'"
                    >
                        <div class="content-text-inner">
                            <textarea
                                [minlength]="validations.component.text.min"
                                [maxlength]="validations.component.text.max"
                                placeholder="Enter text here..."
                                [(ngModel)]="selectedComponent.text"
                                (blur)="onBlurTextarea()"
                            >
                            </textarea>
                        </div>
                        <div
                            class="text-error"
                            *ngIf="shouldDisplayTextError()"
                        >
                            The field above must contain between
                            {{ validations.component.text.min }} -
                            {{ validations.component.text.max }} characters.
                        </div>
                        <div
                            class="text-limit"
                            *ngIf="shouldDisplayTextLimit()"
                        >
                            Text has reached max length:
                            {{ validations.component.text.max }} characters.
                        </div>
                    </div>

                    <!--Other (File)-->
                    <div
                        class="content-other"
                        *ngIf="selectedComponent.type === 'other'"
                    >
                        <a
                            [href]="selectedComponent.downloadUrl"
                            download
                            target="_blank"
                        >
                            <button type="button" class="app-button green">
                                <i class="material-icons icon">file_download</i>
                                Download File
                            </button>
                        </a>
                    </div>

                    <!--Link-->
                    <div
                        class="content-link"
                        *ngIf="selectedComponent.type === 'link'"
                    >
                        <!--URL-->
                        <div class="content-link-url">
                            <input
                                type="text"
                                class="app-field-text"
                                placeholder="Type or paste URL here..."
                                (change)="validateAndSaveUrl()"
                                [(ngModel)]="displayedLinkUrl"
                            />
                        </div>

                        <!--Error-->
                        <div
                            class="content-link-error"
                            *ngIf="shouldDisplayUrlErrorMessage()"
                        >
                            Please specify a valid URL.
                        </div>

                        <!--Error-->
                        <div
                            class="content-link-preview-button"
                            *ngIf="!shouldDisplayUrlErrorMessage()"
                        >
                            <button
                                class="app-button small green"
                                (click)="displayLinkUrlInNewTab()"
                            >
                                <i class="material-icons icon">link</i> View
                                Website Address
                            </button>
                        </div>
                    </div>

                    <!--Questions-->
                    <div
                        class="content-questions"
                        *ngIf="selectedComponent.type === 'questions'"
                    >
                        <app-questions
                            [(questions)]="selectedComponent.questions"
                            (saveQuestionsChanges)="
                                saveQuestionsChanges($event)
                            "
                            [questionErrors]="questionErrors"
                            [isJustAdded]="isJustAdded"
                            [allValidations]="validations"
                            [clearSelectedQuestion]="
                                clearSelectedQuestion.asObservable()
                            "
                            (blurQuestion)="onBlurTextarea()"
                        >
                        </app-questions>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
