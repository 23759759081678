import {
    Component,
    Inject,
    OnInit,
    AfterViewInit,
    HostListener,
    Renderer2,
} from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';

import { environment } from '../environments/environment';
import { AppLoadingIndicatorService } from './shared/services/app.loading-indicator.service';
import { AppUserService } from './shared/services/app.user.service';
import { AppHttpService } from './shared/services/app.http.service';
import { AppPermissionService } from './shared/services/app.permission.service';
import { AppService } from './app.service';
import { AppDialogBrowserCompatibilityComponent } from './shared/dialogs/dialog-browser-compatibility/app.dialog-browser-compatibility.component';
import { AppDialogResetPasswordComponent } from './modules/accounts/dialogs/dialog-reset-password/app.dialog-reset-password.component';
import {
    DialogMergeUsersComponent
} from "./modules/accounts/dialogs/dialog-merge-users/dialog-merge-users.component";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit, AfterViewInit {
    public navOptions = [
        { title: 'Dashboard', name: 'dashboard', icon: 'home' },
        { title: 'Contacts', name: 'contacts', icon: 'people' },
        { title: 'Vings', name: 'vings', icon: 'perm_media' },
        { title: 'Checklists', name: 'checklists', icon: 'checklist' },
    ];
    isMenuOpen = false;
    availableHeader = true;
    isMobile = null;
    version: string = environment.version;
    private prevSlug: string;

    constructor(
        private appService: AppService,
        private appHttpService: AppHttpService,
        private appUserService: AppUserService,
        private appPermissionService: AppPermissionService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private router: Router,
        private dialog: MatDialog,
        private renderer: Renderer2,
        private deviceService: DeviceDetectorService,
        @Inject('storage') private storage: Storage
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (event.url.substr(0, 6) === '/login' || event.url === '/') {
                    this.availableHeader = false;
                } else {
                    this.availableHeader = true;
                }

                // Add page slug to document
                if (this.prevSlug) {
                    this.renderer.removeAttribute(
                        document.documentElement,
                        'view'
                    );
                }
                const slugArr = event.url.split('/');
                if (slugArr.length > 1) {
                    const curSlug = slugArr[1];
                    this.renderer.setAttribute(
                        document.documentElement,
                        'view',
                        curSlug
                    );
                    this.prevSlug = curSlug;
                }
            }
        });

        this.isMobile = this.deviceService.isMobile();
        this.renderer.setAttribute(
            document.documentElement,
            'device',
            this.isMobile ? 'mobile' : 'desktop'
        );
    }

    ngOnInit() {
        this.appUserService.whenUserSet(() => {
            const user = this.appUserService.getUser();
            const passwordResetRequired =
                this.appUserService.isPasswordResetRequired();

            if (passwordResetRequired) {
                this.dialog.open(AppDialogResetPasswordComponent, {
                    height: '500px',
                    width: '425px',
                    disableClose: true,
                    data: {
                        title: 'Reset Password',
                    },
                });
            }
        });

        if (!this.appService.selected.navOption) {
            setTimeout(
                () =>
                    (this.appService.selected.navOption =
                        this.getNavOptionFromUrl()),
                0
            );
        }

        this.appLoadingIndicatorService.show('app');

        // Hide the header for Authentication pages
        if (location.pathname.substr(0, 6) === '/login') {
            this.availableHeader = false;
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            const supportsLocalStorage = typeof Storage !== 'undefined';
            let availableLocalStorage = true;
            try {
                localStorage.setItem('app', 'Ving');
                availableLocalStorage = true;
            } catch (e) {
                availableLocalStorage = false;
            }
            if (!supportsLocalStorage || !availableLocalStorage) {
                this.dialog.open(AppDialogBrowserCompatibilityComponent, {
                    width: '550px',
                    disableClose: true,
                });
            }
        }, 0);
    }

    @HostListener('document:scroll', []) onWindowScroll() {
        const scrollTop =
            window.scrollY ||
            window.pageYOffset ||
            document.body.scrollTop +
                ((document.documentElement &&
                    document.documentElement.scrollTop) ||
                    0);
        if (scrollTop >= 65) {
            this.renderer.addClass(document.body, 'scrolled');
        } else {
            this.renderer.removeClass(document.body, 'scrolled');
        }
    }

    canAccessUrl(url) {
        return this.appPermissionService.canAccess(url);
    }

    setSelectedOption(option) {
        this.appService.selected.navOption = option;
    }

    getOptionClass(optionName) {
        return optionName === this.appService.selected.navOption
            ? 'option selected'
            : 'option';
    }

    isUserLoggedIn(): boolean {
        return this.appUserService.isLoggedIn();
    }

    getUserDisplayName(): string {
        return this.appUserService.isLoggedIn()
            ? this.appUserService.getDisplayName()
            : '';
    }

    getUserAvatar(): string {
        return this.appUserService.isLoggedIn()
            ? this.appUserService.getAvatar()
            : '';
    }

    handleAccountBlur(): void {
        if (this.isMenuOpen === true) {
            this.isMenuOpen = false;
        }
    }

    handleMenuOptionClick(button: string): void {
        switch (button) {
            case 'profile':
                this.router.navigateByUrl('/user/profile');
                break;
            case 'assignments':
                this.router.navigateByUrl('/user/assignments');
                break;
            case 'checklists':
                this.router.navigateByUrl('/user/checklists');
                break;
            case 'logout':
                this.router.navigateByUrl('/logout');
                break;
        }
    }

    private getNavOptionFromUrl() {
        return location.pathname.split('/')[1];
    }

    redirectToDashboard() {
        this.router.navigateByUrl('/dashboard');
    }
}
