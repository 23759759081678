import { Component, Inject, OnInit } from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialog,
} from '@angular/material/dialog';
import { AppAlertService } from '../../../../shared/services/app.alert.service';
import { AppHttpService } from '../../../../shared/services/app.http.service';
import { AppMessageService } from '../../../../shared/services/app.message.service';
import { AppHelpersService } from '../../../../shared/services/app.helpers.service';
import { AppLoadingIndicatorService } from '../../../../shared/services/app.loading-indicator.service';

@Component({
    templateUrl: './app.dialog-complete.component.html',
    styleUrls: ['./app.dialog-complete.component.less'],
})
export class AppDialogCompleteComponent implements OnInit {
    field: string;
    apis = {
        post: 'POST:api/complete/packet/',
    };
    messages = {
        success: 'Setting as complete was successful.',
        error: 'There was an error while setting as complete.',
    };
    error = false;
    selectedItems = [];
    selectedNames = [];
    selectedNamesSet = new Set();
    confirmationText = '';
    othersText = '';
    results: any;
    constructor(
        private appHttpService: AppHttpService,
        private appMessageService: AppMessageService,
        private appHelpersService: AppHelpersService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        public dialogRef: MatDialogRef<AppDialogCompleteComponent>,
        private dialog: MatDialog,
        private appAlertService: AppAlertService,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            analyticsFilterUrl: any;
            analyticsFilterCount: number;
            contactName: string;
            vingTitle: string;
            vingMicroburst: boolean;
            page: string;
            id: number;
            selected: any[];
            allSelected: boolean;
            allSelectedIndexes: any[];
            excluded: any[];
            results: any;
            rows: any;
        }
    ) {
        this.setResults();
    }

    private setResults() {
        if (this.data.results.data) {
            this.results = this.data.results.data.results;
        } else {
            this.results = this.data.results.results;
        }
    }

    private findResultById(id) {
        if (this.data.page === 'PACKET-ASSIGNMENTS') {
            return this.results.find(
                (item) => item.contact.contactId === id
            );
        } else if (this.data.page === 'CONTACT-ASSIGNMENTS') {
            return this.results.find(
                (item) => item.packet.packetId === id
            );
        }
    }

    ngOnInit() {
        let indexes,
            itemCount;

        if (this.data.allSelected) {
            indexes = this.data.allSelectedIndexes;
            itemCount = (this.data.analyticsFilterCount - this.data.excluded.length)
        } else {
            indexes = this.data.selected;
            itemCount = this.data.selected.length;
        }

        indexes.forEach((id) => {
            const result = this.findResultById(id);
            if (result !== undefined) {
                this.selectedItems.push(result);
                if (this.data.page === 'PACKET-ASSIGNMENTS') {
                    this.selectedNamesSet.add(result.contact.name);
                } else {
                    this.selectedNamesSet.add(result.packet.title);
                }
            }
        });

        this.selectedNames = Array.from(this.selectedNamesSet);

        if (this.data.page === 'PACKET-ASSIGNMENTS') {
            // This will set assignments as complete for
            let assignment_str = 'the assignment'
            if (itemCount > 1) {
                assignment_str = 'assignments'
            }

            this.confirmationText = 'This will set ' + assignment_str + ' to "' + this.data.vingTitle + '" as complete for ';

            if (itemCount > 2) {
                this.confirmationText += this.selectedNames[0] + ', ' + this.selectedNames[1] + ', and ' + (itemCount - 2) + ' others.'
            } else if (itemCount == 2) {
                this.confirmationText += this.selectedNames[0] + ' and ' + this.selectedNames[1]
            } else {
                this.confirmationText += this.selectedNames[0] + '.'
            }
        } else if (this.data.page === 'CONTACT-ASSIGNMENTS') {
            this.confirmationText = 'This will set assignments as complete for ';

            if (itemCount > 2) {
                this.confirmationText += this.selectedNames[0] + ', ' + this.selectedNames[1] + ', and ' + (itemCount - 2) + ' others.'
            } else if (itemCount == 2) {
                this.confirmationText +=  this.selectedNames[0] + ' and ' + this.selectedNames[1];
            } else {
                this.confirmationText +=  this.selectedNames[0];
            }
        }
    }
    complete() {
        let completeData = {};

        if (this.data.allSelected) {
            completeData = {
                analyticsFilter: {
                    requestUrl: this.data.analyticsFilterUrl,
                    count: this.data.analyticsFilterCount,
                    excludedIds: this.data.excluded,
                },
            };
        } else if (this.data.page === 'CONTACT-ASSIGNMENTS') {
            this.apis.post = 'POST:api/complete/contact/';
            completeData = {
                packetIds: this.data.selected,
            };
        } else {
            completeData = {
                contactIds: this.data.selected,
            };
        }

        if (this.data.page === 'CONTACT-ASSIGNMENTS') {
            this.apis.post = 'POST:api/complete/contact/';
        }

        this.appHttpService.request(
            this.apis.post + this.data.id + '/',
            completeData,
            (res) => {
                this.dialogRef.close();
                this.appMessageService.show(
                    'app',
                    'success',
                    this.messages.success,
                    4
                );
                this.appLoadingIndicatorService.hide();
            },
            (err) => {
                this.appLoadingIndicatorService.hide();
                this.appMessageService.show(
                    'modal',
                    'error',
                    this.messages.error,
                    4
                );
            }
        );
    }
}
