<div class="page">
    <!--Header-->
    <div class="header">
        <div class="title">Assignments</div>
        <div class="searchbox">
            <div class="searchbox-control">
                <input
                    type="text"
                    name="search"
                    placeholder="Search"
                    [(ngModel)]="searchText"
                    (keyup)="handleSearchChange($event)"
                />
                <i class="material-icons icon" *ngIf="!searchText"
                    >search</i
                >
                <i
                    class="material-icons icon clear"
                    *ngIf="searchText"
                    (click)="clearSearch()"
                    >clear</i
                >
            </div>
        </div>
    </div>
    <!--Assignments-->
    <div class="content">
        <div class="assignments-table">
            <div class="assignments-table-top">
                <!-- Tabs -->
                <div class="assignments-tabs">
                    <button
                        [ngClass]="{ 'active-assignments-nav-button': !isDone }"
                        class="assignments-nav-button"
                        (click)="tabAssignments('todo')"
                    >
                        <div class="button-content">
                            <i class="material-icons icon tab-icon">assignment</i>
                            <span> To Do</span>
                        </div>
                    </button>
                    <button
                        [ngClass]="{ 'active-assignments-nav-button': isDone }"
                        class="assignments-nav-button"
                        (click)="tabAssignments('done')"
                    >
                        <div class="button-content">
                            <i class="material-icons icon tab-icon"
                                >assignment_turned_in</i
                            >
                            <span>Done</span>
                        </div>
                    </button>
                </div>

                <!--Paging-->
                <div class="paging" *ngIf="numberOfPages > 1">
                <!--Button Left-->
                <div class="paging-button left">
                    <button
                        (click)="handlePagination('previous')"
                        [disabled]="currentPageIndex < 1"
                        class="app-button small no-text blue"
                    >
                        <i class="material-icons icon"
                            >keyboard_arrow_left</i
                        >
                    </button>
                </div>

                <div class="paging-details">
                    <div class="number-of-pages">
                        {{
                            currentPageIndex + 1
                        }}&nbsp;&nbsp;/&nbsp;&nbsp;{{ numberOfPages }}
                    </div>
                </div>

                <!--Button Right-->
                <div class="paging-button right">
                    <button
                        (click)="handlePagination('next')"
                        [disabled]="
                            currentPageIndex >= numberOfPages - 1
                        "
                        class="app-button small no-text blue"
                    >
                        <i class="material-icons icon"
                            >keyboard_arrow_right</i
                        >
                    </button>
                </div>
            </div>
            </div>
            <!--Header-->
            <div class="assignments-table-header">
                <table>
                    <thead>
                        <tr>
                            <th class="col-notification">&nbsp;</th>
                            <th
                                class="col-time"
                                title="Expected view schedule"
                                *ngIf="!isDone"
                            >
                                <i class="material-icons icon">timer</i>
                            </th>
                            <th class="col-title">Title</th>
                            <th class="col-notification" *ngIf="isDone">Cert</th>
                            <th class="col-date" *ngIf="!isDone">Due</th>
                            <th class="col-remaining" *ngIf="!isDone">Remaining</th>
                            <th class="col-progress" *ngIf="!isDone">Progress</th>
                            <th class="col-date" *ngIf="isDone">Completed</th>
                            <th class="col-score" *ngIf="isDone">Score</th>
                            <th class="col-date" *ngIf="isDone">Expires</th>
                            <th class="col-type">Type</th>
                        </tr>
                    </thead>
                </table>
            </div>

            <!--Body-->
            <div class="assignments-table-body">

                    <table>
                        <tbody>
                            <tr
                                *ngFor="let assignment of assignments; let i = index"
                                [class]="assignment.isComplete ? 'completed' : ''"
                            >
                                <!--Notification-->
                                <td class="col-notification">
                                    <i
                                        class="material-icons icon"
                                        *ngIf="assignment.messages.length"
                                        (click)="
                                            onShowMessages(assignment.messages)
                                        "
                                        >message</i
                                    >
                                </td>
                                <!--Time-->
                                <td class="col-time" *ngIf="!isDone">
                                    <div class="minutes" *ngIf="assignment.consumeMinutes">
                                        {{ assignment.consumeMinutes }} min
                                    </div>
                                    <div class="interval" *ngIf="assignment.consumeInterval">
                                        <span *ngIf="assignment.consumeInterval == 1">daily</span>
                                        <span *ngIf="assignment.consumeInterval > 1 && assignment.consumeInterval < 5">biweekly</span>
                                        <span *ngIf="assignment.consumeInterval > 4 && assignment.consumeInterval < 10">weekly</span>
                                        <span *ngIf="assignment.consumeInterval > 9">periodically</span>
                                    </div>
                                </td>

                                <!--Title-->
                                <td
                                    class="col-title"
                                    [routerLink]="'/view/' + assignment.stub"
                                >
                                    <div class="text-wrapper">
                                        <div class="title">
                                            {{ assignment.title }}
                                        </div>
                                    </div>
                                </td>

                                <!--Certificate-->
                                <td class="col-notification" *ngIf="isDone">
                                    <a *ngIf="assignment.certificateUrl" href="{{ assignment.certificateUrl }}">
                                        <i class="material-icons icon">workspace_premium</i>
                                    </a>
                                </td>

                                <!--Due-->
                                <td
                                    class="col-date"
                                    *ngIf="!isDone"
                                    [routerLink]="'/view/' + assignment.stub"
                                >
                                    <div class="text-wrapper">
                                        <div class="text">
                                            {{ getDisplayDate(assignment.dateDue) }}
                                        </div>
                                    </div>
                                </td>

                                <!--Remaining-->
                                <td
                                    class="col-remaining"
                                    *ngIf="!isDone"
                                    [routerLink]="'/view/' + assignment.stub"
                                >
                                    <div class="text-wrapper">
                                        <div class="text">
                                            {{ getRemaining(assignment.duration, assignment.durationComplete) }}
                                        </div>
                                    </div>
                                </td>

                                <!--Progress-->
                                <td
                                    class="col-progress"
                                    *ngIf="!isDone"
                                    [routerLink]="'/view/' + assignment.stub"
                                    [ngClass]="{ done: isDone }"
                                >
                                    <!--ProgressBar-->
                                    <div
                                        class="progress"
                                        *ngIf="!isDone && assignment.progress < 1"
                                        [title]="getProgressValue(assignment.progress) + '%'"
                                    >
                                        <div class="text">
                                            {{
                                                getProgressValue(assignment.progress)
                                                    | number: '1.0-0'
                                            }}%
                                        </div>
                                        <div class="bar">
                                            <div
                                                class="fill"
                                                [style.width]="
                                                    getProgressValue(assignment.progress) +
                                                    '%'
                                                "
                                            ></div>
                                        </div>
                                    </div>
                                </td>
                                <!--Completed-->
                                <td
                                    class="col-date"
                                    *ngIf="isDone"
                                    [routerLink]="'/view/' + assignment.stub"
                                >
                                    <div class="text-wrapper">
                                        <div class="text">
                                            {{ getDisplayDate(assignment.dateCompleted) }}
                                        </div>
                                    </div>
                                </td>
                                <td
                                    class="col-score"
                                    [routerLink]="'/view/' + assignment.stub"
                                    *ngIf="isDone"
                                >
                                    <div
                                        *ngIf="assignment.score"
                                        [ngClass]="{
                                            green: assignment.score >= 0.8,
                                            yellow:
                                                assignment.score < 0.8 &&
                                                assignment.score >= 0.6,
                                            red: assignment.score <= 0.6
                                        }"
                                    >
                                        {{
                                            assignment.score * 100
                                                | number: '1.0-0'
                                        }}%
                                    </div>
                                    <div *ngIf="!assignment.score">- -</div>
                                </td>
                                <!--Expires-->
                                <td
                                    class="col-date"
                                    *ngIf="isDone"
                                    [routerLink]="'/view/' + assignment.stub"
                                >
                                    <div class="text-wrapper">
                                        <div class="text">
                                            {{ getDisplayDate(assignment.dateExpires) }}
                                        </div>
                                    </div>
                                </td>

                                <!--Type-->
                                <td
                                    class="col-type"
                                    [routerLink]="'/view/' + assignment.stub"
                                >
                                    <div class="text-wrapper">
                                        <div class="text">
                                            {{ getFriendlyType(assignment.type) }}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <div class="assignments-no-results-message" *ngIf="assignments?.length === 0">
                            <div *ngIf="!isDone">
                                When you have an assignment to complete, it will
                                appear here.
                            </div>
                            <div *ngIf="isDone">
                                Your completed assignments will appear here.
                            </div>
                        </div>
                    </table>

            </div>
        </div>
    </div>
    <!--Footer-->
    <div class="footer">
        <div class="footer-key-value" *ngIf="!isMobile">
            {{ getTableFooterText() }}
        </div>
    </div>
</div>
