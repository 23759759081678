export class Utils {
    static trim(val: string) {
        return val ? val.replace(/\s+/g, ' ').trim() : '';
    }

    static get2LetterName(name: string) {
        const matches = name.match(/\b(\w)/g);
        const acronym = matches.join('').substring(0, 2);
        return acronym.toUpperCase();
    }

    static validateEmail(val: string) {
        const re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(val).toLowerCase());
    }

    static encURIComponent(key: string, val: string) {
        if (key === 'selected_ids' || key === 'excluded_ids') {
            const valArr = val.toString().split(',');
            return valArr
                .map(function (v) {
                    return encodeURIComponent(v);
                })
                .join(',');
        } else {
            return encodeURIComponent(val);
        }
    }

    static decURIComponent(key: string, val: string) {
        if (key === 'selected_ids' || key === 'excluded_ids') {
            const valArr = val.toString().split(',');
            return valArr
                .map(function (v) {
                    return decodeURIComponent(v);
                })
                .join(',');
        } else {
            return decodeURIComponent(val);
        }
    }

    static roundTo5Minutes(seconds: number): number {
        const minutes = Math.round(seconds / 60 / 5) * 5

        if (minutes == 0) {
            return 5;
        } else {
            return minutes;
        }
    }

    static roundToHalfHours(seconds: number): number {
        const roundedHours = (Math.round(((seconds / 60 / 60) * 10) / 5) * 5) / 10,
              halfHour = Math.floor(roundedHours) === roundedHours ? false : true;

        if (halfHour) {
            return roundedHours + 0.5;
        } else {
            return roundedHours;
        }
    }

    static getDurationCompleteValue(isComplete: boolean, viewedDuration: number, totalDuration: number): string {
        let value = 0;

        if (isComplete) {
            value = Utils.roundTo5Minutes(totalDuration);
        } else if (viewedDuration < 5 * 60 && !isComplete) {
            value = Math.ceil(viewedDuration / 60)
        } else {
            value = Utils.roundTo5Minutes(viewedDuration)
        }

        if (value == 0) {
            return '--'
        } else {
            return `${value} mins`;
        }
    }

    static slugify(string) {
        const a =
            'àáäâãåăæąçćčđďèéěėëêęǵḧìíïîįłḿǹńňñòóöôœøṕŕřßśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
        const b =
            'aaaaaaaaacccddeeeeeeeghiiiiilmnnnnooooooprrssssttuuuuuuuuuwxyyzzz------';
        const p = new RegExp(a.split('').join('|'), 'g');

        return string
            .toString()
            .toLowerCase()
            .replace(/\s+/g, '-')
            .replace(p, (c) => b.charAt(a.indexOf(c)))
            .replace(/&/g, '-and-')
            .replace(/[^\w\-]+/g, '')
            .replace(/\-\-+/g, '-')
            .replace(/^-+/, '')
            .replace(/-+$/, '');
    }

    static setUrlParameter(url, key, value) {
        url = url.split('#')[0];
        const baseUrl = url.split('?')[0],
            urlQueryString = url.split('?')[1] ? `?${url.split('?')[1]}` : '',
            newParam = `${key}=${value}`;
        let params = `?${newParam}`;

        // If the "search" string exists, then build params from it
        if (urlQueryString) {
            const updateRegex = new RegExp('([?&])' + key + '[^&]*'),
                removeRegex = new RegExp('([?&])' + key + '=[^&;]+[&;]?');

            if (
                typeof value === 'undefined' ||
                value === null ||
                value === ''
            ) {
                // Remove param if value is empty
                params = urlQueryString.replace(removeRegex, '$1');
                params = params.replace(/[&;]$/, '');
            } else if (urlQueryString.match(updateRegex) !== null) {
                // If param exists already, update it
                params = urlQueryString.replace(updateRegex, '$1' + newParam);
            } else {
                // Otherwise, add it to end of query string
                params = `${urlQueryString}&${newParam}`;
            }
        }

        // no parameter was set so we don't need the question mark
        params = params === '?' ? '' : params;

        return baseUrl + params;
    }
}
