import { Component, ViewChild, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppUserService } from '../../../../shared/services/app.user.service';
import { Utils } from '../../../../shared/app.utils';

@Component({
    templateUrl: './dialog.view.status.component.html',
    styleUrls: ['./dialog.view.status.component.less'],
})
export class DialogViewStatusComponent implements OnInit {
    view: any;
    checklist: any;
    packet: any;
    completedComponents: Array<any>;
    message: string;
    remainder: number;
    private count: any;
    next = 'Close';
    done = '';
    durationComplete: number;
    isComplete: boolean;

    constructor(
        private router: Router,
        private appUserService: AppUserService,
        public dialogRef: MatDialogRef<DialogViewStatusComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            packet: any;
            view: any;
            message: string;
            checklist: any;
        }
    ) {}

    ngOnInit() {
        if (this.data.checklist) {
            this.message = 'You have finished submitting all of your responses.';
        } else {
            this.view = this.data.view;
            this.packet = this.data.packet;

            if (this.view.isComplete) {
                this.message =
                    'You have finished viewing all of the content. Keep up the great work!';
            } else if (
                this.view.enforceOrder &&
                this.view.consumeInterval &&
                this.view.consumeMinutes
            ) {
                let remainderString = "";

                const remainderSeconds = this.packet.duration - this.view.durationComplete;

                if (remainderSeconds > 3600) {
                    const roundedHours = Utils.roundToHalfHours(remainderSeconds),
                          halfHour = Math.floor(roundedHours) !== roundedHours,
                          are = roundedHours === 1 && halfHour === false ? "is" : "are",
                          hours = roundedHours === 1 && halfHour === false ? "hour" : "hours",
                          halfHourString = halfHour === true ? " and a half" : "";

                    remainderString = `There ${are} about ${roundedHours}${halfHourString} ${hours} remaining.`
                } else {
                    const roundedMinutes = Utils.roundTo5Minutes(remainderSeconds);

                    remainderString = `There are about ${roundedMinutes} minutes remaining.`
                }

                const interval = this.view.consumeInterval,
                      days = interval > 1 ? "days" : "day";

                this.message = `You're finished for ${interval} ${days}. ${remainderString} We'll remind you when it's time to complete the next ${this.view.consumeMinutes}-minute
                session. See you next time!`
            } else {
                this.message =
                    "You have reached the end, but you're not done yet. You'll be taken back to the first component that was skipped.";
                this.next = 'Go to Skipped';
                this.done = 'Done';
            }
        }
    }

    close(action) {
        if (action === 'continue') {
            this.dialogRef.close({ event: 'continue' });
        } else {
            this.dialogRef.close({ event: 'done' });
        }
    }
}
