export const columnWidths = {
    smallIcon: '25px',
    bigIcon: '45px',
    name: '190px',
    date: '90px',
    progress: '85px',
    visits: '45px',
    viewed: '60px',
    score: '80px',
    answer: '500px'
};
