<div id="contacts">
    <!--Header-->
    <div id="header">
        <div class="left">
            <div class="arrow" (click)="resetContact()" *ngIf="selectedContact">
                <i class="material-icons icon">arrow_back</i>
            </div>
            <div class="vings-title">
                <div class="title-icon">
                    <i class="material-icons icon">people</i>
                </div>
                <div class="title-text">Contacts</div>
            </div>
        </div>

        <!--Options-->
        <div class="right">
            <div
                class="inner"
                [ngClass]="{ 'inner-selected': selectedContact }"
            >
                <app-contacts-header
                    [contacts]="contacts"
                    [selectedContactIds]="selectedContactIds"
                    [excludedContactIds]="excludedContactIds"
                    [selectedAllContacts]="selectedAllContacts"
                    [contactsCount]="contactsCount"
                    [selectedLabels]="selected['labels']"
                    [selectedFilters]="selected['filters']"
                    [selectedContact]="selectedContact"
                    [selectedStatus]="selectedStatus"
                    [isDetail]="selectedContact"
                    [selectedPage]=this.page
                    [sentimentAssignments]=this.sentimentAssignments
                    [sentimentSubmissions]=this.sentimentSubmissions
                    (displaySelectedContact)="displaySelectedContact($event)"
                    (selectPage)="selectPage($event)"
                    (onClickHeaderButton)="handleHeaderButtonClick($event)"
                    (onToggleAllContactIds)="toggleSelectAllContacts()"
                >
                </app-contacts-header>
            </div>
        </div>
    </div>

    <!--View-->
    <div id="content" [class]="selectedContact ? 'active' : ''">
        <!--Panel One - Filtering-->
        <div id="nav" class="step1">
            <app-filter-navigation
                [page]="'contacts'"
                [entityLists]="entityLists"
                [selectedLabels]="selected['labels']"
                [selected]="selected"
                [filters]="entityLists.custom"
                [selectedFilters]="selected.custom"
                (onEditCategory)="editCategory($event)"
                (onToggleLabel)="selectLabel($event)"
                (onToggleSelectedItem)="toggleSelectedItem($event)"
                (onDeleteCategory)="deleteCategory($event)"
                (onEditCustomFilter)="editCustomFilter($event)"
                (onDeleteCustomFilter)="deleteCustomFilter($event)"
                (onCategoryCreate)="loadLabels(true)"
                (onRemoveLabelFromList)="removeLabel($event)"
            >
            </app-filter-navigation>
            <!--Reset Button-->
            <div class="reset-button">
                <button
                    *ngIf="
                        selected.custom.length ||
                        selected.labels.length ||
                        !!filterText
                    "
                    class="app-button light block"
                    (click)="resetSearch()"
                >
                    Reset Search
                </button>
            </div>
        </div>

        <div class="list-wrapper">
            <!--Panel Two-->
            <div id="panel-two" class="step1">
                <div class="container">
                    <app-contacts-cards
                        [searchStr]="filterText"
                        [contacts]="contacts"
                        [contactsCount]="contactsCount"
                        [statuses]="statuses"
                        [selectedStatus]="selectedStatus"
                        [sorts]="sorts"
                        [selectedSort]="selectedSort"
                        [owners]="owners"
                        [selectedOwner]="selectedOwner"
                        [selectedContact]="selectedContact"
                        [selectedContactIds]="selectedContactIds"
                        [excludedContactIds]="excludedContactIds"
                        [selectedAllContacts]="selectedAllContacts"
                        (handleLazyLoadContacts)="lazyLoadContacts($event)"
                        (handleSearchChange)="handleSearchTextChange($event)"
                        (handleSortUpdate)="updateSelectedSort($event)"
                        (handleStatusUpdate)="
                            updateSelectedStatus($event)
                        "
                        (handleOwnerUpdate)="updateSelectedOwner($event)"
                        (handleSelectContact)="selectContact($event)"
                        (handleToggleContactId)="toggleSelectedContact($event)"
                        (handleResetSearch)="onDeselectContacts($event)"
                    >
                    </app-contacts-cards>
                </div>
            </div>
        </div>
        <!--Panel Three-->
        <div id="panel-three" class="step2">
            <div class="view">
                <!--If there is at least one contact selected, display tabs...-->
                <div class="selected-contact" *ngIf="selectedContact">
                    <div class="details">
                        <!--Header-->
                        <div
                            [class]="
                                selectedContact.blacklist
                                    ? 'header blacklisted'
                                    : 'header'
                            "
                        >
                            <!--Owner-->
                            <div
                                class="owner"
                                *ngIf="selectedContact.owner"
                            >
                                <div
                                    class="icon"
                                    [style.background]="
                                        selectedContact.owner.hexColor
                                    "
                                >
                                    <span
                                        [title]="selectedContact.owner.name"
                                        >{{
                                            get2LetterName(
                                                selectedContact.owner.name
                                            )
                                        }}</span
                                    >
                                </div>
                            </div>
                            <!--Blacklist-->
                            <div
                                class="blacklist"
                                *ngIf="selectedContact.blacklist"
                            >
                                <i
                                    class="material-icons icon"
                                    title="Do Not Contact"
                                    >speaker_notes_off</i
                                >
                            </div>
                            <!--Title-->
                            <div
                                [class]="
                                    selectedContact.name
                                        ? 'title'
                                        : 'title title-not-set'
                                "
                                [title]="selectedContact.name"
                            >
                                <i
                                    *ngIf="selectedContact.isUser"
                                    class="is-user-icon material-icons icon"
                                    >verified_user</i
                                >
                                {{
                                    selectedContact.name
                                        ? selectedContact.name
                                        : 'No name set'
                                }}
                            </div>
                            <!--Buttons-->
                            <div class="buttons">
                                <!--Edit Contact-->
                                <button
                                    class="app-button dark x-small no-text"
                                    title="Edit Contact"
                                    (click)="displayEditContactDialog()"
                                >
                                    <i class="material-icons icon">edit</i>
                                </button>
                            </div>
                        </div>

                        <div class="top">
                            <!--Avatar-->
                            <div class="avatar">
                                <div
                                    class="image"
                                    *ngIf="selectedContact.avatarUrl"
                                    [ngStyle]="{
                                        background:
                                            'url(\'' +
                                            selectedContact.avatarUrl +
                                            '\')',
                                        borderColor:
                                            selectedContact.sentiment.color
                                    }"
                                ></div>
                                <div
                                    class="image no-avatar"
                                    *ngIf="!selectedContact.avatarUrl"
                                >
                                    <i
                                        class="material-icons icon"
                                        [ngStyle]="{
                                            color: selectedContact.sentiment.color
                                        }"
                                        >{{
                                            selectedContact.sentiment.icon
                                        }}</i
                                    >
                                </div>
                            </div>

                            <!--Contact Details-->
                            <div class="fields">
                                <div class="field">
                                    <label>Job Title</label>
                                    <span [title]="selectedContact.title">{{
                                        selectedContact.title
                                    }}</span>
                                </div>
                                <div class="field">
                                    <label>Start Date</label>
                                    <span>{{
                                        selectedContact.hireDate
                                            | date: 'MM/dd/yyyy'
                                    }}</span>
                                </div>
                                <div class="field">
                                    <label>Separation Date</label>
                                    <span>{{
                                        selectedContact.separationDate
                                            | date: 'MM/dd/yyyy'
                                    }}</span>
                                </div>
                                <div class="field">
                                    <label>Internal ID</label>
                                    <span
                                        [title]="selectedContact.internalId"
                                        >{{
                                            selectedContact.internalId
                                        }}</span
                                    >
                                </div>
                            </div>
                        </div>

                        <div class="bottom">
                            <div class="container">
                                <!--Active Contact Methods-->
                                <div class="items">
                                    <fieldset style="position: relative">
                                        <legend>
                                            Active Contact Methods
                                        </legend>
                                        <i
                                            *ngIf="activeContactMethods.length === 0"
                                            class="
                                                icon
                                                material-icons
                                                contact-method-flag
                                            "
                                            >error</i
                                        >
                                    </fieldset>

                                    <ng-container
                                        *ngIf="
                                            activeContactMethods.length > 0
                                        "
                                    >
                                        <div
                                            class="item"
                                            *ngFor="
                                                let contactMethod of activeContactMethods
                                            "
                                        >
                                            {{
                                                contactMethod | formatPhone
                                            }}
                                            <i
                                                (click)="
                                                    deactivateContactMethod(
                                                        contactMethod,
                                                        selectedContact.id
                                                    )
                                                "
                                                class="
                                                    material-icons
                                                    icon
                                                    action
                                                "
                                                title="Deactivate This Method."
                                                >remove_circle_outline</i
                                            >
                                        </div>
                                    </ng-container>

                                    <div
                                        class="app-message-text"
                                        *ngIf="!activeContactMethods.length"
                                    >
                                        <br />
                                        No Active Contact Methods
                                    </div>
                                </div>

                                <!--Inactive Contact Methods-->
                                <div
                                    class="items"
                                    *ngIf="
                                        inactiveContactMethods.length > 0
                                    "
                                >
                                    <fieldset>
                                        <legend>
                                            Inactive Contact Methods
                                        </legend>
                                    </fieldset>

                                    <div
                                        class="item action-item"
                                        *ngFor="
                                            let contactMethod of inactiveContactMethods
                                        "
                                    >
                                        {{ contactMethod | formatPhone }}
                                        <i
                                            class="
                                                material-icons
                                                icon
                                                action
                                            "
                                            title="Activate This Method."
                                            (click)="
                                                activateContactMethod(
                                                    contactMethod,
                                                    selectedContact.id
                                                )
                                            "
                                            >add_circle_outline</i
                                        >
                                    </div>
                                </div>

                                <!--Bad Contact Methods-->
                                <div
                                    class="items"
                                    *ngIf="badContactMethods.length > 0"
                                >
                                    <fieldset>
                                        <legend>Bad Contact Methods</legend>
                                    </fieldset>

                                    <div
                                        class="item action-item"
                                        *ngFor="
                                            let contactMethod of badContactMethods
                                        "
                                    >
                                        {{ contactMethod | formatPhone }}
                                        <i
                                            class="
                                                material-icons
                                                icon
                                                action
                                            "
                                            title="Re-Add This Method."
                                            (click)="
                                                retryContactMethod(
                                                    contactMethod,
                                                    selectedContact.id
                                                )
                                            "
                                            >refresh</i
                                        >
                                    </div>
                                </div>

                                <!--Labels & Lists-->
                                <div class="labels-lists">
                                    <!--Labels-->
                                    <div
                                        class="items"
                                        *ngIf="
                                            selectedContactHasLabels(true)
                                        "
                                    >
                                        <fieldset>
                                            <legend>Labels</legend>
                                        </fieldset>

                                        <div
                                            class="item"
                                            *ngFor="
                                                let label of selectedContact.labels
                                            "
                                            [title]="label"
                                        >
                                            {{ label }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="body">
                        <div class="analytics" *ngIf="page === 'assignments'">
                            <app-selected-contact-assignments
                                [selectNewContactAssignments]="
                                    selectNewContactAssignments.asObservable()
                                "
                            >
                            </app-selected-contact-assignments>
                        </div>
                        <div class="analytics" *ngIf="page === 'submissions'">
                            <app-selected-contact-submissions
                                [selectNewContactSubmissions]="
                                    selectNewContactSubmissions.asObservable()
                                "
                            >
                            </app-selected-contact-submissions>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
