import {
    Component,
    Input,
    OnInit,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AppInfoService } from '../../../../shared/services/app-info/app.info.service';
import { Utils } from '../../../../shared/app.utils';
import {
    catchError,
    distinctUntilChanged,
    map,
    switchMap,
    tap,
} from 'rxjs/operators';
import { AppHttpService } from '../../../../shared/services/app.http.service';
import { concat, Observable, of } from 'rxjs';
import { AppHelpersService } from '../../../../shared/services/app.helpers.service';
import { AppLoadingIndicatorService } from '../../../../shared/services/app.loading-indicator.service';
import { AppDialogConfirmComponent } from '../../../../shared/dialogs/dialog-confirm/app.dialog-confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { AppMessageService } from '../../../../shared/services/app.message.service';
import { DialogSendInvitationComponent } from '../../../../shared/dialogs/dialog-send-invitation/dialog.send.invitation.component';
import {Packet} from "../../classes/packet";

interface Result {
    id: string;
    name: string;
    email: string;
    phone: string;
    method: string;
    disabled?: boolean;
}
@Component({
    selector: 'app-vings-scheduled',
    styleUrls: ['./schedule.component.less'],
    templateUrl: './schedule.component.html',
})
export class ScheduleComponent implements OnInit, OnChanges {
    @Input() selectedPacketIds: Array<number>;
    @Input() excludedPacketIds: Array<number>;
    @Input() selectedAllPackets: boolean;
    @Input() packets: Array<Packet>;
    @Input() scheduledInvitations: any;
    @Output() handleSelectPacket = new EventEmitter<any>();
    searchInput$ = new Subject<string>();
    peopleLoading = false;
    results$: Observable<Result[]>;
    selectedResults: Array<any> = [];
    filterBySelected: boolean;
    private types = {
        contact: 1,
        filter: 2,
        label: 3,
        table: 5,
        email: 6,
        phone: 7,
    };
    private filterData: {
        emails: any;
        contactLabels: any;
        packetIds: any;
        phones: any;
        contactFilters: any;
        contactIds: any;
    };
    constructor(
        private router: Router,
        private appInfoService: AppInfoService,
        private appHttpService: AppHttpService,
        private appHelpersService: AppHelpersService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private appMessageService: AppMessageService,
        private dialog: MatDialog
    ) {}

    ngOnInit() {
        this.formatScheduledInvitations();

        this.results$ = concat(
            of([]), // default items
            this.searchInput$.pipe(
                distinctUntilChanged(),
                tap(() => (this.peopleLoading = true)),
                switchMap((term) =>
                    this.getSearchResults(term).pipe(
                        catchError(() => of([])), // empty list on error
                        tap(() => (this.peopleLoading = false))
                    )
                )
            )
        );
    }

    padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.filterBySelected) {
            this.onChange();
        }
    }
    trackByFn(item: any) {
        return item.id;
    }
    getSearchResults(term: string = null): Observable<Result[]> {
        if (term) {
            return this.appHttpService
                .requestObserver('GET:api/contacts/search/', { query: term })
                .pipe(map((data) => this.cleanSearchResults(data)));
        } else {
            return of([]);
        }
    }
    formatScheduledInvitations() {
        this.scheduledInvitations.map((invite) => {
            if (invite.sendDate !== null) {
                invite.sendDate = new Date(invite.sendDate);
                invite.sendDate = [
                    this.padTo2Digits(invite.sendDate.getMonth() + 1),
                    this.padTo2Digits(invite.sendDate.getDate()),
                    invite.sendDate.getFullYear(),
                ].join('/');
            }
        });
    }
    cleanSearchResults(data) {
        const contacts = [];
        data.contacts.map((item, index) => {
            item.key = item.id;
            item.type = this.types.contact;
            item.contactMethods.active.map((el, idx) => {
                const itm = this.appHelpersService.getClone(item);
                itm.id = 'contacts_' + item.key + '_' + idx;
                itm.contactMethod = el;
                if (itm.name && !/^ *$/.test(itm.name)) {
                    contacts.push(itm);
                } else {
                    itm.name = 'No name set';
                    contacts.push(itm);
                }
                itm.class = 'contact_' + idx;
                itm.group = 'Contacts';
                itm.method = ' | ' + itm.contactMethod;
            });
        });
        const filters = data.filters.map((item, index) => {
            item.key = item.id;
            item.id = 'filters_' + item.id;
            item.type = this.types.filter;
            item.group = 'Filters';
            item.count = item.contactCount;
            return item;
        });
        const labels = data.labels.map((item, index) => {
            item.id = 'labels_' + item.name;
            item.type = this.types.label;
            item.group = 'Labels';
            item.count = item.objectCount;
            return item;
        });
        const newContacts = [];
        data.newEmails.map((email, index) => {
            const item = {
                id: email,
                type: this.types.email,
                email: email,
                group: 'New Contacts',
            };
            newContacts.push(item);
        });
        data.newPhones.map((phone, index) => {
            const item = {
                id: phone,
                type: this.types.phone,
                phone: phone,
                group: 'New Contacts',
            };
            newContacts.push(item);
        });
        const res = [...newContacts, ...filters, ...labels, ...contacts];
        return res;
    }

    handleResultsUpdate(results) {
        this.selectedResults = results;
        this.onChange();
    }

    onChange() {
        this.appLoadingIndicatorService.show(
            '.scheduled-vings .scheduled-vings-table .scheduled-vings-table-body'
        );
        const allPackets = this.packets.map((ving) => ving.id);
        const emails = [],
            phones = [],
            filters = [],
            labels = [],
            contactIds = [];

        let packetIds = [];

        if (this.filterBySelected) {
            if (this.selectedAllPackets) {
                packetIds = allPackets;
                if (this.excludedPacketIds) {
                    packetIds = allPackets.filter(
                        (val) => !this.excludedPacketIds.includes(val)
                    );
                }
            } else {
                packetIds = this.selectedPacketIds;
            }
        }

        this.selectedResults.map((item, index) => {
            if (item.type === this.types.contact) {
                if (Utils.validateEmail(item.contactMethod)) {
                    emails.push(item.contactMethod);
                } else {
                    phones.push(item.contactMethod);
                }
            } else if (item.type === this.types.filter) {
                filters.push(item.key);
            } else if (item.type === this.types.label) {
                labels.push(item.name);
            } else if (item.type === this.types.email) {
                emails.push(item.email);
            } else if (item.type === this.types.phone) {
                phones.push(item.phone);
            }
            return item;
        });
        this.filterData = {
            emails: emails.join(','),
            phones: phones.join(','),
            contactLabels: labels.join(','),
            contactFilters: filters.join(','),
            contactIds: contactIds.join(','),
            packetIds: packetIds.join(','),
        };
        this.appHttpService.request(
            'GET:api/invitations/packet/unsent/',
            this.filterData,
            (response) => {
                this.scheduledInvitations = response.results;
                this.formatScheduledInvitations();
                this.appLoadingIndicatorService.hide(350);
            }
        );
    }

    filterbySelectedPackets() {
        if (this.filterBySelected) {
            this.filterBySelected = false;
            this.onChange();
        } else {
            this.filterBySelected = true;
            this.onChange();
        }
    }

    editInvitation(invitation: any) {
        // TODO: add prepopulation option to app-select2-component
        this.appHttpService.request(
            'GET:api/invitations/' + invitation.id,
            {},
            (response) => {
                this.dialog.open(DialogSendInvitationComponent, {
                    width: '700px',
                    minHeight: '370px',
                    height: 'auto',
                    disableClose: true,
                    data: {
                        shareType: "packet",
                        header: "Edit Assignments and Invitations",
                        prePopulate: response,
                        callback: () => {
                            this.onChange();
                        },
                    },
                });
            },
            () => {
                this.appLoadingIndicatorService.hide(350);
                const message =
                    'There was an error fetching the invitation data. Please try again.';
                this.appMessageService.show('app', 'error', message, 5.0);
            }
        );
    }

    deleteInvitation(invitation: any) {
        // Display confirmation dialog.
        this.dialog.open(AppDialogConfirmComponent, {
            height: '165px',
            width: '425px',
            data: {
                text: 'Are you sure you want to delete this invitation?',
                callback: () => {
                    this.appLoadingIndicatorService.show('app');
                    this.appHttpService.request(
                        'DELETE:api/invitations/' + invitation.id,
                        {},
                        (response) => {
                            this.appLoadingIndicatorService.hide(350);
                            const message =
                                'The invitation was successfully deleted.';
                            this.appMessageService.show(
                                'app',
                                'success',
                                message,
                                5.0
                            );
                            this.onChange();
                        },
                        () => {
                            this.appLoadingIndicatorService.hide(350);
                            const message =
                                'There was an error deleting the invitation. Please try again.';
                            this.appMessageService.show(
                                'app',
                                'error',
                                message,
                                5.0
                            );
                        }
                    );
                },
            },
        });
    }

    handleClickTitle(invitation) {
        this.handleSelectPacket.emit(invitation.packetId);
    }
}
