import {
    Component,
    EventEmitter,
    Input,
    Output,
    OnInit,
    OnChanges,
    SimpleChanges,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import * as pluralize from 'pluralize';

import {XTable, XtRow} from './classes/XTable';
import { XtFilter } from './classes/XTableFilter';
import { AppHelpersService } from '../../services/app.helpers.service';
import { AppLoadingIndicatorService } from '../../services/app.loading-indicator.service';
import { AppHttpService } from '../../services/app.http.service';
import { AppSelectOption } from '../app-select/classes/app.select.option';
import { AppDialogImageComponent } from '../../dialogs/dialog-image/app.dialog-image.component';
import { Utils } from '../../app.utils';
import * as $ from 'jquery';
import { debounceTime } from 'rxjs/operators';
import { AppDialogUnassignComponent } from '../../../modules/vings/dialogs/dialog-unassign/app.dialog-unassign.component';
import { AppDialogCompleteComponent } from '../../../modules/vings/dialogs/dialog-complete/app.dialog-complete.component';
import { Packet } from '../../../modules/vings/classes/packet';
import { query } from '@angular/animations';
import { AppDataTransform } from '../../app.data-transform';
import { AppMessageService } from '../../../shared/services/app.message.service';
import { AppDialogErrorComponent } from '../../dialogs/dialog-error/app.dialog-error.component';
import { DialogReadonlyComponent } from '../../dialogs/dialog-readonly/dialog.readonly.component';
import { AppDialogConfirmComponent } from '../../dialogs/dialog-confirm/app.dialog-confirm.component';
import { AppDialogResolveComponent } from '../../../modules/checklists/dialogs/dialog-resolve/app.dialog-resolve.component';
import {
    DialogUploadCertificateComponent
} from "../../dialogs/dialog-upload-certificate/dialog-upload-certificate.component";

@Component({
    selector: 'app-x-table',
    queries: {
        scrollBarAreaRef: new ViewChild('scrollBarAreaRef'),
    },
    templateUrl: './app.x.table.component.html',
    styleUrls: ['./app.x.table.component.less'],
})
export class AppXTableComponent implements OnInit, OnChanges {
    scrollbarOptions = {
        axis: 'y',
        theme: 'minimal-dark',
        scrollInertia: 300,
        advanced: {
            updateOnContentResize: true,
        },
    };
    isToggleAllSelected = false;
    selectedRowIndexes: Array<number> = [];
    selectedFlagged: Array<number> = [];
    excludedRowIndexes: Array<number> = [];
    pages: Array<AppSelectOption> = [];
    selectedPage: AppSelectOption;
    isSelectedInitialized = false;
    filterText: string;
    deselectedItems = [];
    selectedAllRows: any[] = [];
    private analyticsURL: any;

    private numberOfColumns: number;
    private lastSearchText: string;
    private searchSubject: Subject<string> = new Subject();
    public scrollBarAreaRef: ElementRef;

    @Input() id: number = null;
    @Input() question_id: number = null;
    @Input() title: string = null;
    @Input() microburst: boolean;
    @Input() page: string = null;
    @Input() contactName: string = null;
    @Input() searchText: string = null;
    @Input() xTableData: XTable;
    @Input() xTableFilters: Array<XtFilter>;
    @Input() analyticsData: any;
    @Input() numberOfPages: number;
    @Input() numbersPerPage: number;
    @Input() currentPageIndex: number;
    @Input() numberOfResults: number;
    @Input() results: any[];
    @Input() entityName = 'entry';
    @Input() canSelectRows = true;
    @Input() showSentiment = false;
    @Input() updatePaging: Observable<any>;
    @Input() searchDelayMs = 250;
    @Input() infoLink: string;
    @Input() noDataText: string;
    @Input() selectedRows: Array<number> = [];
    @Input() excludedRows: Array<number> = [];
    @Input() selectedAll = false;
    @Input() isOverviewSelected = false;
    @Input() analyticsFilterURL: string;

    @Output() handleSearchTextChange = new EventEmitter<any>();
    @Output() handleFiltersChange = new EventEmitter<any>();
    @Output() handlePageChange = new EventEmitter<any>();
    @Output() handleSelectedRows = new EventEmitter<any>();
    @Output() handleExcludedRows = new EventEmitter<any>();
    @Output() handleSelectedAllRows = new EventEmitter<any>();
    @Output() handleRefreshChecklistData = new EventEmitter<any>();
    @Output() handleUpdateUserTables = new EventEmitter<any>();
    @Output() export = new EventEmitter<any>();

    constructor(
        private router: Router,
        private appHelpersService: AppHelpersService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private appMessageService: AppMessageService,
        private appHttpService: AppHttpService,
        private dialog: MatDialog
    ) {}

    ngOnInit() {
        this.updatePaging.subscribe(() => {
            this.initPages();
        });
        this.filterText = this.searchText;
        this.lastSearchText = this.searchText;
        if (this.xTableData) {
            this.numberOfColumns = this.xTableData.headers.length;
        }

        this.selectedRowIndexes = this.selectedRows;
        this.excludedRowIndexes = this.excludedRows;
        this.isToggleAllSelected = this.selectedAll;
        this.selectAllRows();

        this.initPages();

        this.searchSubject
            .pipe(debounceTime(this.searchDelayMs))
            .subscribe((searchText) => {
                this.processSearchTextChange(searchText);
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.xTableData &&
            'previousValue' in changes.xTableData &&
            'currentValue' in changes.xTableData &&
            changes.xTableData.previousValue !== changes.xTableData.currentValue
        ) {
            this.selectAllRows();
        }
    }

    initPages(): void {
        // Clear list.
        this.pages = [];

        // Build list.
        for (let i = 0; i < this.numberOfPages; i++) {
            const page = (i + 1).toString();
            this.pages.push(new AppSelectOption(page, page));
        }

        // Set initial selected page.
        this.selectedPage = this.pages[0];

        // Check and initialize select.
        if (
            this.numberOfPages === this.pages.length &&
            Boolean(this.selectedPage)
        ) {
            this.isSelectedInitialized = true;
        }
    }

    handleSearchChange(event): void {
        if (event.key !== 'Enter') {
            const searchText = event.target.value;
            this.searchSubject.next(searchText);
        }
    }

    handleFilterChange(newFilterValue, filterName): void {
        this.handleFiltersChange.emit({
            newFilterValue: newFilterValue,
            filterName: filterName,
        });
    }

    isRowSelected(rowIndex: number): boolean {
        return this.selectedRowIndexes.indexOf(rowIndex) >= 0;
    }

    isRowFlagged(row: any): boolean {
        return row.cells[3].text === 'flagged';
    }

    toggleSelectedRow(event, row, selectable: boolean): void {
        if (selectable) {
            const currentRowIndex = this.selectedRowIndexes.indexOf(row.id);

            // If not in list, add to list; otherwise remove from list.
            if (currentRowIndex < 0) {
                this.selectedRowIndexes.push(row.id);
            } else {
                this.selectedRowIndexes.splice(currentRowIndex, 1);
                this.excludedRowIndexes.push(row.id);
            }

            if (this.page === 'CHECKLIST-SUBMISSIONS' || this.page === 'CONTACT-SUBMISSIONS') {
                const currentFlagRowIndex = this.selectedFlagged.indexOf(
                    row.id
                );
                if (currentFlagRowIndex < 0) {
                    if (this.isRowFlagged(row)) {
                        this.selectedFlagged.push(row.id);
                    }
                } else {
                    this.selectedFlagged.splice(
                        this.selectedFlagged.indexOf(currentFlagRowIndex),
                        1
                    );
                }
            }

            // Sort for future look ups.
            this.selectedRowIndexes.sort((a, b) => a - b);

            this.handleSelectedRows.emit(this.selectedRowIndexes);
            this.handleExcludedRows.emit(this.excludedRowIndexes);
        }
        event.stopPropagation();
    }

    toggleAllRows(): void {
        this.isToggleAllSelected = !this.isToggleAllSelected;
        if (this.isToggleAllSelected) {
            this.selectedRowIndexes = this.xTableData.rows.map((r) => r.id);
            this.handleSelectedAllRows.emit(true);
        } else {
            this.selectedRowIndexes = [];
            this.handleSelectedRows.emit(this.selectedRowIndexes);
            this.handleSelectedAllRows.emit(false);
        }
        this.excludedRowIndexes = [];
        this.handleExcludedRows.emit(this.excludedRowIndexes);
    }

    handleItemClick(e, name: string, row: XtRow): void {
        e.stopPropagation();
        if (name === 'title') {
            if (this.page === 'PACKET-ASSIGNMENTS') {
                this.router.navigateByUrl('/contacts/assignments/' + row.id);
            } else if (this.page === 'CHECKLIST-SUBMISSIONS') {
                this.router.navigateByUrl('/contacts/submissions/' + row.contactId);
            } else if (this.page === 'CONTACT-ASSIGNMENTS') {
                this.router.navigateByUrl('/vings/' + row.id, {
                    state: { id: 1, name: 'xTable' },
                });
            } else if (this.page === 'CONTACT-SUBMISSIONS') {
                this.router.navigateByUrl('/checklists/' + row.checklistId);
            }
        }
    }

    openDetails(e, rowId: number): void {
        e.stopPropagation();
        const date = new Date();
        const diff = date.getTimezoneOffset();
        const offset = (diff < 0 ? '+' : '-') + Math.abs(diff);
        let url = 'GET:api/data/reports/';

        if (this.page === 'PACKET-ASSIGNMENTS') {
            url += 'assignment-detail/' + rowId + '/' + offset + '/';
        } else if (this.page === 'CONTACT-ASSIGNMENTS') {
            url += 'assignment-detail/' + rowId + '/' + offset + '/';
        } else if (this.page === 'CHECKLIST-SUBMISSIONS') {
            url += 'submission-detail/' + rowId + '/' + offset + '/';
        } else if (this.page === 'CONTACT-SUBMISSIONS') {
            url += 'submission-detail/' + rowId + '/' + offset + '/';
        }

        this.appLoadingIndicatorService.show('.power-table .power-table-body');

        this.appHttpService.requestBlob(url, {}, (response) => {
            let filename = 'report_' + new Date().getTime() + '.pdf';
            const disposition = response.headers.get('Content-Disposition');
            if (disposition && disposition.indexOf('attachment') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }

            const blob = new Blob([response.body], { type: 'text/pdf' });

            if (navigator.msSaveOrOpenBlob) {
                // Workaround for IE 11
                navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                const data = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = data;
                link.download = filename;
                link.target = '_blank';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(data);
            }

            setTimeout(() => {
                this.appLoadingIndicatorService.hide();
            }, 100);
        });
    }

    handlePagesButtonClick(direction: string): void {
        let newPageIndex: number;

        if (direction === 'previous' && this.currentPageIndex > 0) {
            newPageIndex = this.currentPageIndex - 1;
        } else if (
            direction === 'next' &&
            this.currentPageIndex !== this.numberOfPages - 1
        ) {
            newPageIndex = this.currentPageIndex + 1;
        }

        // Set new selected page object.
        if (newPageIndex !== undefined) {
            this.selectedPage = this.pages[newPageIndex];
            this.currentPageIndex = newPageIndex;
            this.handlePageChange.emit(this.currentPageIndex);
        }
        this.scrollBarAreaRef.nativeElement.scrollTo(0, 0);
    }

    handlePagesSelectChange(selectedPage: AppSelectOption): void {
        const newPageIndex = this.appHelpersService.findIndexByProperty(
            this.pages,
            'value',
            selectedPage.value
        );

        this.selectedPage = selectedPage;
        this.currentPageIndex = newPageIndex;
        this.handlePageChange.emit(this.currentPageIndex);
    }

    handleDataExport(): void {
        this.export.emit();
    }

    getFooterPagingWidth(): string {
        let width = '125px';

        if (this.numberOfPages >= 10 && this.numberOfPages <= 99) {
            width = '135px';
        } else if (this.numberOfPages >= 100 && this.numberOfPages <= 999) {
            width = '150px';
        }

        return width;
    }

    isProgressSet(progress: any): boolean {
        let isSet = false;

        if (
            progress !== null &&
            progress !== undefined &&
            !isNaN(progress) &&
            progress >= 0
        ) {
            isSet = true;
        }

        return isSet;
    }

    getFilterClass(filter: XtFilter): string {
        let css = 'app-select-field ' + filter.name;

        if (filter.type === 'single') {
            if (
                (filter.shouldIncludeDefaultValue &&
                    filter.options.length === 1) ||
                (!filter.shouldIncludeDefaultValue &&
                    filter.options.length === 0)
            ) {
                css += ' disabled';
            }
        } else if (filter.type === 'multi' && filter.options.length === 0) {
            css += ' disabled';
        }

        return css;
    }

    getFilterSelectedValue(filter: XtFilter): any {
        let selected = null;
        if (filter.type === 'single') {
            if (filter.selected && filter.selected.length) {
                selected = filter.selected[0];
            }
        } else if (filter.type === 'multi') {
            if (filter.selected && filter.selected.length) {
                selected = filter.selected;
            } else {
                selected = [];
            }
        }

        return selected;
    }

    handleClearSearchField(): void {
        this.filterText = '';
        this.processSearchTextChange('');
    }

    getTableFooterText(): string {
        const numberOfEntities = this.numberOfResults,
            numberOfPages = this.numberOfPages ? this.numberOfPages : false;
        let text = '';

        // If there are entities within the table to display, generate footer text.
        if (numberOfEntities > 0) {
            if (numberOfPages > 1) {
                const currentPage = this.currentPageIndex + 1,
                    startValue =
                        currentPage * this.numbersPerPage -
                        this.numbersPerPage +
                        1;
                let endValue = startValue + this.numbersPerPage - 1;

                if (endValue > numberOfEntities) {
                    endValue = numberOfEntities;
                }

                // If there are multiple pages, ...
                text =
                    'Displaying ' +
                    startValue +
                    ' to ' +
                    endValue +
                    ' of ' +
                    numberOfEntities +
                    ' ' +
                    pluralize(this.entityName, numberOfEntities);
            } else {
                // ... otherwise, display simpler format text.
                text =
                    'Displaying ' +
                    numberOfEntities +
                    ' ' +
                    pluralize(this.entityName, numberOfEntities);
            }
        } else if (numberOfEntities === 0) {
            text = 'Displaying 0 ' + pluralize(this.entityName);
        }

        return text;
    }

    viewAnswerImage(url: string) {
        this.dialog.open(AppDialogImageComponent, {
            width: '700px',
            minHeight: '300px',
            height: 'auto',
            disableClose: true,
            data: {
                url: url,
            },
        });
    }

    private processSearchTextChange(searchText): void {
        if (this.lastSearchText !== searchText) {
            this.lastSearchText = Utils.trim(searchText);
            this.handleSearchTextChange.emit(this.lastSearchText);
        }
    }

    private selectAllRows(): void {
        if (this.isToggleAllSelected) {
            this.selectedRowIndexes = this.xTableData.rows
                .filter((i) => this.excludedRowIndexes.indexOf(i.id) < 0)
                .map((r) => r.id);
        }
    }

    getAnalyticsFilterBaseUrl() {
        const baseUrls = {
            'PACKET-ASSIGNMENTS': `api/data/assignments/packet/${this.id}/`,
            'CONTACT-ASSIGNMENTS': `api/data/assignments/contact/${this.id}/`,
            'CHECKLIST-SUBMISSIONS': `api/data/submissions/checklist/${this.id}/`,
            'CONTACT-SUBMISSIONS': `api/data/submissions/contact/${this.id}/`,
        },
        baseUrl = baseUrls[this.page];

        if (this.page === 'CHECKLIST-SUBMISSIONS' && !this.isOverviewSelected) {
            return `${baseUrl}question/${this.question_id}/`;
        } else {
            return baseUrl;
        }
    }

    getAnalyticsFilterUrl() {
        const data = this.analyticsData,
            baseUrl = this.getAnalyticsFilterBaseUrl(),
            params = AppDataTransform.getConvertedData('snake', data),
            query = [];

        Object.keys(params || {}).forEach((key) => {
            const val = params[key];
            if (Array.isArray(val)) {
                val.forEach((v) => {
                    query.push([key, encodeURIComponent(v)].join('='));
                });
            } else {
                query.push([key, encodeURIComponent(val)].join('='));
            }
        });

        return `${baseUrl}?${query.join('&')}`;
    }

    unassignFromPacket() {
        const unassignDialog = this.dialog.open(AppDialogUnassignComponent, {
            width: '500px',
            height: 'auto',
            disableClose: true,
            data: {
                page: this.page,
                contactName: this.contactName,
                vingTitle: this.title,
                vingMicroburst: this.microburst,
                id: this.id,
                selected: this.selectedRows,
                allSelected: this.selectedAll,
                allSelectedIndexes: this.selectedRowIndexes,
                excluded: this.excludedRowIndexes,
                results: this.results,
                rows: this.selectedRows,
                analyticsFilterUrl: this.getAnalyticsFilterUrl(),
                analyticsFilterCount: this.numberOfResults,
            },
        });

        unassignDialog.afterClosed().subscribe(() => {
            // needed for contacts row reset
            this.handleSelectedRows.emit([]);

            this.selectedRowIndexes = [];
            // updates the user table, for pagination update
            this.handleUpdateUserTables.emit([]);

            // toggles all selection if all are selected
            if (this.selectedAll) {
                this.toggleAllRows();
            }
        });
    }

    setAsComplete() {
        const completeDialog = this.dialog.open(AppDialogCompleteComponent, {
            width: '500px',
            height: 'auto',
            disableClose: true,
            data: {
                page: this.page,
                contactName: this.contactName,
                vingTitle: this.title,
                vingMicroburst: this.microburst,
                id: this.id,
                selected: this.selectedRows,
                allSelected: this.selectedAll,
                allSelectedIndexes: this.selectedRowIndexes,
                excluded: this.excludedRowIndexes,
                results: this.results,
                rows: this.selectedRows,
                analyticsFilterUrl: this.getAnalyticsFilterUrl(),
                analyticsFilterCount: this.numberOfResults,
            },
        });

        completeDialog.afterClosed().subscribe(() => {
            // needed for contacts row reset
            this.handleSelectedRows.emit([]);

            this.selectedRowIndexes = [];
            // updates the user table, for pagination update
            this.handleUpdateUserTables.emit([]);

            // toggles all selection if all are selected
            if (this.selectedAll) {
                this.toggleAllRows();
            }
        });
    }

    openFileInNewTab(certificateUrl: string) {
        window.open(certificateUrl, '_blank');
    }

    uploadCertificate(assignmentId: number) {
        const dialogData = {
                origin: this.page,
                assignmentId: assignmentId,
            },
            uploadCertificateDialog = this.dialog.open(DialogUploadCertificateComponent, {
                width: '500px',
                height: 'auto',
                disableClose: true,
                data: dialogData,
            });

        uploadCertificateDialog.afterClosed().subscribe(() => {
            // needed for contacts row reset
            this.handleSelectedRows.emit([]);

            this.selectedRowIndexes = [];
            // updates the user table, for pagination update
            this.handleUpdateUserTables.emit([]);
            this.handleRefreshChecklistData.emit();

            // toggles all selection if all are selected
            if (this.selectedAll) {
                this.toggleAllRows();
            }
        });
    }

    resolveSelectedFlag() {
        const dialogData = {
            page: this.page,
            contactName: this.contactName,
            vingTitle: this.title,
            vingMicroburst: this.microburst,
            id: this.id,
            selected: this.selectedRows,
            allSelected: this.selectedAll,
            allSelectedIndexes: this.selectedRowIndexes,
            excluded: this.excludedRowIndexes,
            results: this.results,
            rows: this.selectedRows,
            analyticsFilterUrl: this.getAnalyticsFilterUrl(),
            analyticsFilterCount: this.numberOfResults,
            isOverview: this.isOverviewSelected,
        };
        const resolveDialog = this.dialog.open(AppDialogResolveComponent, {
            width: '500px',
            height: 'auto',
            disableClose: true,
            data: dialogData,
        });

        resolveDialog.afterClosed().subscribe(() => {
            // needed for contacts row reset
            this.handleSelectedRows.emit([]);

            this.selectedRowIndexes = [];
            this.selectedFlagged = [];
            // updates the user table, for pagination update
            this.handleUpdateUserTables.emit([]);
            this.handleRefreshChecklistData.emit();

            // toggles all selection if all are selected
            if (this.selectedAll) {
                this.toggleAllRows();
            }
        });
    }
}
