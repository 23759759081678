import { CUSTOM_ELEMENTS_SCHEMA, NgModule, Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
    HammerGestureConfig,
    HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RecaptchaModule } from 'ng-recaptcha';
import { DeviceDetectorModule } from 'ngx-device-detector';
import * as Hammer from 'hammerjs';

import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './modules/page-not-found/page-not-found.component';

import { AppRoutingModule } from './app.routing.module';
import { AccountsModule } from './modules/accounts/accounts.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { ViewModule } from './modules/consume/view.module';
import { VingsModule } from './modules/vings/vings.module';
import { ChecklistsModule } from './modules/checklists/checklists.module';
import { ContactsModule } from './modules/contacts/contacts.module';
import { ProfileModule } from './modules/profile/profile.module';
import { AssignmentsModule } from './modules/assignments/assignments.module';
import { SubmissionsModule } from './modules/submissions/submissions.module';
import { AppControlsModule } from './shared/modules/app.controls.module';
import { AppPipesModule } from './shared/modules/app.pipes.module';

import { AppService } from './app.service';
import { AppHttpService } from './shared/services/app.http.service';
import { AppHelpersService } from './shared/services/app.helpers.service';
import { AppUserService } from './shared/services/app.user.service';
import { AppPermissionService } from './shared/services/app.permission.service';
import { AppLoadingIndicatorService } from './shared/services/app.loading-indicator.service';
import { AppMessageService } from './shared/services/app.message.service';
import { AppAlertService } from './shared/services/app.alert.service';
import { AppHeaderService } from './shared/services/app.header.service';
import { AppInfoService } from './shared/services/app-info/app.info.service';
import { AppJobService } from './shared/services/job/app.job.service';
import { AppFilterService } from './shared/services/app.filter.service';

import { AppResponseInterceptor } from './shared/interceptors/app.response.interceptor';
import { AuthGuard } from './shared/guards/auth.guard';
import {
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
} from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';

export function getWindow() {
    return window;
}

export function getLocalStorage() {
    try {
        return localStorage;
    } catch (e) {
        return {};
    }
}

export function getLocation() {
    return location;
}

@Injectable()
export class HammerConfig extends HammerGestureConfig {
    buildHammer(element: HTMLElement) {
        const mc = new Hammer(element, {
            touchAction: 'auto',
        });
        return mc;
    }
}

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        AppControlsModule,
        AppPipesModule,
        RecaptchaModule,
        DeviceDetectorModule.forRoot(),
        /*Features*/
        ProfileModule,
        AssignmentsModule,
        SubmissionsModule,
        ContactsModule,
        AccountsModule,
        ViewModule,
        VingsModule,
        ChecklistsModule,
        DashboardModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatNativeDateModule,
        NgxMatMomentModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        AppComponent,
        PageNotFoundComponent,
    ],
    providers: [
        { provide: 'window', useFactory: getWindow },
        { provide: 'storage', useFactory: getLocalStorage },
        { provide: 'location', useFactory: getLocation },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppResponseInterceptor,
            multi: true,
        },
        { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
        AppService,
        AppHttpService,
        AppHelpersService,
        AppJobService,
        AppUserService,
        AppPermissionService,
        AppInfoService,
        AppLoadingIndicatorService,
        AppMessageService,
        AppAlertService,
        AppHeaderService,
        AppFilterService,
        /*Guards*/
        AuthGuard,
    ],
    entryComponents: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
